import log from 'loglevel';
import time from "./time";
import lang from "./lang";

const loggers = new Map();

class Logger {
    #timers= new Map();
    constructor(logLevel) {
        this.logLevel = logLevel;
    }
    log(...messages) {
        const text = this.format(...messages);
        this.logLevel.log(text);
    }
    trace(...messages) {
        const text = this.format(...messages);
        this.logLevel.trace(text);
    }
    debug(...messages) {
        const text = this.format(...messages);
        this.logLevel.debug(text);
    }
    info(...messages) {
        const text = this.format(...messages);
        this.logLevel.info(text);
    }
    warn(...messages) {
        const text = this.format(...messages);
        this.logLevel.warn(text);
    }
    error(...messages) {
        const text = this.format(...messages);
        this.logLevel.error(text);
    }
    format(...messages) {
        const timestamp = time.format(time.now());
        const title = `${this.name}@${timestamp}: `;
        const content = messages.reduce((content, message) => {
            let text
            if(lang.isNullOrUndefined(message)) {
                text = '';
            } else if(message instanceof Error) {
                text = message.message;
            } else {
                text = message.toString();
            }
            return content + text + '\n';
        }, '')

        return title + content;
    }
    setLevel(level) {
        this.logLevel.setLevel(level)
    }
    getLevel() {
        return this.logLevel.getLevel();
    }
    get name() {
        return this.logLevel.name;
    }
    getLogger(name) {
        const logLevel = this.logLevel.getLogger(name);
        const logger = new Logger(logLevel);
        // logger.setLevel(this.getLevel());
        loggers.set(name, logger);
        return logger;
    }
    startWatch(tag) {
        const timer = [new Date()];
        this.#timers.set(tag, timer);
    }
    stopWatch(tag) {
        const timer = this.#timers.get(tag) || [new Date()];
        timer.push(new Date());
        this.#timers.set(tag, timer);
    }
    getElapsedTime(tag) {
        const timer = this.#timers.get(tag);
        if(timer?.length <= 1) {
            return null;
        }
        const duration = time.between(timer[0], timer[1]);
        return duration.toMillis();
    }
    init(cfg = {}) {
        const {level = 'WARN'} = cfg;
        for(const [name, logger] of loggers) {
            logger.setLevel(lang.has(cfg, name) ? cfg[name]: level);
        }
    }
}

const logger = new Logger(log);

export default logger;