import lang from "./lang";
import env from "./env";

class Cookie {
    get(name, defaultValue) {
        const decodedCookie = decodeURIComponent(document.cookie);
        const entries = decodedCookie.split(';');
        let value = lang.isUndefined(defaultValue) ? null : defaultValue;
        for(let i = 0; i <entries.length; i++) {
            const entry = entries[i].trim();
            const [k, v] = entry.split("=");
            if(k===name) {
                value = v;
                break;
            }
        }
        return value;
    }

    getAsBoolean(name, defaultValue) {
        let value = this.get(name, defaultValue);
        return (value==='true');
    }

    set(name, value, expiredInDays) {
        if(lang.isNullOrUndefined(value)) {
            this.delete(name);
            return;
        }
        const d = new Date();
        d.setTime(d.getTime() + (expiredInDays*24*60*60*1000));
        const expires = 'expires=' + d.toUTCString();
        const path = env.basename;
        document.cookie = `${name}=${value};${expires};path=${path};secure`;
    }

    has(name) {
        const value = this.get(name);
        return lang.isNotNullOrUndefined(value);
    }

    delete(name) {
        let d = new Date();
        d.setTime(d.getTime() - (24*60*60*1000));
        let expires = 'expires=' + d.toUTCString();
        const path = env.basename
        document.cookie = `${name}=;${expires};path=${path}`;
    }
}

const cookie = new Cookie();

export default cookie;