const products = [
    {
        "id": "Sweet flower box",
        "description": "特殊染色三色康乃馨,充滿夢幻浪漫氣氛",
        "name": "甜馨花盒",
        "width": 35,
        "height": 40,
        "price": 3000,
        "materials": [
            "藍繡球",
            "糖果康乃馨",
            "紫桔梗",
            "紫翠珠",
            "香檳玫",
            "綠康乃馨"
        ],
        "category": "Boxed Flower",
        "createdBy": "Jason Lin",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "keywords": [
            "Mother's Day",
            "Birthday"
        ],
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Yellow Green Christmas Gift Box",
        "name": "黃綠色耶誕禮盒",
        "description": "活潑亮眼聖誕佳節賀禮",
        "width": 35,
        "height": 35,
        "price": 3000,
        "materials": [
            "針墊花",
            "諾貝松",
            "東亞蘭",
            "聖誕裝飾"
        ],
        "category": "Boxed Flower",
        // "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": false,
        "delivery": "outsource"
    },
    {
        "id": "Romantic pink christmas tree",
        "name": "浪漫粉聖誕樹",
        "description": "以粉嫩淡色系鮮花設計\n浪漫擄獲粉紅少女心♥",
        "width": 25,
        "height": 40,
        "price": 4000,
        "materials": [
            "東亞蘭",
            "乒乓菊",
            "康乃馨",
            "松果"
        ],
        "category": "Boxed Flower",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": false,
        "delivery": "outsource"
    },
    {
        "id": "Pink bouquet",
        "name": "粉馨花束",
        "description": "粉柔康乃馨如母親溫暖懷抱\n搭配柔柔的蕾絲 ,大方亮麗",
        "width": 40,
        "height": 40,
        "price": 4200,
        "materials": [
            "康乃馨",
            "繡球",
            "蘭花",
            "尤加利葉"
        ],
        "keywords": [
            "Mother's Day",
            "Birthday"
        ],
        "category": "Flower Bouquet",
        // "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Gentleman blue",
        "name": "紳士藍",
        "description": "綻青如黑夜夜空 , 屬於男士之夜充滿神秘深邃感\n適合注重生活情趣的人!!",
        "width": 35,
        "height": 45,
        "price": 3500,
        "materials": [
            "藍繡球",
            "紫繡球",
            "紫結梗",
            "山防風"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "108 red roses bouquet",
        "name": "108朵紅玫瑰花束",
        "description": "綻嫁給我吧! 九打玫瑰的長長久久感\n華麗的進口絲絨紅玫瑰 , 極簡包裝呈現高貴質感",
        "width": 80,
        "height": 100,
        "price": 15000,
        "materials": [
            "進口玫瑰",
            "葉材"
        ],
        "category": "Flower Bouquet",
        "keywords": [
            "Valentine"
        ],
        "note": "情人節期間價格請來電洽詢",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "outsource"
    },
    {
        "id": "Romantic waltz",
        "name": "浪漫華爾滋",
        "description": "敞手飛揚 , 裙擺搖搖 , 浪漫旋轉\n葉蘭摺疊如裙襬般搖曳生姿 , 搭配有層次的玫瑰設計",
        "price": 3200,
        "width": 30,
        "height": 65,
        "materials": [
            "粉玫瑰",
            "松蟲草",
            "順風粉桔梗",
            "粉腎藥蘭"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-03-04T18:25:43.511Z",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Fresh and elegant",
        "description": "潔白純淨 氣質脫俗\n鐵炮百合淡雅清香, 冰清玉潔, 搭配尤加利葉別有一番花韻",
        "name": "清新雅韻",
        "width": 30,
        "height": 80,
        "price": 2200,
        "materials": [
            "鐵炮百合",
            "白桔梗",
            "山防風",
            "尤加利葉"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Queen of hearts",
        "description": "亮立紅色高雅花束 , 特殊靜面紙包裝增加花色投影\n立體豐富有趣",
        "name": "紅馨皇后",
        "width": 30,
        "height": 40,
        "price": 3200,
        "materials": [
            "粉邊康乃馨",
            "紅玫瑰",
            "馬雅百合",
            "雞冠花",
            "非洲玉金香"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Rose whisper",
        "description": "簡單展現玫瑰之美,訴說純淨的情\n香檳玫瑰搭配白色玫瑰,獻上閃亮純粹的情意",
        "name": "玫瑰訴情",
        "width": 30,
        "height": 50,
        "price": 2500,
        "materials": [
            "香檳玫瑰",
            "白玫瑰",
            "深山櫻",
            "葉材"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Pure white rose bouquet",
        "description": "華麗精緻的白玫瑰花束\n搭配對比強烈的黑白包裝,凸顯玫瑰的純淨",
        "name": "純白玫瑰花束",
        "width": 30,
        "height": 40,
        "price": 4500,
        "materials": [
            "白玫瑰",
            "葉材"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Love of the Danube",
        "description": "藍紫色花束 , 充滿浪漫色彩\n濃情的組合搭配融化戀人的心",
        "name": "多瑙河之戀",
        "width": 30,
        "height": 40,
        "price": 2520,
        "materials": [
            "白玫瑰",
            "愛情花",
            "桔梗花",
            "石竹"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Orange champagne",
        "description": "迷人的香檳色帶橘色調",
        "name": "橘色香檳",
        "width": 40,
        "height": 45,
        "price": 2500,
        "materials": [
            "淺香檳玫瑰",
            "進口橘玫瑰",
            "白桔梗",
            "臘梅",
            "進口尤佳利"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Bouquet of platycodon",
        "description": "精緻小巧的訴情小天使\n粉色及白色桔梗深情組合,精緻浪漫",
        "name": "桔梗花束",
        "width": 25,
        "height": 40,
        "price": 2200,
        "materials": [
            "白桔梗",
            "粉桔梗",
            "葉材"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Whispering honey",
        "description": "清柔怡人的香檳玫瑰訴說款款深情\n配上低調個性包裝, 默默獻上真情",
        "name": "呢喃蜜語",
        "width": 30,
        "height": 40,
        "price": 2500,
        "materials": [
            "香檳玫瑰",
            "白繡球",
            "綠火龍果",
            "狗尾草"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Brilliant mommy bouquet",
        "description": "陽光般亮麗色彩 ,有如開朗媽咪笑容",
        "name": "燦爛媽咪花束",
        "width": 40,
        "height": 40,
        "price": 2580,
        "materials": [
            "紅粉康乃馨",
            "橘巨輪蘭",
            "綠火龍果",
            "琥珀綠結梗"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Cute mommy",
        "description": "粉柔色系玫瑰加上桔梗,3朵康乃馨代表\"我愛你\"\n獻給最溫柔可愛的媽咪",
        "name": "可愛媽咪",
        "width": 30,
        "height": 45,
        "price": 2500,
        "materials": [
            "藍繡球",
            "紫繡球",
            "紫結梗",
            "山防風"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800"
    },
    {
        "id": "Bouquet of purple flower",
        "description": "葡萄紫溫馨花束 , 神秘高貴\n進口鑲邊紫色康乃馨 , 特殊造型更顯別緻",
        "name": "紫馨花束",
        "width": 30,
        "height": 40,
        "price": 2520,
        "materials": [
            "康乃馨",
            "玫瑰",
            "石竹"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800"
    },
    {
        "id": "Bear bouquet",
        "description": "俏皮小熊搭配新翠粉玫瑰,輕巧可愛",
        "name": "小熊花束",
        "width": 30,
        "height": 40,
        "price": 3000,
        "materials": [
            "新翠粉玫瑰",
            "白桔梗",
            "綠石竹"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800"
    },
    {
        "id": "City upstart",
        "description": "對比鮮豔的配色,獨特奢華\n以拉菲草及葉材來代替緞帶,低調卻不失個性",
        "name": "城市新貴",
        "width": 30,
        "height": 50,
        "price": 2500,
        "materials": [
            "紅玫瑰",
            "繡球花",
            "山歸來"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800"
    },
    {
        "id": "Summer love",
        "description": "鮮紅玫瑰的熱情 , 如夏日陽光般熾熱\n搭配小巧可愛的臘梅及亮白桔梗,低調奢華",
        "name": "夏日赤戀",
        "width": 40,
        "height": 40,
        "price": 3500,
        "materials": [
            "紅玫瑰",
            "白桔梗",
            "臘梅",
            "尤加利葉"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800"
    },
    {
        "id": "Bouquet of red roses",
        "description": "紅玫瑰代表熱戀的激情,至情不悔的見證\n如絲絨般的艷麗紅玫,傳達濃濃熱情甜蜜愛意",
        "name": "紅玫瑰花束",
        "width": 30,
        "height": 60,
        "price": 2500,
        "materials": [
            "紅玫瑰",
            "紅火龍果",
            "魚尾山蘇葉",
            "唐棉"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Pink misty",
        "description": "溫柔粉色溫馨花束 ,群聚設計簡單乾淨",
        "name": "粉馨縹緲",
        "width": 30,
        "height": 40,
        "price": 2520,
        "materials": [
            "粉康乃馨",
            "粉玫瑰",
            "小薔薇",
            "珍珠草"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Warm tenderness",
        "description": "碎花組合搭配羊毛氈質感的棉布\n柔美溫和,獻給溫暖柔情的她",
        "name": "溫暖柔情",
        "width": 30,
        "height": 40,
        "price": 2520,
        "materials": [
            "進口多朵薔薇",
            "粉桔梗",
            "新娘花",
            "白千日紅",
            "羊毛松",
            "尤加利葉"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Blue orange bouquet",
        "description": "亮眼的對比色組合,新潮又出色\n清晰亮眼的香檳玫瑰搭配藍色繡球,簡單有型   ",
        "name": "藍橘色花束",
        "width": 30,
        "height": 40,
        "price": 2500,
        "materials": [
            "香檳玫瑰",
            "藍繡球",
            "火燭葉"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Dream mirror",
        "description": "魔鏡! 魔鏡! 誰是最美的情人呢?\n鮮紅玫瑰如紅唇般誘人 , 搭配鏡面紙的包裝增加花束的質量感!!",
        "name": "夢幻魔鏡",
        "width": 35,
        "height": 45,
        "price": 3200,
        "materials": [
            "進口紅玫12朵",
            "臘梅",
            "尤加利葉",
            "火燭葉"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Imported yellow rose bouquet",
        "description": "祝福你擁有閃亮人生,光輝前程\n金黃玫瑰,亮麗如你",
        "name": "進口黃玫瑰花束",
        "width": 45,
        "height": 40,
        "price": 4000,
        "materials": [
            "進口黃玫瑰",
            "伯利恆之星"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "California sunshine",
        "description": "獻給前世的情人, 爸爸節快樂\n展現陽光般的笑容,感謝辛苦老爸",
        "name": "加州陽光",
        "width": 45,
        "height": 40,
        "price": 2520,
        "materials": [
            "粉玫瑰",
            "進口多朵橘色薔葳",
            "白桔梗",
            "臘梅",
            "粉雞冠"
        ],
        "category": "Flower Bouquet",
        "keywords": [
            "Father's Day"
        ],
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Delicate and pretty woman",
        "description": "3種玫瑰色組和花束, 繽紛浪漫\n清爽柔美色系 , 就如同妳那甜美笑容 !!",
        "name": "清秀佳人",
        "width": 30,
        "height": 30,
        "price": 2200,
        "materials": [
            "白玫瑰",
            "甜心玫瑰",
            "粉翡翠玫瑰",
            "尤加利葉"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Tangerine bouquet",
        "description": "鮮艷對比色彩 ,熱鬧亮麗顯眼",
        "name": "橘馨繽紛花束",
        "width": 40,
        "height": 40,
        "price": 2580,
        "materials": [
            "橘邊康乃馨",
            "紅玫瑰",
            "紫桔梗",
            "火焰百合"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Hundred roses bouquet",
        "description": "百朵玫瑰表達愛意,終身不悔\n滿滿粉桃色玫瑰 , 表達深深濃情蜜意",
        "name": "百朵玫瑰花束",
        "width": 60,
        "height": 60,
        "price": 8000,
        "materials": [
            "玫瑰",
            "火龍果",
            "滿天心"
        ],
        "category": "Flower Bouquet",
        "keywords": [
            "Valentine"
        ],
        "note": "情人節期間價格請來電洽詢",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    },
    {
        "id": "Happy smile",
        "description": "妳的微笑是我幸福的原料\n浪漫花仙子傳送幸福微笑, 深情滿溢",
        "name": "幸福微笑",
        "width": 30,
        "height": 65,
        "price": 2520,
        "materials": [
            "粉玫瑰",
            "卡斯比亞",
            "白桔梗",
            "水仙百合"
        ],
        "category": "Flower Bouquet",
        "createdOn": "2018-07-12T00:00:00.000+0800",
        "availability": true,
        "delivery": "in-house"
    }
];

export default products