import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            // Ignore these action types
            // ignoredActions: ['your/action/type'],
            // Ignore these field paths in all actions
            ignoredActionPaths: ['meta.arg', 'payload'],
            // Ignore these paths in the state
            // ignoredPaths: ['product'],
        },
    }),
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
