import {createSlice} from "@reduxjs/toolkit";
import {postError} from "../app/appReducer";
import contentTypeService from "../../mock/content/contentTypeService";

const name = 'content';

const initialState = {
    contentTypes: []
};

const slice = createSlice({
    name,
    initialState,
    reducers: {
        setContentTypes(state, action) {
            state.contentTypes = action.payload;
        }
    }
});

export const { setContentTypes } = slice.actions;

export const { reducer } = slice;

export const selectContentTypes = (state) => state[name].contentTypes;

export const fetchContentTypes = () => async (dispatch, getState) => {
    try {
        const contentTypes = await contentTypeService.findAll();
        dispatch(setContentTypes(contentTypes));
    } catch(e) {
        dispatch(postError(e));
    }
};

const contentTypeReducer = reducer;
export default contentTypeReducer;
