import lang from "./lang";
import time from "./time";
import fileIO, {FileDescriptor} from "./fileIO";
const parser = window.JSON;

const ISO8601 = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

class JSON {
    parse(text) {
        return this.decode(parser.parse(text));
    }
    stringify(value, replacer, space) {
        return parser.stringify(this.encode(value), replacer, space);
    }
    beautify(value, replacer) {
        return parser.stringify(this.encode(value), replacer, 2);
    }

    /**
     * transfer any value to the json object.
     * - encode the date value to ISO8601 string.
     *
     * This function has to be synchronized to make it easier to use in Redux's action creator.
     *
     * @param value
     * @returns {*}
     */
    toJSON(value) {
        return this.encode(value);
    }
    fromJSON(jsonObject, clazz) {
        if(lang.isNullOrUndefined(jsonObject)) return jsonObject;

        const json = this.decode(jsonObject);

        if(lang.isNullOrUndefined(clazz)) return json;

        if(lang.isFunction(clazz.fromJSON)) {
            return clazz.fromJSON(json);
        }

        const object = new clazz();
        for(const property in object) {
            object[property] = json[property];
        }

        return object;
    }

    /**
     * Transform the value to an encoded JSON value.
     * - Date: encode the date value to a ISO8601 string.
     * @param value
     */
    encode(value) {
        let jsonValue;
        if(lang.isPrimitive(value)||lang.isNullOrUndefined(value)) {
            jsonValue = lang.isDate(value) ? time.format(value, ISO8601) : value;
        } else if(lang.isArray(value)) {
            jsonValue = value.map(val => this.encode(val));
        } else {
            const obj = value.toJSON ? value.toJSON() : value;
            if(value.toJSON) {
                // value.toJSON() could return anything (array, object ...).
                jsonValue = this.encode(value.toJSON());
            } else {
                jsonValue = lang.mapObject(obj, val => this.encode(val));
            }
        }

        return jsonValue;
    }

    /**
     * Transform the encoded JSON value to a pojo or primitive value.
     * - Date: decode the ISO8601 string to a date value.
     *
     * @param value
     */
    decode(value) {
        let jsonValue
        if(lang.isArray(value)) {
            jsonValue =value.map(val => this.decode(val));
        } else if(lang.isObject(value)) {
            if(fileIO.isFileLike(value)) {
                jsonValue = FileDescriptor.fromJSON(value);
            } else {
                jsonValue = lang.mapObject(value, val => this.decode(val));
            }
        } else if(lang.isString(value) && time.isISODateString(value)) {
            jsonValue = time.parse(value, ISO8601);
        } else {
            jsonValue = value;
        }

        return jsonValue;
    }
}

export const json = new JSON();

export default json;