import lang from "../../lib/appfuse-react/core/lang";
import farmer from "./farmer";

const farmerService = [{
    matcher: 'farmer',
    reply: config => {
        if(config.method==='post') {
            return [200, farmer[0]];
        } else {
            const {predicate} = config.params;
            let data = farmer;
            const selector = predicate?.node()?.selector?.();
            const children = predicate?.node()?.children?.();
            if(lang.isNotEmpty(selector)) {
                const value = predicate.node().expectation();
                if(lang.isEmpty(value)) {
                    return [200, farmer];
                }
                if(selector==='farmerName' || selector==='farmNo') {
                    const regexp = new RegExp(value.replaceAll('*', '.*'));
                    data = farmer.filter(farmer => regexp.test(farmer[selector]));
                } else if(selector==='farmName') {
                    const regexp = new RegExp(value.replaceAll('*', '.*'));
                    data = farmer.filter(farmer => regexp.test(farmer?.cropApplication[selector]));
                } else if(selector==='crop' || selector==='selfProcessing' || selector==='approvedProcessing') {
                    if(value) data = farmer.filter(farmer => farmer[selector]===value);
                } else {
                    data = farmer.filter(farmer => farmer[selector]===value);
                }
            } else if(lang.isNotEmpty(children)) {
                let tmp = farmer;
                for(let child of children) {
                    const value = child.expectation();
                    if(lang.isEmpty(value)) {
                        continue;
                    }
                    const selector = child.selector();
                    if(selector==='farmerName' || selector==='farmNo') {
                        const regexp = new RegExp(value.replaceAll('*', '.*'));
                        tmp = tmp.filter(farmer => regexp.test(farmer[selector]));
                    } else if(selector==='farmName') {
                        const regexp = new RegExp(value.replaceAll('*', '.*'));
                        tmp = tmp.filter(farmer => regexp.test(farmer?.cropApplication[selector]));
                    } else if(selector==='crop' || selector==='selfProcessing' || selector==='approvedProcessing') {
                        if(value) tmp = tmp.filter(farmer => farmer[selector]===value);
                    } else {
                        tmp = tmp.filter(farmer => farmer[selector]===value);
                    }
                }
                data = tmp;
            } else {
                data = farmer;
            }
            return [200, data];
        }
    }
}, {
    matcher: /farmer\/(.*)/,
    reply: config => {
        const id = /farmer\/(.*)/.exec(config.url)[1];
        const data = farmer.find(farmer => farmer.id===id);
        return [200, data];
    }
}];

export default farmerService;