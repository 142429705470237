import {Fragment, useCallback, useLayoutEffect, useState} from "react";
import lang from "../../core/lang";
import UserEvent from "../../core/UserEvent";

function ResizeSensor(props) {
    useLayoutEffect(() => {
        window.addEventListener('resize', safeHandleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleResize = event => {
        const width = window.innerWidth, height = window.innerHeight;
        props.onResize && props.onResize(new UserEvent({width, height}, event));
    }

    const safeHandleResize = useCallback(lang.debounce(handleResize, 1000), []);

    return (
        <Fragment>
            {props.children}
        </Fragment>
    )
}

export default ResizeSensor;