
const registrationInboxes = [
    {
        "uuid":"1",
        "createdOn":"2021-08-12T08:20:10.000Z",
        "activity":"survey_S10",
        "farmerName":"王森林",
        "mobilePhone":"0911230120",
        "farmCity":"65",
        "applyTarget":"species1",
        "registration":{
            "applicant":"王森林",
            "idNo":"A123456789",
            "farmName":"王森林(原味蕃薯園)",
            "mobilePhone":"0911234098",
            "phone":"02-7723-8001",
            "fax":"02-7723-8002",
            "contactPerson":"王森林",
            "email":"stevenwang111@gmail.com",
            "appliedItem":"crop",
            "city":"65",
            "district":"三峽區",
            "area":0.0098,
            "mainCrop":"黃豆、花生、綠豆、紅豆、蕎麥",
            "productionPeriod":"每年 1~3 及 6~9月",
            "environmentalStatus":"近年來食安事件頻傳，環保意識抬頭，新農業政策已轉向促進有機農業及友善環境耕作發展，本文\n" +
                "爰分析全球有機農業發展現況，並運用農林漁牧業普查資料，探討近10 年來我國友善環境農業發展\n" +
                "情形及現況。",
            "conservationTarget":"species1",
            "species":"臺灣朱雀",
            "channels":["market","network"],
            "businessPhilosophy":"有機農業是一種不污染環境、不破壞生態，並能提供消費者健康與安全農產品的生 產方式。 其特點為以維護生態及環境的平衡為依歸，並以遵循自然資源循環及土地永續 利用為原則。",
            "certificationBody":"001",
            "organicCategories":["crop","processing"],
            "organicValidDate":"2023-08-12T08:20:10.000Z",
            "organicCertificateNo":"1-009-1-00912",
            "assistantAndBriefDate":"參加 2021/3/15 舉辦的說明會,輔導員為張先生"
        }
    },
    {
        "uuid":"2",
        "createdOn":"2021-08-11T08:20:10.000Z",
        "activity":"survey_S20",
        "farmerName":"李大同",
        "mobilePhone":"0911230120",
        "farmCity":"65",
        "applyTarget":"species2",
        "areaContact": "北區窗口",
        "registration":{
            "applicant":"李大同",
            "idNo":"A123456789",
            "farmName":"李大同(1111蕃薯園)",
            "mobilePhone":"0911234098",
            "phone":"02-7723-8001",
            "fax":"02-7723-8002",
            "contactPerson":"李大同",
            "email":"samlee111@gmail.com",
            "appliedItem":"crop",
            "city":"65",
            "district":"三峽區",
            "area":0.0098,
            "mainCrop":"小麥、燕麥、高梁、甘藷",
            "productionPeriod":"每年 1~3 及 6~9月",
            "environmentalStatus":"近年來食安事件頻傳，環保意識抬頭，新農業政策已轉向促進有機農業及友善環境耕作發展，本文\n" +
                "爰分析全球有機農業發展現況，並運用農林漁牧業普查資料，探討近10 年來我國友善環境農業發展\n" +
                "情形及現況。",
            "conservationTarget":"species1",
            "species":"臺灣朱雀",
            "channels":["market","network"],
            "businessPhilosophy":"有機農業是一種不污染環境、不破壞生態，並能提供消費者健康與安全農產品的生 產方式。 其特點為以維護生態及環境的平衡為依歸，並以遵循自然資源循環及土地永續 利用為原則。",
            "certificationBody":"001",
            "organicCategories":["crop","processing"],
            "organicValidDate":"2023-08-12T08:20:10.000Z",
            "organicCertificateNo":"1-009-1-00912",
            "assistantAndBriefDate":"參加 2021/3/15 舉辦的說明會,輔導員為張先生"
        },

        "commentsS10": "很棒"
    }
]

export default registrationInboxes;