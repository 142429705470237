import './App.css';
import {unstable_createMuiStrictModeTheme as createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import env from "./lib/appfuse-react/core/env";
import CssBaseline from "@material-ui/core/CssBaseline";
import {AppFuseProvider} from "./lib/appfuse-react/component/AppFuse";
import AppRouter from "./AppRouter";
import config from "./conf/config";
import i18n from "./lib/appfuse-react/core/i18n";
import logger from "./lib/appfuse-react/core/logger";
import LinearProgress from "./lib/appfuse-react/component/LinearProgress";

const log = logger.getLogger('App');
const LANGUAGES = ['en', 'zh'];
const LANGPACKS = ['term', 'message', 'article'];

const App = () => {
    const [ready, setReady] = useState(false)
    const themeOptions = env.get('theme') || {};
    const theme = createMuiTheme(themeOptions);

    // useEffect 可以選擇不回傳值, 或是回傳一個 cleanup 函數供 react 在 unmount 元件前呼叫, 讓元件可以釋放佔用的資源.
    // 所以傳給 useEffect 的 effect 函數不能宣告為 async. 因為宣告為 async 的函數的回傳值是 promise.
    // 要在 useEffect 使用 await 呼叫 async 函數的方法是:
    // - 在 effect 函數裡面宣告一個 async 的函數, 在函數裡面使用 await 呼叫想要使用的 async 函數,
    // - 然後在呼叫宣告的 async 函數.
    useEffect(() => {
        (async () => {
            await env.init(config);
            for(const language of LANGUAGES) {
                for(const pack of LANGPACKS) {
                    const path = `./nls/${pack}/${language}`;
                    try {
                        const module = await import(`${path}`);
                        i18n.addTranslation(language, module.default);
                    } catch(e) {
                        log.warn(`Failed to load the translation from ${path}.`, e);
                    }
                }
            }
            logger.init(env.get('logger'));
            setReady(true);
        })();
    }, []);


    // 所有使用到 material-ui 的元件必須在 ThemeProvider 的 hierarchy 之下.
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppFuseProvider>
                <LinearProgress value={ready} >
                    <AppRouter />
                </LinearProgress>
            </AppFuseProvider>
        </ThemeProvider>
    );
}

export default App;
