import React, {useEffect} from "react";
import {withExplorer} from "../../layout/Explorer";
import { useSelector} from "../../store";
import {isAuthenticated} from "../../reducer/iam/authReducer";
import {useTheme} from "@material-ui/core";
import Image from "../../lib/appfuse-react/component/Image";
import Paper from "../../lib/appfuse-react/component/Paper";
import {Redirect} from "react-router-dom";

function Home(props) {
    const {disabled} = props;
    const theme = useTheme();
    const authenticated = useSelector(isAuthenticated);

    useEffect(() => {
    }, []);

    const route = authenticated ? <Redirect to="/main" /> : (
        <Paper className="app-home" fullWidth fullHeight disabled={disabled} >
            <Image value={theme.brand.banner} fullWidth fullHeight />
        </Paper>
    );

    return route;
}

function HomeImage() {
    return (
        <Home disabled />
    )
}

export default withExplorer(Home);

export {HomeImage};