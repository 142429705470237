import {Avatar, Box, Divider, makeStyles, Paper, Popover, Typography} from "@material-ui/core";
import Button from "../../lib/appfuse-react/component/Button";
import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import useRouter from "../../lib/appfuse-react/hook/useRouter";
import {useHistory} from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import code from "../../lib/appfuse-react/core/code";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    avatar: {
        width: '2.4rem',
        height: '2.4rem',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

function MailDetail(props) {
    const classes = useStyles();
    const {anchorEl, open, userInfo, inboxCounts} = props;
    const {cropInboxNumber = 0, recognizedInboxNumber = 0} = inboxCounts;
    const history = useHistory();
    const [inboxNumbers, setInboxNumbers] = useState([]);

    useEffect(() => {
        (async () => {
            const inboxNumbers = await code.fetch('inboxNumbers');
            setInboxNumbers(inboxNumbers);
        })();
    }, [])

    return (
        <Popover anchorEl={anchorEl} open={open}
                 onClose={props.onClose}
                 anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'center',
                 }}
                 transformOrigin={{
                     vertical: 'top',
                     horizontal: 'center',
                 }}>
            <Paper className={classes.root}>
                <Typography align="center">收件匣</Typography>
                {/*<Box marginY={1} ><Divider /></Box>*/}
                <div className={classes.buttons}>
                    {inboxNumbers.map(inboxNumber => (
                        <div>
                            <Box marginY={1} ><Divider /></Box>
                            <BadgeTextButton key={inboxNumber.value} count={inboxCounts[inboxNumber.value]} text={inboxNumber.text} path={inboxNumber.icon} />
                        </div>
                    ))}
                </div>
            </Paper>
        </Popover>
    )
}

function BadgeTextButton(props) {
    const {count, text, path} = props;
    const history = useHistory();

    return (
        <Badge badgeContent={count} color="error">
            <Button text={text} onClick={()=>{history.push(path)}} variant={"text"} fullWidth color="primary" />
        </Badge>
    )
}

MailDetail.propTypes = {
    anchorEl: PropTypes.instanceOf(Element),
    open: PropTypes.bool,
    inboxCounts: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
}


export default MailDetail;