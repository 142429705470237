import lang from './lang';

class Predicate {
    constructor(node) {
        this._node = node;
    }
    and(predicate) {
        let node;
        if(predicate.node() instanceof EmptyNode) {
            node = this.node();
        } else if(this.node() instanceof EmptyNode) {
            node = predicate.node();
        } else if(this.node() instanceof AndNode) {
            node = new AndNode(...this.node().children(), predicate.node());
        } else {
            node = new AndNode(this.node(), predicate.node());
        }
        return new Predicate(node);
    }
    or(predicate) {
        let node;
        if(predicate.node() instanceof EmptyNode) {
            node = this.node();
        } else if(this.node() instanceof EmptyNode) {
            node = predicate.node();
        } else if(this.node() instanceof OrNode) {
            node = new OrNode(...this.node().children(), predicate.node());
        } else {
            node = new OrNode(this.node(), predicate.node());
        }
        return new Predicate(node);
    }
    toString() {
        return this.node().toString();
    }
    node() {
        return this._node;
    }
    static empty() {
        return new Predicate(new EmptyNode());
    }
    static eq(selector, expectation) {
        const node = lang.isNullOrUndefined(expectation) ? new EmptyNode() : new ComparisonNode(ComparisonOperator.EQ, selector, expectation);
        return new Predicate(node);
    }
    static notEq(selector, expectation) {
        const node = lang.isNullOrUndefined(expectation) ? new EmptyNode() : new ComparisonNode(ComparisonOperator.NOT_EQ, selector, expectation);
        return new Predicate(node);
    }
    static like(selector, expectation) {
        let node;
        if(lang.isNullOrUndefined(expectation)) {
            node = new EmptyNode();
        } else {
            const comparison = expectation.indexOf('*')===-1 ? `*${expectation}*` : expectation;
            node = new ComparisonNode(ComparisonOperator.EQ, selector, comparison);
        }
        return new Predicate(node);
    }
    static startsWith(selector, expectation) {
        const node = lang.isNullOrUndefined(expectation) ? new EmptyNode() : new ComparisonNode(ComparisonOperator.EQ, selector, `${expectation}*`);
        return new Predicate(node);
    }
    static endsWith(selector, expectation) {
        const node = lang.isNullOrUndefined(expectation) ? new EmptyNode() : new ComparisonNode(ComparisonOperator.EQ, selector, `*${expectation}`);
        return new Predicate(node);
    }
    static ge(selector, expectation) {
        const node = lang.isNullOrUndefined(expectation) ? new EmptyNode() : new ComparisonNode(ComparisonOperator.GE, selector, expectation);
        return new Predicate(node);
    }
    static gt(selector, expectation) {
        const node = lang.isNullOrUndefined(expectation) ? new EmptyNode() : new ComparisonNode(ComparisonOperator.GT, selector, expectation);
        return new Predicate(node);
    }
    static lt(selector, expectation) {
        const node = lang.isNullOrUndefined(expectation) ? new EmptyNode() : new ComparisonNode(ComparisonOperator.LT, selector, expectation);
        return new Predicate(node);
    }
    static le(selector, expectation) {
        const node = lang.isNullOrUndefined(expectation) ? new EmptyNode() : new ComparisonNode(ComparisonOperator.LE, selector, expectation);
        return new Predicate(node);
    }
    static in(selector, expectation) {
        const node = lang.isEmpty(expectation) ? new EmptyNode() : new ComparisonNode(ComparisonOperator.IN, selector, expectation);
        return new Predicate(node);
    }
    static notIn(selector, expectation) {
        const node = lang.isEmpty(expectation) ? new EmptyNode() : new ComparisonNode(ComparisonOperator.NOT_IN, selector, expectation);
        return new Predicate(node);
    }
    static has(selector, expectation) {
        const node = lang.isNullOrUndefined(expectation) ? new EmptyNode() : new ComparisonNode(ComparisonOperator.HAS, selector, expectation);
        return new Predicate(node);
    }
    static is(selector, expectation) {
        const node = new ComparisonNode(ComparisonOperator.IS, selector, expectation);
        return new Predicate(node);
    }
    static isNot(selector, expectation) {
        const node = new ComparisonNode(ComparisonOperator.IS_NOT, selector, expectation);
        return new Predicate(node);
    }
}

class Node {}

class EmptyNode extends Node {
    toString() {
        return null;
    }
}

class LogicalNode extends Node {
    constructor(operator, children) {
        super();
        this._operator = operator;
        this._children = children;
    }
    operator() {
        return this._operator;
    }
    children() {
        return this._children;
    }
}

class AndNode extends LogicalNode {
    constructor() {
        super(LogicalOperator.AND, [...arguments]);
    }
    toString() {
        let query = this.children().reduce((query, node) => {
            query.push(node.toString());
            return query;
        }, []);
        return `(${query.join(';')})`;
    }
}

class OrNode extends LogicalNode {
    constructor() {
        super(LogicalOperator.OR, [...arguments]);
    }
    toString() {
        let query = this.children().reduce((query, node) => {
            query.push(node.toString());
            return query;
        }, []);
        return `(${query.join(',')})`;
    }
}

class ComparisonNode extends Node {
    constructor(operator, selector, expectation) {
        super();
        this._operator = operator;
        this._selector = selector;
        this._expectation = expectation;
    }
    operator() {
        return this._operator;
    }
    selector() {
        return this._selector;
    }
    expectation() {
        return this._expectation;
    }
    toString() {
        let expectation = this.expectation();

        if(typeof(expectation)==='string') {
            expectation = `"${expectation}"`;
        } else if(expectation instanceof Date) {
            expectation = expectation.toISOString();
        } else if(typeof(expectation)==='boolean') {
            expectation = expectation.toString();
        } else if(Array.isArray(expectation)) {
            expectation = expectation.reduce((accumulator, currentValue) => {
                accumulator += `"${currentValue}",`;
                return accumulator;
            }, '');
            expectation = expectation.substr(0, expectation.length-1); //chop off last ","
            expectation = `(${expectation})`;
        }

        return `${this.selector()}${this.operator()}${expectation}`;
    }
}

const  LogicalOperator = {
    AND: ';',
    OR: ','
};

const ComparisonOperator = {
    EQ: '==',
    NOT_EQ: '!=',
    GT: '=gt=',
    GE: '=ge=',
    LT: '=lt=',
    LE: '=le=',
    IN: '=in=',
    NOT_IN: '=out=',
    HAS: '=has=',
    BETWEEN: '=between=',
    IS: '=is=',
    IS_NOT: '==isnt=='
};


Predicate.ComparisonOperator = ComparisonOperator;

export {ComparisonOperator, LogicalOperator};

export default Predicate;