class UserEvent {
    constructor(data, nativeEvent) {
        for(const k in data) {
            Object.defineProperty(this, k, {value : data[k],
                writable : false,
                enumerable : true,
                configurable : true});
        }
        this.data = data;
        this.nativeEvent = nativeEvent;

    }
    preventDefault() {
        this.nativeEvent?.preventDefault();
    }
    isDefaultPrevented() {
        return this.nativeEvent?.isDefaultPrevented();
    }
    stopPropagation() {
        this.nativeEvent?.stopPropagation();
    }
    isPropagationStopped() {
        return this.nativeEvent?.isPropagationStopped();
    }
    get target() {
        return this.data?.target || this.nativeEvent?.target;
    }
    get currentTarget() {
        return this.data?.currentTarget || this.nativeEvent?.currentTarget;
    }
}

export default UserEvent;