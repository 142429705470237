import PropTypes from 'prop-types';
import {Divider, Popover, Snackbar, Typography} from "@material-ui/core";
import React, {useEffect, useCallback, useRef, useState} from 'react';
import Button from "../Button";
import {makeStyles} from "@material-ui/core/styles";
import Icon from "../Icon";
import Box from "../Box";
import clsx from "clsx";
import UserEvent from "../../core/UserEvent";
import i18n from "../../core/i18n";
import lang from "../../core/lang";
import {ConstraintError} from "../../error";
import useResponsive from "../../hook/useResponsive";

const initialContext = {
    success: () => {},
    info: () => {},
    warn: () => {},
    alert: () => {},
    confirm: () => {}
}

const useStyles = makeStyles(theme => ({
    popup: {
        backgroundColor: props => props.backdrop ? 'rgba(0, 0, 0, 0.2)' : 'transparent'
    },
    divider: {
        backgroundColor: 'white'
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText
    },
    info: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText
    },
    confirm: {
        backgroundColor: theme.palette.confirm?.main || theme.palette.prompt?.main,
        color: theme.palette.confirm?.contrastText || theme.palette.prompt?.contrastText,
    }
}));

const PromptContext = React.createContext(initialContext);

export function PromptProvider(props) {
    const responsive = useResponsive();
    const isSmallScreen = responsive.isSmallScreen();
    const {children} = props;
    const classes = useStyles({isSmallScreen});
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const ref = useRef();

    const handleClose = (action, onAction, resolve, timestamp) => async (event) => {
        const value = action;
        resolve && resolve(action);
        onAction && await onAction(new UserEvent({action, value}, event));
        if(timestamp === ref.current?.timestamp) {
            ref.current = null;
            forceUpdate();
        }
    }

    const render = (message, {title, icon, variant, autoHideDuration,
                    onAction, actions = ['dismiss'], dictionary, vertical, horizontal} = {}, resolve) => {
        const className = clsx('appfuse-prompt', classes[variant]);
        const isSmallScreen = responsive.isSmallScreen();
        const timestamp = Date.now();
        const buttons = actions.map(action => {
            return <Button key={action} color="inherit" onClick={handleClose(action, onAction, resolve, timestamp)} text={action} />
        });
        const longTextLength = isSmallScreen ? 20 : 50;
        let subject, text;
        const $message = (message instanceof Error) ? renderError(message, dictionary) : message;
        if(title) {
            subject = title;
            text = lang.isString($message) ? $message : $message.toString();
        } else {
            if($message.indexOf('\n')!==-1 || $message.length > longTextLength) {
                text = $message;
                subject = variant?.toUpperCase();
            } else {
                subject = $message;
            }
        }
        subject = i18n.translate(subject);
        text = i18n.translate(text);
        const hasSubject = lang.isNotEmpty(subject);
        const hasText = lang.isNotEmpty(text);
        const hasManyButtons = buttons.length > 1;
        const maxWidth = isSmallScreen ? '100vw' : '50vw';
        const content = (
            <Box className={className} maxWidth={maxWidth} display="flex" flexDirection="column"  paddingTop={1} paddingX={1.5} paddingBottom={hasManyButtons ? 1 : 1.5} borderRadius={1} gap={1} fontSize="1rem" boxShadow={8} overflowY="hidden">
                <Box className="appfuse-prompt-header" gap={1} fullWidth display={hasSubject ? 'flex' : 'none'} alignItems='center' overflow="hidden">
                    {lang.isEmpty(icon) ? null : <Icon size="1.4em">{icon}</Icon>}
                    <Typography title={subject} noWrap={hasText}>{subject}</Typography>
                    {hasManyButtons && hasText ? null : <Box display="flex" flexGrow={1} justifyContent="flex-end" gap={0.5}>{buttons}</Box>}
                </Box>
                {subject && text && <Divider variant="fullWidth" className={classes.divider}/>}
                <Box className="appfuse-prompt-body" display={text ? 'flex' : 'none'} flexDirection="column" paddingLeft={0.5}  justifyContent="space-between" alignItems='flex-start'
                     overflowY="auto">
                    <Box whiteSpace="break-spaces" fullWidth >
                        <Typography noWrap={false}>{text}</Typography>
                    </Box>
                    {hasManyButtons ? <Box display="flex" fullWidth justifyContent="flex-end" gap={0.5}>{buttons}</Box> : null}
                </Box>
            </Box>
        );
        const backdrop = (variant === 'confirm' || variant === 'warning' || variant === 'error')
        const popup = {
            autoHideDuration, vertical, horizontal, backdrop, timestamp,
            onClose: handleClose('dismiss', onAction, resolve, timestamp),
            children: content
        }
        ref.current = popup;
        forceUpdate();
    }

    const success = async (message, {title, icon = 'check', onAction, actions, dictionary, vertical = 'top', horizontal = 'center'} = {}) => {
        const variant = 'success';
        const autoHideDuration = 8000;
        return new Promise((resolve, reject) => {
            render(message, {
                title, icon,
                variant, autoHideDuration,
                onAction, actions,
                dictionary, vertical, horizontal
            }, resolve);
        });
    }
    const info = async (message, {title, icon = 'check', onAction, actions, dictionary, vertical = 'top', horizontal = 'center'} = {}) => {
        const variant = 'info';
        const autoHideDuration = 10000;
        return new Promise((resolve, reject) => {
            render(message, {
                title, icon,
                variant, autoHideDuration,
                onAction, actions,
                dictionary, vertical, horizontal
            }, resolve);
        });
    }
    const warn = async (message, {title, icon = 'error_outline', error, onAction, actions, dictionary, vertical = 'center', horizontal = 'center'} = {}) => {
        const variant = 'warning';
        if(error && !title) {
            title = message;
            message = error;
        }
        return new Promise((resolve, reject) => {
            render(message, {
                title, icon,
                variant,
                onAction, actions,
                dictionary, vertical, horizontal
            }, resolve);
        });
    }
    const alert = async (message, {title, icon = 'cancel', error, onAction, actions, dictionary, vertical = 'center', horizontal = 'center'} = {}) => {
        const variant = 'error';
        if(error && !title) {
            title = message;
            message = error;
        }
        return new Promise((resolve, reject) => {
            render(message, {
                title,  icon,
                variant,
                onAction, actions,
                dictionary, vertical, horizontal
            }, resolve);
        });
    }

    const renderError = (error, dictionary) => {
        let text;
        if(error instanceof ConstraintError) {
            text = error.toString(dictionary);
        } else if(error instanceof Error) {
            text = error.message;
        } else {
            text = error.toString();
        }

        return i18n.translate(text);
    }

    const confirm = async (message, {title, icon = 'task_alt', onAction, actions, dictionary, vertical = 'center', horizontal = 'center'} = {}) => {
        const variant = 'confirm';
        return new Promise((resolve, reject) => {
            render(message, {
                title, icon,
                variant,
                actions, onAction,
                dictionary, vertical, horizontal
            }, resolve);
        });
    }

    return (
        <PromptContext.Provider value={{success, info, warn, alert, confirm}}>
            {children}
            {ref.current ? <Popup {...ref.current} /> : null}
        </PromptContext.Provider>
    );
}

function Popup({onClose, autoHideDuration, children, vertical, horizontal, backdrop, timestamp}) {
    const classes = useStyles({backdrop});
    const handleClose = useRef();

    useEffect(() => {
        const callback = handleClose.current;
        handleClose.current = onClose;
        if(callback) {
            callback();
        }
    }, [onClose, timestamp])

    const type = (backdrop || vertical === 'center') ? Popover : Snackbar;
    const options = {
        className: classes.popup,
        anchorOrigin: {
            vertical: vertical || 'center',
            horizontal: horizontal || 'center'
        },
        open: true,
        onClose: onClose
    };

    if(type === Popover) {
        options.transformOrigin = {
            vertical: 'bottom',
            horizontal: 'center',
        };
        options.anchorEl = document.body;
    } else {
        options.autoHideDuration = autoHideDuration;
    }
    return React.createElement(type, options, children);
}

PromptProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const PromptConsumer = PromptContext.Consumer;

export default PromptContext;