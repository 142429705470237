import {createSlice} from "@reduxjs/toolkit";

const name = 'farmerChannelQuestionnaire';

const initialState = {
    pageNumber: 0,
    pageSize: 10,
};

const slice = createSlice({
    name,
    initialState,
    reducers: {
        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
    }
});

export const { setPageNumber, setPageSize } = slice.actions;

export const selectPageNumber = state => state[name].pageNumber;
export const selectPageSize = state => state[name].pageSize;

const farmerChannelQuestionnaireReducer = slice.reducer;
export default farmerChannelQuestionnaireReducer;