import usePrompt from "../../lib/appfuse-react/hook/usePrompt";
import React, {useState} from "react";
import lang from "../../lib/appfuse-react/core/lang";
import {Dialog, DialogContent, Divider, Grid, TextField, Typography} from "@material-ui/core";
import Box from "../../lib/appfuse-react/component/Box";
import i18n from "../../lib/appfuse-react/core/i18n";
import Form from "../../lib/appfuse-react/component/Form";
import Button from "../../lib/appfuse-react/component/Button";
import UserEvent from "../../lib/appfuse-react/core/UserEvent";

function ForgotPasswordForm({onCancel, onApply}) {
    const prompt = usePrompt();
    const [value, setValue] = useState({});

    const handleChange = (event) => {
        const {value} = event;
        setValue(value);
    };

    const handleApply = async () => {
        if(lang.isEmpty(value?.username)) {
            prompt.confirm('Please enter your username.');
            return;
        }
        const {username} = value;
        onApply(new UserEvent({username}));
    };


    return (
        <Dialog open={true} onClose={onCancel} maxWidth='xs' fullWidth>
            <Box paddingTop={2}>
                <Typography align='center' variant='h6'>{i18n.translate('Forget Password')}</Typography>
            </Box>
            <Divider variant='middle'/>
            <DialogContent>
                <Box fullHeight fullWidth overflow='auto' paddingTop={2} paddingBottom={5} paddingX={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={{whiteSpace: 'pre-wrap'}} variant='body2' color='textSecondary'>
                                {i18n.translate("Enter your username and we'll send you a login link.")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Form value={value} onChange={handleChange} onSubmit={handleApply}>
                                {/*用戶名稱*/}
                                <TextField name='username' label='Username' variant='outlined' fullWidth required />
                            </Form>
                        </Grid>
                        <Grid item xs={6}>
                            <Button icon='save' text='OK' variant='contained' color='primary' fullWidth onClick={handleApply}/>
                        </Grid>
                        <Grid item xs={6}>
                            <Button icon='close' text='Cancel' variant='contained' color='primary' fullWidth onClick={onCancel}/>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default ForgotPasswordForm;