import React, {useEffect, useState} from "react";
import UserEvent from "../../core/UserEvent";
import lang from "../../core/lang";
import PropTypes from 'prop-types';
import {TextField as MuiTextField, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "../../core/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        '& input,textarea': {
            color: props => props.color
        }
    }
}));

function TextField(props) {
    const {value, length, readOnly, className, type, disabled} = props;
    const theme = useTheme();
    const style = {...props.style};

    const handleChange = event => {
        // 根據 Reactjs 最新的官方文件已經要求不要在 React DOM 使用 event.persist()
        // 使用了也無法避免瀏覽器清除 event 上的一些屬性，例如 currentTarget。
        // 所以我們把 currentTarget 保留下來，傳送給 async 的 function。
        // 與 Material UI 的 MuiTextField 相容，一樣提供 currentTarget。
        const {target, currentTarget, nativeEvent} = event;
        triggerChange({target, currentTarget}, nativeEvent);
    }

    const triggerChange = ({target, currentTarget}, nativeEvent) => {
        const value = target.value;
        props.onChange && props.onChange(new UserEvent({value, target, currentTarget}, nativeEvent));
    }

    const text = lang.isNullOrUndefined(value) ? '' : value;

    const options = lang.omit(props, 'value', 'disabled', 'readOnly', 'length', 'color');

    // 因為 material-ui 元件對 aria-label 的處理是透過在 input 上一層的 div 上產生，有同樣效果，但是無障礙掃描軟體無法偵測到，
    // 所以要加在 inputProps。
    let ariaLabel;
    if(props["aria-label"]) {
        ariaLabel = i18n.translate(props["aria-label"]);
    } else {
        ariaLabel = i18n.translate(lang.isString(props.label) ? props.label : '');
    }
    const inputProps = {
        'aria-label': ariaLabel,
        size: length,
        readOnly
    }

    const calcColor = (theme, color) => {
        const tokens = color.split('.');
        let themeColor = theme.palette[tokens[0]];
        for(let i = 1; i<tokens.length; i++) {
            if(themeColor[tokens[i]]) {
                themeColor = themeColor[tokens[i]];
            } else {
                break;
            }
        }
        return themeColor || color;
    };
    const color = lang.isFunction(props.color) ? props.color(text) : (props.color || theme.palette.text.primary);
    const effectiveColor = color ? calcColor(theme, color) : undefined;

    const classes = useStyles({color: effectiveColor});
    if(readOnly) {
        style.pointerEvents = '';
    }

    return (
        <MuiTextField {...options} aria-label={ariaLabel} classes={classes} className={clsx('appfuse-TextField', className)} style={style}
                      type={type} value={text} onChange={handleChange} inputProps={inputProps} disabled={disabled} />
    )
}

TextField.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.any,
    type: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
    size: PropTypes.oneOf(['large', 'medium', 'small']),
    fullWidth: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    length: PropTypes.number,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    "aria-label": PropTypes.string,
    color: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    multiline: PropTypes.bool,
}

TextField.defaultProps = {
    type: 'text'
}

export default TextField;