import {createSlice} from "@reduxjs/toolkit";
import {json} from "../../lib/appfuse-react/core/json";

const name = 'systemCode';

const initialState = {
    selection: [],
    pageNumber: 0,
    pageSize: 10,
    type: null,
};

const slice = createSlice({
    name,
    initialState,
    reducers: {
        setSelection(state, action) {
            state.selection = json.toJSON(action.payload);
        },
        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setType(state, action) {
            state.type = json.toJSON(action.payload);
        },
    }
});

export const { setSelection, setPageNumber, setPageSize, setType } = slice.actions;

export const selectSelection = state => json.fromJSON(state[name].selection);
export const selectPageNumber = state => state[name].pageNumber;
export const selectPageSize = state => state[name].pageSize;
export const selectType = state => json.fromJSON(state[name].type);

const systemCodeReducer = slice.reducer;
export default systemCodeReducer;
