const applets = [
    {
        "text": "Inbox",
        "icon": "/image/application/wheat.svg",
        "path": "inbox",
        "id": "Inbox",
        "disabled": true,
        "category": "My Favorite"
    },
    {
        "text": "Approved Processing",
        "icon": "/image/application/gears.svg",
        "path": "authProc",
        "id": "ApprovedProcessingInboxApplet",
        "disabled": true,
        "category": "My Favorite"
    },
    {
        "text": "Disqualification",
        "icon": "/image/myFavorite/corrupted-file.svg",
        "path": "disqualification",
        "id": "DisqualificationInbox",
        "disabled": true,
        "category": "My Favorite"
    },
    {
        "text": "Crop Sampling",
        "icon": "/image/samplingReport/crop-sampling.svg",
        "id": "CropSamplingApplet",
        "path": "cropSampling",
        "category": "Sampling Report"
    },
    {
        "text": "Approved Sampling",
        "icon": "/image/samplingReport/inspection.svg",
        "id": "ApprovedSamplingApplet",
        "path": "approvedSampling",
        "category": "Sampling Report"
    },
    {
        "text": "Crop Application Case",
        "icon": "/image/caseManagement/crop-application.svg",
        "id": "CropApplicationApplet",
        "path": "cropApplication",
        "category": "Case Management"
    },
    {
        "text": "Approved Processing Application Case",
        "icon": "/image/caseManagement/automated.svg",
        "id": "ApprovedProcessingApplicationApplet",
        "path": "authProcAPP",
        "category": "Case Management"
    },
    {
        "text": "Farmer",
        "icon": "/image/caseManagement/farmer.svg",
        "id": "FarmerApplet",
        "path": "farmer",
        "category": "Case Management"
    },
    {
        "text": "Mark Application Case",
        "icon": "/image/caseManagement/mark-application.svg",
        "id": "MarkApplicationApplet",
        "path": "markApplication",
        "category": "Case Management"
    },
    {
        "text": "Disqualification Application",
        "icon": "/image/caseManagement/disqualification-application.svg",
        "id": "DisqualificationApplicationApplet",
        "path": "disqualificationApplication",
        "category": "Case Management"
    },
    {
        "text": "Sms History",
        "icon": "/image/caseManagement/sms-history.svg",
        "id": "SmsHistoryApplet",
        "path": "smsHistory",
        "category": "Case Management"
    },
    {
        "text": "Case Summary",
        "icon": "/image/reportManagement/case-summary.svg",
        "id": "CaseSummaryApplet",
        "path":"caseSummary",
        "category": "Report Management"
    },
    {
        "text": "Certified Farmers List",
        "icon": "/image/reportManagement/certified-farmers-list.svg",
        "id": "CertifiedFarmersApplet",
        "path":"certifiedFarmers",
        "category": "Report Management"
    },
    {
        "text": "Organic Agriculture System",
        "icon": "/image/reportManagement/organic-agriculture-system.svg",
        "id": "OrganicAgricultureSystemApplet",
        "path":"organicAgricultureSystem",
        "category": "Report Management"
    },
    {
        "text": "Case Statistics",
        "icon": "/image/reportManagement/case-statistics.svg",
        "id": "CaseStatisticsApplet",
        "path":"caseStatistics",
        "category": "Report Management"
    },
    {
        "text": "Farmer Channel Questionnaire",
        "icon": "/image/reportManagement/farmer-channel-questionnaire.svg",
        "id": "FarmerChannelQuestionnaireApplet",
        "path":"farmerChannelQuestionnaire",
        "category": "Report Management"
    },
    {
        "text": "Farmer Statistics",
        "icon": "/image/reportManagement/farmer-statistics.svg",
        "id": "FarmerStatisticsApplet",
        "path":"farmerStatistics",
        "category": "Report Management"
    },
    {
        "text": "Account",
        "icon": "/image/system/account.svg",
        "id": "AccountApplet",
        "path": "account",
        "category": "System"
    },
    {
        "text": "Region",
        "icon": "/image/system/region.svg",
        "id": "RegionApplet",
        "path": "region",
        "category": "System"
    },
    {
        "text": "Headquarters",
        "icon": "/image/system/headquarters.svg",
        "id": "HeadquartersApplet",
        "path": "headquarters",
        "category": "System"
    },
    {
        "text": "Letter Template",
        "icon": "/image/system/letter-template.svg",
        "id": "LetterTemplateApplet",
        "path": "letterTemplate",
        "category": "System"
    },
    {
        "text": "Crop Category",
        "icon": "/image/system/crop-category.svg",
        "id": "CropCategory",
        "path": "cropCategory",
        "category": "System"
    },
    {
        "text": "System Code",
        "icon": "/image/system/system-code.svg",
        "id": "SystemCodeApplet",
        "path": "systemCode",
        "category": "System"
    }
]


export default applets;