import MuiToolbar from "@material-ui/core/Toolbar";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Button from "../../lib/appfuse-react/component/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

function Toolbar(props) {
    const classes = useStyles();
    const {dense} = props;
    const children = React.Children.toArray(props.children);
    const elements = React.Children.map(props.children, (child, index) => {
        let element = child;
        if(child.type===Button) {
            if(index===0) {
                const edge = 'start';
                element = React.cloneElement(child, {edge});
            } else if(index===children.length-1) {
                const edge = 'end';
                element = React.cloneElement(child, {edge});
            }
        }
        return element;
    });
    return (
        <MuiToolbar className={classes.root} variant={dense ? 'dense' : 'regular'}>
            {elements}
        </MuiToolbar>
    )
}

Toolbar.propTypes = {
    dense: PropTypes.bool
};

export default Toolbar;
