import {Box, Divider, makeStyles, Paper, Popover, Typography} from "@material-ui/core";
import Button from "../../lib/appfuse-react/component/Button";
import React from "react";
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

function UserProfile(props) {
    const classes = useStyles();
    const {anchorEl, open, userInfo} = props;
    return (
        <Popover anchorEl={anchorEl} open={open}
                 onClose={props.onClose}
                 anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'center',
                 }}
                 transformOrigin={{
                     vertical: 'top',
                     horizontal: 'center',
                 }}>
            <Paper className={classes.root}>
                <Typography align="center">{userInfo.displayName || userInfo.username}</Typography>
                <Box marginY={1} ><Divider /></Box>
                <div className={classes.buttons}>
                    <Button text="Change Password" onClick={props.onChangePassword} fullWidth color="primary" />
                    <Button text="Logout" onClick={props.onLogout} fullWidth color="primary" />
                </div>
            </Paper>
        </Popover>
    )
}

UserProfile.propTypes = {
    anchorEl: PropTypes.instanceOf(Element),
    open: PropTypes.bool,
    userInfo: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onChangePassword: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired
}


export default UserProfile;