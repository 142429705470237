import React, {useEffect, useRef, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Dialog, DialogContent, Divider, Drawer, Grid, TextField, useTheme} from "@material-ui/core";
import Launchpad from "../../component/Launchpad";
import {useDispatch, useSelector} from "../../store";
import {
    selectApplets,
    selectMessengers,
    selectUserInfo,
    setMessengers,
    setUserInfo
} from "../../reducer/iam/authReducer";
import { useHistory } from "react-router-dom";
import Button from "../../lib/appfuse-react/component/Button";
import Space from "../../lib/appfuse-react/component/Space";
import Typography from "@material-ui/core/Typography";
import SearchField from "../../component/SearchField";
import Expander from "../../lib/appfuse-react/component/Expander";
import Toolbar from "../../component/Toolbar";
import UserProfile from "./UserProfile";
import lang from "../../lib/appfuse-react/core/lang";
import {selectTitle} from "../../reducer/app/appReducer";
import i18n from "../../lib/appfuse-react/core/i18n";
import Box from "../../lib/appfuse-react/component/Box";
import env from "../../lib/appfuse-react/core/env";
import useRouter from "../../lib/appfuse-react/hook/useRouter";
import Image from "../../lib/appfuse-react/component/Image";
import authService from "../../service/iam/authService";
import usePrompt from "../../lib/appfuse-react/hook/usePrompt";
import Form from "../../lib/appfuse-react/component/Form";
import MailDetail from "./MailDetail";

const initialContext = {
    workspace: undefined
}

const WorkbenchContext = React.createContext(initialContext);

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 100,
        backgroundColor: theme.brand.appbar?.backgroundColor,
        color: theme.brand.appbar?.color,
    },
    drawer: {
        flexShrink: 0
    },
    drawerPaper: {
        top: theme.spacing(8),
        height: "100%",
        backgroundColor: 'rgba(255, 255, 255, 0.75)'
    }
}));

function Workbench(props) {
    const prompt = usePrompt();
    const theme = useTheme();
    const router = useRouter();
    const backgroundImage = router.resolve(theme.brand.backgroundImage);
    const classes = useStyles({backgroundImage});
    const history = useHistory();
    const dispatch = useDispatch();
    const [keyword, setKeyword] = useState(null);
    const [launcherOpen, setLauncherOpen] = useState(false);
    const [userProfileAnchorEl, setUserProfileAnchorEl] = useState(null);
    const [mailDetailAnchorEl, setMailDetailAnchorEl] = useState(null);
    const [changePasswordOpen, setChangePasswordOpen] = useState(false);
    const title = useSelector(selectTitle);
    const userInfo = useSelector(selectUserInfo);
    const applets = useSelector(selectApplets);
    const messengers = useSelector(selectMessengers);
    const responsive = env.responsive;
    const workspaceRef = useRef();
    const workspace = workspaceRef?.current;

    const {totalNumber} = messengers;

    const [showChangePassword, setShowChangePassword] = useState(false);
    const location = props.children.props.location;
    const {search} = location;
    const urlParams = new URLSearchParams(search);
    const changePassword = urlParams.get("changePassword");
    if (lang.isNotEmpty(changePassword) && changePassword==="true" && lang.isFalse(showChangePassword)) {
        setChangePasswordOpen(true);
        setShowChangePassword(true);
    }

    useEffect(() => {
        (async () => {
            await getUserInfo();
            await getMessenger();
        })();
    }, []);

    const getUserInfo = async () => {
        try {
            const userInfo = await authService.fetchUserInfo();
            if(userInfo.category==='farmer') {
                prompt.alert('No permission, can not log in', {title:'Failed to login'});
                await authService.logout();
                history.push('/logon');
                return;
            }
            dispatch(setUserInfo(userInfo));
        } catch (e) {
            prompt.alert(e, {title: 'Failed to fetch User Info.'});
        }
    };

    const getMessenger = async () => {
        const messengers = await authService.fetchMessenger();
        dispatch(setMessengers(messengers));
    }

    const handleToggleLauncher = () => setLauncherOpen(!launcherOpen);
    const handleCloseLauncher = () => setLauncherOpen(false);

    const handleLaunchpadClick = (event) => {
        const { path } = event;
        history.push(`/${path}`);
    };

    const handleToggleUserProfile = (event) => {
        setUserProfileAnchorEl(userProfileAnchorEl ? null : event.currentTarget);
    };

    const handleUserProfileClose = () => {
        setUserProfileAnchorEl(null);
    }

    const handleChangePasswordOpen = () => setChangePasswordOpen(true);
    const handleChangePasswordClose = () => setChangePasswordOpen(false);
    const handleChangePassword = async (value) => {
        await authService.changePassword(value);
    };

    const handleLogout = () => {
        setUserProfileAnchorEl(null);
        authService.logout();
        router.reload();
    }

    const handleSearch = event => {
        const keyword = event.value;
        setKeyword(keyword);
    }

    const handleMailDetailOpen = (event) => {
        setMailDetailAnchorEl(mailDetailAnchorEl ? null : event.currentTarget);
    }

    const handleMailDetailClose = () => {
        setMailDetailAnchorEl(null);
    }

    window.document.title = i18n.translate(title);

    const settings = env.get('app');

    const stageInfo = env.appStage.toUpperCase() === 'PROD' ? null : env.appStage;
    const versionInfo = env.appVersion + `${stageInfo ? '@' + stageInfo: ''}`;
    return (
        <Box className="app-workbench" fullWidth fullHeight overflow="hidden" display="flex" flexDirection="column">
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Image value={theme.brand.logo} onClick={()=> history.push('/main')} height={46} title={versionInfo} />
                    <Space spacing={1} />
                    <Typography variant="h5" noWrap>
                        {i18n.translate(title)}
                        <Space spacing={1} />
                        {stageInfo ? <Typography variant="body2" display="inline">{versionInfo}</Typography> : null}
                    </Typography>
                    <Box display={lang.isFalse(settings.search) ? 'none' : 'initial'}>
                        <SearchField label="Search" value={keyword} onChange={handleSearch} />
                    </Box>
                    <Expander />
                    <Typography variant="subtitle1" noWrap>{`${i18n.translate('Welcome')} ${userInfo?.name || ''}`}</Typography>
                    <Space spacing={2} />
                    <Button icon="mail" variant="icon" badge={totalNumber}  color="inherit" onClick={handleMailDetailOpen}/>
                    <Button icon="account_circle" variant="icon"  color="inherit" onClick={handleToggleUserProfile} />
                    <Button icon="apps" variant="icon" onClick={handleToggleLauncher}  color="inherit" />
                </Toolbar>
            </AppBar>
            <Box fullWidth fullHeight backgroundImage={backgroundImage} overflow="auto">
                <Box ref={workspaceRef} fullHeight fullWidth overflow="hidden" minWidth={`${responsive.minWidth}px`}>
                    <WorkbenchContext.Provider value={{workspace}}>
                        {props.children}
                    </WorkbenchContext.Provider>
                </Box>
            </Box>
            <Drawer className={classes.drawer}
                    classes={{ paper: classes.drawerPaper }}
                    anchor="right"
                    open={launcherOpen}
                    onClose={handleCloseLauncher}
                    PaperProps={{ style: { height: `calc(100% - 64px)`}}} >
                <Launchpad data={applets} onClick={handleLaunchpadClick} />
            </Drawer>
            <UserProfile anchorEl={userProfileAnchorEl} userInfo={userInfo}
                         open={lang.isNotNullOrUndefined(userProfileAnchorEl)}
                         onClose={handleUserProfileClose} onChangePassword={handleChangePasswordOpen} onLogout={handleLogout}/>
            <MailDetail anchorEl={mailDetailAnchorEl} open={lang.isNotNullOrUndefined(mailDetailAnchorEl)}
                        inboxCounts={messengers} onClose={handleMailDetailClose}/>
            {changePasswordOpen && <ChangePassword open={changePasswordOpen} onClose={handleChangePasswordClose} onApply={handleChangePassword}/>}
        </Box>
    )
}

const withWorkbench = WrappedComponent => (props) => {
    return (
        <Workbench>
            <WrappedComponent {...props} />
        </Workbench>
    );
};

function ChangePassword(props) {
    const prompt = usePrompt();
    const {open} = props;
    const [value, setValue] = useState({});

    const handleChange = (event) => {
        const {value} = event;
        setValue(value);
    };

    const handleOK = async () => {
        if(lang.isEmpty(value?.newPassword)) {
            prompt.confirm('Please enter the new password.');
            return;
        } else if(lang.isEmpty(value?.confirmNewPassword)) {
            prompt.confirm('Please enter the new password again.');
            return;
        }
        try {
            await props.onApply(value);
            prompt.success('Password changed successfully.');
            props.onClose();
        } catch (e) {
            prompt.alert(e, {title: 'Failed to change password.'});
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    return (
        <Dialog open={open} onClose={props.onClose} maxWidth='xs' fullWidth>
            <Box paddingTop={2}>
                <Typography align='center' variant='h6'>{i18n.translate('Change Password')}</Typography>
            </Box>
            <Divider variant='middle'/>
            <DialogContent>
                <Box fullHeight fullWidth overflow='auto' paddingTop={2} paddingBottom={5} paddingX={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Form value={value} onChange={handleChange} onSubmit={handleOK}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {/*新密碼*/}
                                        <TextField name='newPassword' label='Please enter the new password.' variant='outlined' fullWidth required type='password'/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/*確認新密碼*/}
                                        <TextField name='confirmNewPassword' label='Enter the new password again.' variant='outlined' fullWidth required type='password'/>
                                    </Grid>
                                </Grid>

                            </Form>
                        </Grid>
                        <Grid item xs={6}>
                            <Button icon='save' text='OK' variant='contained' color='primary' fullWidth onClick={handleOK}/>
                        </Grid>
                        <Grid item xs={6}>
                            <Button icon='close' text='Cancel' variant='contained' color='primary' fullWidth onClick={handleCancel}/>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default Workbench;

export { withWorkbench, WorkbenchContext };