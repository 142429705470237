import applets from './applets';
import users from './users';

const authService = [{
    matcher: 'login',
    reply: config => {
        const {headers = {}} = config;
        const authorization = headers.Authorization;
        if(authorization.startsWith('Basic ')) {
            const [, token] = authorization.split(' ');
            const [username, password] = atob(token).split(':');
            const user = users.find(user => user.username.toLowerCase()===username.toLowerCase() && user.password===password);
            if(user) {
                return [200, user, {
                    Authorization: authorization
                }];
            } else {
                return [401, "輸入的用戶名稱或密碼不正確．"];
            }
        } else {
            return [401, "請輸入用戶名稱與密碼．"];
        }
    }
}, {
    matcher: 'applets',
    reply: config => {
        return [200, applets];
    }
}]

export default authService;