import lang from "./lang";

const JAVA_STRING_FORMAT = new RegExp('%[d|s]');
class StringUtil {
    format(text, params) {
        let output;
        if(lang.isNullOrUndefined(text)) {
            output = '';
        } else if(lang.isArray(params)||lang.isObject(params)) {
            output = text;
            for(let index=0; JAVA_STRING_FORMAT.test(output); index++) {
                output = output.replace(JAVA_STRING_FORMAT, `\$\{${index}\}`)
            }
            lang.each(params, (value, index) => {
                output = output.replace(`$\{${index}}`, value);
            }, this);
        } else {
            output = text;
        }
        return output;
    }
}

const string = new StringUtil();

export default string;