import lang from "./lang";

class Sort {
    static asc(property) {
        const args = Array.from(arguments);
        const orders = args.map(arg => Order.asc(arg));
        return new Sort(orders);
    }

    static desc(property) {
        const args = Array.from(arguments);
        const orders = args.map(arg => Order.desc(arg));
        return new Sort(orders);
    }

    orders = [];

    constructor(orders = []) {
        this.orders = [...orders];
        this.freezeProperties('orders')
    }

    and(sort) {
        const orders = this.orders.concat(sort.orders);
        return new Sort(orders);
    }

    find(property) {
        return this.orders.find(order => order.property===property);
    }

    indexOf(property) {
        return this.orders.findIndex(order => order.property===property);
    }

    filter(fn) {
        const orders = this.orders.filter(fn);
        return new Sort(orders);
    }

    toJSON() {
        return {
            orders: this.orders.map(order => order.toJSON())
        };
    }

    toString() {
        let s = '';
        for(const order of this.orders) {
            s = s + `${order.property},${order.direction}&`;
        }
        if (s.length > 0) {
            s = s.substring(0, s.length - 1); //chop off last "&"
        }
        return s;
    }

    static fromJSON(jsonObject) {
        const orders = jsonObject.orders.map(order => Order.fromJSON(order));
        return new Sort(orders);
    }

    clone() {
        const json = this.toJSON();
        return Sort.fromJSON(json);
    }

    static of(value) {
        let sort;
        if(lang.isNullOrUndefined(value)) {
            sort = value;
        } else if(value instanceof Sort) {
            sort = value.clone();
        } else if(lang.isObject(value)) {
            sort = Sort.fromJSON(value);
        } else {
            throw new Error(`Type is not supported. Supported types are Sort or JSON object, but receive: ${value}`);
        }
        return sort;
    }

    defineProperty(name, value) {
        Object.defineProperty(this, name, {
            value : value,
            enumerable : true,
            writable : true,
            configurable : true
        });
    }

    freezeProperty(name) {
        Object.defineProperty(this, name, {
            writable : false,
            configurable : true
        });
    }

    freezeProperties(names) {
        lang.isArray(names) || (names = Array.from(arguments));
        names.forEach(name => this.freezeProperty(name), this);
    }
}

class Order {
    property = undefined;
    direction = undefined;

    static asc(property) {
        return new Order(Sort.Direction.ASC, property)
    }

    static desc(property) {
        return new Order(Sort.Direction.DESC, property)
    }

    static fromJSON(jsonObject) {
        return new Order(jsonObject.direction, jsonObject.property);
    }

    constructor(direction, property) {
        this.property = property;
        this.direction = direction;
        this.freezeProperties('property', 'direction');
    }

    toJSON() {
        return {
            property: this.property,
            direction: this.direction
        }
    }

    defineProperty(name, value) {
        Object.defineProperty(this, name, {
            value : value,
            enumerable : true,
            writable : true,
            configurable : true
        });
    }

    freezeProperty(name) {
        Object.defineProperty(this, name, {
            writable : false,
            configurable : true
        });
    }

    freezeProperties(names) {
        lang.isArray(names) || (names = Array.from(arguments));
        names.forEach(name => this.freezeProperty(name), this);
    }
}

Sort.Direction = {
    ASC: 'asc',
    DESC: 'desc'
}

export default Sort;