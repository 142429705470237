import React from "react";
import {withWorkbench} from "../../layout/Workbench";
import Box from "../../lib/appfuse-react/component/Box";

function Main(props) {
    return (
        <Box className="app-main" fullWidth fullHeight backgroundOpacity={0.5} backgroundColor="black">
        </Box>
    );
}

export default withWorkbench(Main);