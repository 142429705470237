import { Mock } from "../lib/appfuse-react/core/ajax";
import salesService from "../mock/sales";
import iamService from "../mock/iam";
import contentService from "../mock/content";
import inboxService from "../mock/inbox/inboxService";
import codeService from "../mock/code/codeService";
import questionnaireService from "../mock/questionnaire/questionnaireService";
import samplingCropService from "../mock/samplingCrop";
import cropApplicationTypeService from "../mock/cropApplication";
import farmerService from "../mock/farmer";

const mock = (function() {
    const mock = new Mock();
    mock.use(iamService);
    mock.use(contentService)
    mock.use(salesService);
    mock.use(inboxService);
    mock.use(codeService);
    mock.use(questionnaireService);
    mock.use(samplingCropService);
    mock.use(cropApplicationTypeService);
    mock.use(farmerService);
    return mock;
}());

const context = {mock};

export default context;

export {mock};