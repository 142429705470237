import {BrowserRouter as Router, useHistory} from 'react-router-dom';
import React, {useContext, useEffect, useState} from "react";
import {NotifProvider} from "../Notif";
import {PromptProvider} from "../Prompt";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import ajax from "../../core/ajax";
import useRouter from "../../hook/useRouter";
import env from "../../core/env";
import browser from "../../core/browser";
import logger from "../../core/logger";

const log = logger.getLogger('appfuse.component.AppFuse');

moment.locale("en"); // it is required to select default locale manually

const initialContext = {
    basename: undefined,
    resolve: () => {},
    addRoute: () => {},
    home: () => {},
    main: () => {},
    login: () => {},
    register: () => {},
    reload: () => {}
}

const AppFuseContext = React.createContext(initialContext);

function AppFuseProvider(props) {
    const {children} = props;
    const [basename, setBasename] = useState();
    const [ready, setReady] = useState(false)
    const context = {};

    useEffect(() => {
        (async () => {
            const response = await fetch('.', {method: 'HEAD'});
            const basename = response.headers.has('Basename') ? response.headers.get('Basename') : '/';
            env.basename = basename;
            setBasename(basename);
            if(response.headers.has('ServerURL')) {
                ajax.baseURL = response.headers.get('ServerURL');
            }
            if(response.headers.has('AppName')) {
                env.appName = response.headers.get('AppName');
            }
            if(response.headers.has('AppVersion')) {
                env.appVersion = response.headers.get('AppVersion');
            }
            if(response.headers.has('AppStage')) {
                env.appStage = response.headers.get('AppStage');
            }
            const locale = browser.language;
            try {
                await import(`moment/locale/${locale.toLowerCase()}`);
            } catch(e) {
                log.info(`Failed to load the locale(${locale.toLowerCase()}) of moment: ${`moment/locale/${locale.toLowerCase()}`}`, e)
            }
            setReady(true);
        })();
    }, []);

    context.basename = basename;

    context.resolve = (path) => {
        let effectivePath;
        if(path?.startsWith('http')) {
            effectivePath = path;
        } else if(path?.startsWith(basename)) {
            // a path has been already resolved.
            effectivePath = path;
        } else if(path?.startsWith('/')) {
            effectivePath = `${basename}/${path.substring(1)}`;
            effectivePath = effectivePath.replaceAll('//', '/');
        } else {
            effectivePath = path;
        }

        if(effectivePath.startsWith('/')) {
            const location = window.location;
            // basename must start with '/'
            effectivePath = `${location.protocol}//${location.host}${effectivePath}`
        }

        return effectivePath;
    }

    const router = (
        <Router basename={basename}>
            <AppFuse>
                {children}
            </AppFuse>
        </Router>
    )

    return (
        <AppFuseContext.Provider value={context}>
            {ready ? router : null}
        </AppFuseContext.Provider>
    )
}

function AppFuse(props) {
    const {children} = props;
    const context = useContext(AppFuseContext);
    const history = useHistory();
    const router = useRouter();

    context.addRoute = (name, path) => {
        context[name] = () => history.push(path);
    }
    context.login = () => history.push('/logon');
    context.register = () => history.push('/registration');
    context.home = () => history.push('/');
    context.main = () => history.push('/main');
    context.reload = () => {
        window.location = router.resolve('/');
    }

    const locale = browser.language;
    return (
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={locale.toLowerCase()}>
            <NotifProvider>
                <PromptProvider>
                    {children}
                </PromptProvider>
            </NotifProvider>
        </MuiPickersUtilsProvider>
    )
}

const AppFuseConsumer = AppFuseContext.Consumer;

export default AppFuseContext;

export { AppFuseProvider, AppFuseConsumer }