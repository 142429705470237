import {fade, makeStyles} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import {InputBase} from "@material-ui/core";
import React from "react";
import UserEvent from "../../lib/appfuse-react/core/UserEvent";
import PropTypes from 'prop-types';
import i18n from "../../lib/appfuse-react/core/i18n";
import lang from "../../lib/appfuse-react/core/lang";

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    }
}));

function SearchField(props) {
    const classes = useStyles();
    const { value, label } = props;

    const handleChange = event => {
        const value = event.currentTarget.value.length===0 ? null : event.currentTarget.value;

        props.onChange && props.onChange(new UserEvent( { value }, event ));
    }

    const placeholder = `${i18n.translate(label || 'Search')}...`;

    const text = lang.isNullOrUndefined(value) ? '' : value;

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder={placeholder}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                value={text}
                onChange={handleChange}
            />
        </div>
    )
}

SearchField.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func
}

export default SearchField;