const registrationService = [{
    matcher: 'registration/requestToken',
    reply: config => {
        return [200];
    }
}, {
    matcher: 'registration/verifyEmail',
    reply: config => {
        return [200];
    }
}]

export default registrationService;