import React, {useEffect, useRef} from 'react';
import i18n from '../../core/i18n';
import UserEvent from "../../core/UserEvent";
import PropTypes from 'prop-types';
import lang from "../../core/lang";
import numeral from "../../core/numeral";
import reactjs from "../../core/reactjs";
import logger from "../../core/logger";
import Box from "../Box";
import clsx from "clsx";

const log = logger.getLogger('Form');

const initialContext = {
    value: () => ({}),
    index: () => -1,
}

const FormContext = React.createContext(initialContext);

function Form(props) {
    const { index = -1, children, disabled, readOnly } = props;
    const options = lang.omit(props, 'value', 'change', 'onChange', 'children', 'readOnly');
    options.className = clsx('appfuse-form', options.className);

    const context = {
        index: () => index,
        value: () => ({...props.value})
    }

    const parseChildren = children => {
        return children.reduce((child, counter) => {
            const count = parseChild(child);
            for(const [k, v] of count.entries()) {
                if(counter.hasOwnProperty(k)) {
                    counter[k]+= v;
                } else {
                    counter[k] = v;
                }
            }
        }, {});
    }

    const parseChild = child => {
        let counter = {};
        const isElement = React.isValidElement(child);
        if(!isElement) return counter;

        const {name, children} = child.props;
        if(name) {
            counter[name] = 1;
        } else {
            if(children) {
                counter = parseChildren(children);
            }
        }
        return counter;
    }
    const renderChildren = (children, values = {}, {disabled, readOnly}) => {
        const elements = React.Children.map(children, child => renderChild(child, values, {disabled, readOnly}));
        return elements.length===1 ? elements[0] : elements;
    }

    const renderChild = (child, values = {}, {disabled, readOnly}) => {
        const isElement = React.isValidElement(child);
        if(!isElement) return child;

        let clone;

        if(child.props.name) {
            try {
                clone = renderInput(child, values, {disabled, readOnly});
            } catch (e) {
                const { name } = child.props;
                const value = values[name];
                const message = `Failed to render the input: name=${name}, value=${value}`;
                log.error(`${message}\n${e}`);
                throw new Error(message);
            }

        } else {
            clone = renderOther(child, values, {disabled, readOnly});
        }

        return clone;
    }
    const renderInput = (child, values = {}, {disabled, readOnly}) => {
        const options = {};
        const name = child.props.name;
        if(child.props.label) {
            options.label = lang.isString(child.props.label) ? i18n.translate(child.props.label) : child.props.label;
        }
        if(child.props.helperText) {
            options.helperText = lang.isString(child.props.helperText) ? i18n.translate(child.props.helperText) : child.props.helperText;
        }

        const value = values[name];
        const nullable = child.props.nullable && (child.props.nullable!=='false');

        lang.isNullOrUndefined(child.props.disabled) && (options.disabled = disabled);
        if(lang.isNullOrUndefined(child.props.readOnly)) {
            options.readOnly = lang.isFunction(readOnly) ? readOnly(value, {value: values, name}) : readOnly;
        }
        options.style = {};
        if (lang.isObject(child.props.style)) options.style = child.props.style;
        if (lang.isNullOrUndefined(child.props.style?.pointerEvents)) {
            options.style.pointerEvents = options.readOnly ? 'none' : '';
        }

        if(lang.isNullOrUndefined(value)) {
            const { type, multiple } = child.props;
            if(lang.isNullOrUndefined(child.props.value)) {
                // controls without the designed value.
                if(nullable) {
                    options.value = null;
                } else if(type==='array' || multiple) {
                    options.value = [];
                } else if(type==='object') {
                    options.value = {}
                } else {
                    options.value = '';
                    options.checked = false;
                }
            } else {
                options.checked = false;
            }
        } else {
            if(child.props.value) {
                // checkbox-like control with designed value.
                options.checked = value?.includes(child.props.value);
            } else {
                options.value = value;
                if(lang.isBoolean(value)) {
                    // checkbox-like control without designed value.
                    options.checked = value;
                }
            }
        }

        options.onChange = (event, value) => handleChange(name, event, value);

        return React.cloneElement(child, options);
    }

    const renderOther = (child, values = {}, {disabled, readOnly}) => {
        const options = {};
        if(child.props.label) {
            options.label = lang.isString(child.props.label) ? i18n.translate(child.props.label) : child.props.label;
        }
        if(child.props.helperText) {
            options.helperText = lang.isString(child.props.helperText) ? i18n.translate(child.props.helperText) : child.props.helperText;
        }
        if(child.props.children) {
            options.children = renderChildren(child.props.children, values, {disabled, readOnly});
        }
        return React.cloneElement(child, options);
    }

    // name is the forwarded parameter by closure.
    const handleChange = (name, event, value) => {
        let newValue;
        if(lang.isUndefined(value)) {
            // has only one argument
            if(lang.isPrimitive(event)) {
                // value is the 1st argument
                newValue = event;
            } else if(lang.has(event, 'value')) {
                // Form-like
                newValue = event.value;
            } else if(event.target) {
                // Native HTML form control-like
                if(event.target.type==='number') {
                    newValue = numeral.parse(event.target.value);
                } else {
                    newValue = event.target.value;
                }
            } else {
                throw new Error(`Failed to handle the change for the input control: name=${name}, event=${event}, value=${value}`)
            }
        } else {
            if(reactjs.isElement(value)) {
                // material-ui Select-like
                newValue = value.props.value;
            } else if(lang.isBoolean(value) && event?.currentTarget?.value &&
                !(event.currentTarget.value==='true' || event.currentTarget.value==='false')) {
                // checkbox-like control with designed value.
                const effectiveValue = {...props.value, ...props.change}
                const prevValue = effectiveValue[name] || [];
                newValue = prevValue.filter(item => item !== event.currentTarget.value);
                value && (newValue.push(event.currentTarget.value));
            } else {
                newValue = value;
            }
        }
        const nativeEvent = (event instanceof UserEvent || event instanceof Event) ? event : undefined;
        const changeEvent = new UserEvent({value: newValue, name}, nativeEvent);
        handleSyntheticChange(changeEvent);
    }

    const handleSyntheticChange = event => {
        const change = props.change ? {...props.change} : {};
        change[event.name] = (event.value === '' ? null : event.value);
        const value = {...props.value, ...change};

        const bounce = event.nativeEvent?.bounce;
        log.debug(`receive change event: ${event.name} = ${event.value}, bounce = ${bounce}`);

        triggerChange(value, change, event.name, event.nativeEvent);
    }

    const triggerChange = (value, change, name, nativeEvent) => {
        const changeEvent = new UserEvent({value, change, name}, nativeEvent);
        props.onChange && props.onChange(changeEvent);
        log.debug(`trigger change event: value => ${JSON.stringify(value)}, change => ${JSON.stringify(change)}`);
    }

    const handleKeyPress = event => {
        if(event.key === "Enter") {
            const change = {...props.change};
            const value = {...props.value, ...change};
            const nativeEvent = (event instanceof UserEvent || event instanceof Event) ? event : undefined;
            triggerSubmit(value, change, nativeEvent);
        }
        props.onKeyPress && props.onKeyPress(event);
    }

    const triggerSubmit = (value, change, nativeEvent) => {
        const submitEvent = new UserEvent({value, change}, nativeEvent);
        props.onSubmit && props.onSubmit(submitEvent);
    }

    const content = renderChildren(children, {...props.value, ...props.change}, {disabled, readOnly});

    return (
        <FormContext.Provider value={context}>
            <Box {...options} tabIndex={0} onKeyPress={handleKeyPress}>
                {content}
            </Box>
        </FormContext.Provider>
    );
}

Form.propTypes = {
    value: PropTypes.object,
    change: PropTypes.object,
    index: PropTypes.number,
    readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

    // props inherits from Box
    // Display
    display: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    displayPrint: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    overflow: PropTypes.string,
    overflowX: PropTypes.string,
    overflowY: PropTypes.string,
    textOverflow: PropTypes.string,
    visibility: PropTypes.string,
    cursor: PropTypes.string,

    // Shadows
    boxShadow: PropTypes.number,

    // Flexbox
    flexDirection: PropTypes.string,
    flexWrap: PropTypes.string,
    justifyContent: PropTypes.string,
    alignItems: PropTypes.string,
    alignContent: PropTypes.string,
    gap: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    order: PropTypes.number,
    flexGrow: PropTypes.number,
    flexShrink: PropTypes.number,
    alignSelf: PropTypes.string,

    // Spacing
    padding: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    paddingX: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    paddingY: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    paddingTop: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    paddingRight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    paddingBottom: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    paddingLeft: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    margin: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    marginX: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    marginY: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    marginTop: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    marginRight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    marginBottom: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    marginLeft: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),

    // Sizing
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    maxWidth: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    minWidth: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    maxHeight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    minHeight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    boxSizing: PropTypes.string,
    fullWidth: PropTypes.bool,
    fullHeight: PropTypes.bool,

    // Typography
    fontFamily: PropTypes.string,
    fontSize: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    fontStyle: PropTypes.string,
    fontWeight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    letterSpacing: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    lineHeight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    whiteSpace: PropTypes.string,

    // Palette
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    backgroundImage: PropTypes.string,
    backgroundSize: PropTypes.string,
    backgroundRepeat: PropTypes.string,
    backgroundOpacity: PropTypes.number,
    opacity: PropTypes.number,

    // Border
    border: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    borderTop: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    borderLeft: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    borderRight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    borderBottom: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    borderRadius: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    borderColor: PropTypes.string,

    // Position
    position: PropTypes.string,
    zIndex: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    top: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    right: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    bottom: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    left: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),

    // Event,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onSubmit: PropTypes.func,

    disabled: PropTypes.bool
}

Form.defaultProps = {}
export default Form;
export {FormContext};