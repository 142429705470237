const cropInboxes = [
    {
        "uuid":"1",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGA00150-C-0-00-001",
        "type":"initial",
        "phase":"documentReview",
        "activity":"documentReview_S10",
        "farmerNo":"CGA00150",
        "farmerName":"王森林",
        "cropApplication": {
            "id":"1",
            "applicant":"王森林",
            "idNo": "A123456789",
            "farmName": "興興",
            "applicantType": "individual",
            "gender": "male",
            "birthday": "1979/03/23",
            "phone": "03-2912-1292",
            "contactPerson": "王大濃",
            "farmType": ["production"],
            "applyApprovedProcessing": true,
            "contactPersonPhone": "0912123321",
            "conservationTarget": "species2",
            "species": "青蛙",
            "category":"crop",
            "type":"initial",
            "createdOn":"2021-05-12T08:20:10.000Z",
            "totalImplementationArea":1.1678,
            "nonGreenArea": 0.345,
            "fieldLands":[
                {
                    "id":"1",
                    "fieldNo":"F01",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"249",
                    "actualCultivatedArea":0.2968
                },
                {
                    "id":"2",
                    "fieldNo":"F02",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"246",
                    "actualCultivatedArea":0.0305
                },
                {
                    "id":"3",
                    "fieldNo":"F03",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"218",
                    "actualCultivatedArea":0
                },
                {
                    "id":"4",
                    "fieldNo":"F04",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"277",
                    "actualCultivatedArea":0.0719
                },
                {
                    "id":"5",
                    "fieldNo":"F05",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"278",
                    "actualCultivatedArea":0.1417
                },
                {
                    "id":"6",
                    "fieldNo":"F06",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"346",
                    "actualCultivatedArea":0.3405
                },
                {
                    "id":"7",
                    "fieldNo":"F07",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"329",
                    "actualCultivatedArea":0.2864
                }
            ],
            "fieldCrops":[
                {
                    "fieldNos":["F01","F02"],
                    "crops":"包心白菜、結球萵苣",
                    "variety":"高雄149",
                    "estimatedProductionPeriods":["1月","11月","12月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                },
                {
                    "fieldNos":["F03","F04","F05"],
                    "crops":"蘿蔔、胡蘿蔔",
                    "variety":"白玉蘿蔔",
                    "estimatedProductionPeriods":["1月","2月","10月","11月","12月"],
                    "plantingMethod":"seedling",
                    "seedSources":["selfSeeding","outsourcing"]
                },
                {
                    "fieldNos":["F03"],
                    "crops":"地瓜",
                    "variety":"台農 157",
                    "estimatedProductionPeriods":["6月","7月","8月","9月","10月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                }
            ],
            "confirmStatement1": true,
            "confirmStatement2": true,
            "confirmStatement3": true,
            "confirmStatement4": true,
            "confirmStatement5": true,
            "confirmStatement6": true
        },
        "workflowHistory": [
            {
                "phase": "documentReview",
                "activity": "documentReview_S10",
                "createdOn": "2021/9/10",
                "assigneeName": "王小華",
                "examination": "qualified",
            },
            {
                "phase": "documentReview",
                "activity": "documentReview_S20",
                "createdOn": "2021/9/10",
                "assigneeName": "張小花",
                "examination": "qualified",
            }
        ]
    },
    {
        "uuid":"2",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGA00152-C-0-00-001",
        "type":"initial",
        "phase":"documentReview",
        "activity":"documentReview_S20",
        "farmerNo":"CGA00152",
        "farmerName":"李大同",
        "cropApplication": {
            "id":"2",
            "applicant":"李大同",
            "idNo": "A123456789",
            "farmName": "興興",
            "applicantType": "individual",
            "gender": "male",
            "birthday": "1979/03/23",
            "phone": "03-2912-1292",
            "contactPerson": "王大濃",
            "farmType": ["production"],
            "applyApprovedProcessing": true,
            "contactPersonPhone": "0912123321",
            "conservationTarget": "habitat1",
            "category":"crop",
            "type":"initial",
            "createdOn":"2021-03-12T08:20:10.000Z",
            "totalImplementationArea":1.1678,
            "nonGreenArea": 0.345,
            "fieldLands":[
                {
                    "id":"1",
                    "fieldNo":"F01",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"249",
                    "actualCultivatedArea":0.2968
                },
                {
                    "id":"2",
                    "fieldNo":"F02",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"246",
                    "actualCultivatedArea":0.0305
                },
                {
                    "id":"3",
                    "fieldNo":"F03",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"218",
                    "actualCultivatedArea":0
                },
                {
                    "id":"4",
                    "fieldNo":"F04",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"277",
                    "actualCultivatedArea":0.0719
                },
                {
                    "id":"5",
                    "fieldNo":"F05",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"278",
                    "actualCultivatedArea":0.1417
                },
                {
                    "id":"6",
                    "fieldNo":"F06",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"346",
                    "actualCultivatedArea":0.3405
                },
                {
                    "id":"7",
                    "fieldNo":"F07",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"329",
                    "actualCultivatedArea":0.2864
                }
            ],
            "fieldCrops":[
                {
                    "fieldNos":["F01","F02"],
                    "crops":"包心白菜、結球萵苣",
                    "variety":"高雄149",
                    "estimatedProductionPeriods":["1月","11月","12月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                },
                {
                    "fieldNos":["F03","F04","F05"],
                    "crops":"蘿蔔、胡蘿蔔",
                    "variety":"白玉蘿蔔",
                    "estimatedProductionPeriods":["1月","2月","10月","11月","12月"],
                    "plantingMethod":"seedling",
                    "seedSources":["selfSeeding","outsourcing"]
                },
                {
                    "fieldNos":["F03"],
                    "crops":"地瓜",
                    "variety":"台農 157",
                    "estimatedProductionPeriods":["6月","7月","8月","9月","10月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                }
            ],
            "confirmStatement1": true,
            "confirmStatement2": true,
            "confirmStatement3": true,
            "confirmStatement4": true,
            "confirmStatement5": true,
            "confirmStatement6": true
        },
        "workflowHistory": [
            {
                "phase": "documentReview",
                "activity": "documentReview_S10",
                "createdOn": "2021/9/10",
                "assigneeName": "王小華",
                "examination": "qualified",
            },
            {
                "phase": "documentReview",
                "activity": "documentReview_S20",
                "createdOn": "2021/9/10",
                "assigneeName": "張小花",
                "examination": "qualified",
            }
        ]
    },
    {
        "uuid": "3",
        "createdOn": "2021-03-12T08:20:10.000Z",
        "appNo": "CGA00154-C-0-00-001",
        "type": "initial",
        "phase": "documentReview",
        "activity": "documentReview_S30",
        "farmerNo": "CGA00153",
        "farmerName": "李小異"
    },
    {
        "uuid":"16",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGB00097-C-0-00-001",
        "type":"random",
        "phase":"documentReview",
        "activity":"documentReview_S60",
        "farmerNo":"CGB00100",
        "farmerName":"李文琦",

        "cropSampling": {
            "farmer": "CGB00103 陳曉梧",
            "reason": "隨機",
            "createdOn": "2021-10-06T08:20:10.000Z"
        }
    },
    {
        "uuid":"4",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGB00090-C-0-00-001",
        "type":"initial",
        "phase":"onsiteInspection",
        "activity":"onsiteInspection_S10",
        "farmerNo":"CGB00090",
        "farmerName":"吳小花"
    },
    {
        "uuid":"5",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGB00033-C-0-00-001",
        "type":"initial",
        "phase":"onsiteInspection",
        "activity":"onsiteInspection_S20",
        "farmerNo":"CGB00090",
        "farmerName":"吳小明"
    },
    {
        "uuid":"6",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGB00088-C-0-00-001",
        "type":"initial",
        "phase":"onsiteInspection",
        "activity":"onsiteInspection_S30",
        "farmerNo":"CGB00090",
        "farmerName":"張嘉佳"
    },
    {
        "uuid":"7",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGB00089-C-0-00-001",
        "type":"initial",
        "phase":"inspectionReview",
        "activity":"inspectionReview_S10",
        "farmerNo":"CGB00091",
        "farmerName":"黃美辛"
    },
    {
        "uuid":"8",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGB00090-C-0-00-001",
        "type":"initial",
        "phase":"inspectionReview",
        "activity":"inspectionReview_S20",
        "farmerNo":"CGB00092",
        "farmerName":"黃竹芯"
    },
    {
        "uuid":"9",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGB00091-C-0-00-001",
        "type":"initial",
        "phase":"inspectionReview",
        "activity":"inspectionReview_S30",
        "farmerNo":"CGB00093",
        "farmerName":"黃沐邤",


        "visitReportA01": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "A01查訪表單.pdf",
            "lastModified": 0
        },
        "visitReportA02": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "A02採樣表.pdf",
            "lastModified": 0
        },
        "visitReportA04": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "A04照片報告.pdf",
            "lastModified": 0
        },
        "visitReportA03": {
            "suggestedResult": "belong",
            "visitMethod": "dedicated"
        },
        "visitReportA06": [
            {
                "crop": "作物1",
                "variety": "品種1",
                "seedYear": "1",
                "cultivateFeature": "特性1",
                "useFeature": "特色1",
                "applyGreenConservation": true,
            },
            {
                "crop": "作物2",
                "variety": "品種2",
                "seedYear": "2",
                "cultivateFeature": "特性2",
                "useFeature": "特色3"
            }
        ]
    },
    {
        "uuid":"10",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGB00092-C-0-00-001",
        "type":"initial",
        "phase":"inspectionReview",
        "activity":"inspectionReview_S40",
        "farmerNo":"CGB00094",
        "farmerName":"黃鉊鑫",

        "visitReportA01": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "A01查訪表單.pdf",
            "lastModified": 0
        },
        "visitReportA02": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "A02採樣表.pdf",
            "lastModified": 0
        },
        "visitReportA04": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "A04照片報告.pdf",
            "lastModified": 0
        },
        "visitReportA03": {
            "suggestedResult": "belong",
            "visitMethod": "dedicated"
        },
        "visitReportA06": [
            {
                "crop": "作物1",
                "variety": "品種1",
                "seedYear": "1",
                "cultivateFeature": "特性1",
                "useFeature": "特色1",
                "applyGreenConservation": true,
            },
            {
                "crop": "作物2",
                "variety": "品種2",
                "seedYear": "2",
                "cultivateFeature": "特性2",
                "useFeature": "特色3"
            }
        ],
        "reminderInspector": "整個規劃很棒，充分運用各種資源",
        "suggestion": "沒有建議",
        "reviewResult": "pass",
        "drugTestResult": "pass",
        "drugTestReport": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "A05藥檢報告.pdf",
            "lastModified": 0
        }
    },
    {
        "uuid":"11",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGB00092-C-0-00-001",
        "type":"regular2",
        "phase":"inspectionReview",
        "activity":"inspectionReview_S40",
        "farmerNo":"CGB00094",
        "farmerName":"黃鉊鑫",

        "visitReportA01": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "A01查訪表單.pdf",
            "lastModified": 0
        },
        "visitReportA02": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "A02採樣表.pdf",
            "lastModified": 0
        },
        "visitReportA04": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "A04照片報告.pdf",
            "lastModified": 0
        },
        "visitReportA03": {
            "suggestedResult": "belong",
            "visitMethod": "dedicated"
        },
        "visitReportA06": [
            {
                "crop": "作物1",
                "variety": "品種1",
                "seedYear": "1",
                "cultivateFeature": "特性1",
                "useFeature": "特色1",
                "applyGreenConservation": true,
            },
            {
                "crop": "作物2",
                "variety": "品種2",
                "seedYear": "2",
                "cultivateFeature": "特性2",
                "useFeature": "特色3"
            }
        ],
        "reminderInspector": "整個規劃很棒，充分運用各種資源",
        "suggestion": "沒有建議",
        "reviewResult": "pass",
        "drugTestResult": "pass",
        "drugTestReport": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "A05藥檢報告.pdf",
            "lastModified": 0
        }
    },
    {
        "uuid":"12",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGB00093-C-0-00-001",
        "type":"initial",
        "phase":"recheckReview",
        "activity":"recheckReview_S20",
        "farmerNo":"CGB00096",
        "farmerName":"戴宇齊",

        "drugTestResult": "fail",
        "drugTestReport": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "A05藥檢報告.pdf",
            "lastModified": 0
        },
        "speculateReason": "農藥施放時僅少部份殘留在目標作物上，達到防治或調節效果，大部份會經由蒸發、飄散、沉降、淋洗、溢流、滲濾、吸附及攝食而分別進入空氣、土壤、河川及生物鏈中。",
        "speculateReasonAttachment": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "推測檢出原因.pdf",
            "lastModified": 0
        },
        "improvement": "農藥施放時僅少部份殘留在目標作物上，達到防治或調節效果，大部份會經由蒸發、飄散、沉降、淋洗、溢流、滲濾、吸附及攝食而分別進入空氣、土壤、河川及生物鏈中。",
        "improvementAttachment": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "改善方式.pdf",
            "lastModified": 0
        }
    },
    {
        "uuid":"13",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGB00094-C-0-00-001",
        "type":"initial",
        "phase":"recheckReview",
        "activity":"recheckReview_S30",
        "farmerNo":"CGB00097",
        "farmerName":"戴賢棋",

        "drugTestResult": "fail",
        "drugTestReport": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "A05藥檢報告.pdf",
            "lastModified": 0
        },
        "speculateReason": "農藥施放時僅少部份殘留在目標作物上，達到防治或調節效果，大部份會經由蒸發、飄散、沉降、淋洗、溢流、滲濾、吸附及攝食而分別進入空氣、土壤、河川及生物鏈中。",
        "speculateReasonAttachment": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "推測檢出原因.pdf",
            "lastModified": 0
        },
        "improvement": "農藥施放時僅少部份殘留在目標作物上，達到防治或調節效果，大部份會經由蒸發、飄散、沉降、淋洗、溢流、滲濾、吸附及攝食而分別進入空氣、土壤、河川及生物鏈中。",
        "improvementAttachment": {
            "type": "",
            "size": 0,
            "bytes": "",
            "name": "改善方式.pdf",
            "lastModified": 0
        },
        "reviewOpinion": "農藥施放時僅少部份殘留在目標作物上，達到防治或調節效果，大部份會經由蒸發、飄散、沉降、淋洗、溢流、滲濾、吸附及攝食而分別進入空氣、土壤、河川及生物鏈中。"
    },
    {
        "uuid":"14",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGB00095-C-0-00-001",
        "type":"initial",
        "phase":"recheckReview",
        "activity":"recheckReview_S40",
        "farmerNo":"CGB00098",
        "farmerName":"戴斯琦",
    },
    {
        "uuid":"15",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGB00096-C-0-00-001",
        "type":"initial",
        "phase":"recheckReview",
        "activity":"recheckReview_S50",
        "farmerNo":"CGB00099",
        "farmerName":"戴天祈",
    }
]

export default cropInboxes;