import PropTypes from 'prop-types';
import {Slide, Typography} from "@material-ui/core";
import React, {useContext, useState} from 'react';
import {SnackbarProvider, useSnackbar} from "notistack";
import Button from "../Button";
import {makeStyles} from "@material-ui/core/styles";
import Icon from "../Icon";
import Box from "../Box";

const initialContext = {
    success: () => {},
    info: () => {},
    warn: () => {},
    alert: () => {}
}

const styles = {
    success: {
        fontSize: '0.9rem',
        lineHeight: '1.2em'
    },
    error: {
        fontSize: '0.9rem',
        lineHeight: '1.2em'
    },
    warning: {
        fontSize: '0.9rem',
        lineHeight: '1.2em'
    },
    info: {
        fontSize: '0.9rem',
        lineHeight: '1.2em'
    }
};

const useStyles = makeStyles(styles);

const NotifContext = React.createContext(initialContext);

function NotifProvider(props) {
    const {children, max = 3, autoHideDuration = 5000} = props;
    const classes = useStyles();

    return (
        <NotifContext.Provider value={initialContext}>
            <SnackbarProvider maxSnack={max}
                              autoHideDuration={autoHideDuration}
                              hideIconVariant
                              TransitionComponent={Slide}
                              anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                              classes={{
                                  variantSuccess: classes.success,
                                  variantError: classes.error,
                                  variantWarning: classes.warning,
                                  variantInfo: classes.info,
                              }}>
                <Notif>
                    {children}
                </Notif>
            </SnackbarProvider>
        </NotifContext.Provider>
    );
}

function Notif(props) {
    const {children} = props;
    const context = useContext(NotifContext);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const handleDismiss = key => () => {
        closeSnackbar(key);
    }

    const render =  (message, {title, subject, details, path, key, icon} = {}) => {
        const dismiss = <Button color="inherit" size="small" onClick={handleDismiss(key)} text="Dismiss"/>;
        const content = (
            <Box display="flex" flexDirection="column" gap={0.5} marginTop={-1}>
                <Box display={title ? "flex" : 'none'} justifyContent="space-between" alignItems="center">
                    <Box display="flex" gap={0.5} alignItems="center">
                        <Icon size="1.4em">{icon}</Icon>
                        <Box lineHeight="2.4em">{title}</Box>
                    </Box>
                    {dismiss}
                </Box>
                <Box paddingLeft={0.5}>
                    <Box display={subject ? "flex" : 'none'} justifyContent="space-between" alignItems="center">
                        <div>{subject}</div>
                        {title ? null : dismiss}
                    </Box>
                    <Box display="flex" justifyContent="space-between" gap={3}>
                        <Box whiteSpace="pre">{message}</Box>
                        {subject ? null : dismiss}
                    </Box>
                </Box>
            </Box>
        );
        return content;
    }

    const show = (message, {title, subject, details, path, key, icon,
        variant, preventDuplicate, persist, autoHideDuration} = {}) => {
        const content = render(message, {
            title, subject, details, path, key, icon});
        enqueueSnackbar(content, {variant, preventDuplicate, persist, autoHideDuration});
    }

    context.success = (message, {title, subject, details, path, key} = {}) => {
        const variant = 'success';
        const icon = 'check';
        const preventDuplicate = true;
        const persist = false;
        show(message, {
            title, subject, details, path, key, icon,
            variant, preventDuplicate, persist
        });
    }

    context.info = (message, {title, subject, details, path, key} = {}) => {
        const variant = 'info';
        const icon = 'notifications';
        const preventDuplicate = true;
        const persist = false;
        show(message, {
            title, subject, details, path, key, icon,
            variant, preventDuplicate, persist
        });
    }
    context.warn = (message, {title, subject, details, path, key} = {}) => {
        const variant = 'warning';
        const icon = 'error_outline';
        const preventDuplicate = true;
        const persist = false;
        const autoHideDuration = 10000;
        show(message, {
            title, subject, details, path, key, icon,
            variant, preventDuplicate, persist, autoHideDuration
        });
    }
    context.alert = (message, {title, subject, details, path, key} = {}) => {
        const variant = 'error';
        const icon = 'cancel';
        const preventDuplicate = true;
        const persist = true;
        show(message, {
            title,subject, details, path,  key, icon,
            variant, preventDuplicate, persist
        });
    }

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}

NotifProvider.propTypes = {
    children: PropTypes.node.isRequired
};

const NotifConsumer = NotifContext.Consumer;

export default NotifContext;

export { NotifProvider, NotifConsumer };