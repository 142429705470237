import {useContext} from "react";
import AppFuseContext from "../component/AppFuse";

function useRouter() {
    const context = useContext(AppFuseContext);

    const addRoute = (name, path) => context.addRoute(name, path);

    const basename = context.basename;

    const resolve = (path) => context.resolve(path);

    const home = () => context.home();

    const main = () => context.main();

    const login = () => context.login();

    const register = () => context.register();

    const reload = () => context.reload();

    return {basename, resolve, addRoute, home, main, login, register, reload};
}

export default useRouter;