const disqualificationInboxes = [
    {
        "uuid":"1",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGA00150-C-0-00-001",
        "activity":"S10",
        "farmerNo":"CGA00150",
        "farmerName":"王森林",
        "mobilePhone":"0911234098",
        "disqualification": {
            "type": "crop",
            "reason": ["02", "04"],
        },
        "workflowHistory": [
            {
                "activity": "S10",
                "createdOn": "2021/9/10",
                "assigneeName": "王小華",
            },
            {
                "activity": "S20",
                "createdOn": "2021/9/10",
                "assigneeName": "張小花",
            }
        ],

        "type": "crop",
        "reason": ["02", "04"],
    },
    {
        "uuid":"2",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGB00090-C-0-00-001",
        "activity":"S20",
        "farmerNo":"CGB00090",
        "farmerName":"吳小花",
        "mobilePhone":"0919234098",
        "disqualification": {
            "type": "crop",
            "reason": ["01", "others"],
            "otherReason": "家庭因素"
        },
        "workflowHistory": [
            {
                "activity": "S10",
                "createdOn": "2021/9/10",
                "assigneeName": "王小華",
            },
            {
                "activity": "S20",
                "createdOn": "2021/9/10",
                "assigneeName": "張小花",
            }
        ],

        "type": "crop",
        "reason": ["01", "others"],
        "otherReason": "家庭因素"
    },
]

export default disqualificationInboxes;