import React, {useState, useEffect, Suspense, lazy} from 'react';
import i18n from "./lib/appfuse-react/core/i18n";
import env from "./lib/appfuse-react/core/env";
import Home from "./applet/Home";
import {Switch, Route, Redirect, useHistory, useRouteMatch, useLocation, Link} from "react-router-dom";
import {useDispatch, useSelector} from "./store";
import {
    isAuthenticated,
    selectApplets,
    selectToken,
    setApplets
} from "./reducer/iam/authReducer";
import {setTitle} from "./reducer/app/appReducer";
import Main from "./applet/Main";
import Registration from "./applet/Registration";
import Logon from "./applet/Logon";
import lang from "./lib/appfuse-react/core/lang";
import CircularProgress from "./lib/appfuse-react/component/CircularProgress";
import LinearProgress from "./lib/appfuse-react/component/LinearProgress";
import Box from "./lib/appfuse-react/component/Box";
import {Typography, useTheme} from "@material-ui/core";
import ResizeSensor from "./lib/appfuse-react/component/ResizeSensor";
import usePrompt from "./lib/appfuse-react/hook/usePrompt";
import authService from "./service/iam/authService";
import Paper from "./lib/appfuse-react/component/Paper";
import Space from "./lib/appfuse-react/component/Space";
import logger from "./lib/appfuse-react/core/logger";
import useRouter from "./lib/appfuse-react/hook/useRouter";

const log = logger.getLogger('AppRouter');

const AppRouter = () => {
    const [ready, setReady] = useState(false)
    const token = useSelector(selectToken);
    const authenticated = useSelector(isAuthenticated);
    const applets = useSelector(selectApplets);
    const dispatch = useDispatch();
    const settings = env.get('app') || {};
    const theme = useTheme();
    const router = useRouter();
    const backgroundImage = router.resolve(theme.brand.backgroundImage);
    const prompt = usePrompt();
    const history = useHistory();
    const responsive = env.responsive;
    const location = useLocation();
    const {path} = useRouteMatch();
    const [routes, setRoutes] = useState([]);

    // useEffect 可以選擇不回傳值, 或是回傳一個 cleanup 函數供 react 在 unmount 元件前呼叫, 讓元件可以釋放佔用的資源.
    // 所以傳給 useEffect 的 effect 函數不能宣告為 async. 因為宣告為 async 的函數的回傳值是 promise.
    // 要在 useEffect 使用 await 呼叫 async 函數的方法是:
    // - 在 effect 函數裡面宣告一個 async 的函數, 在函數裡面使用 await 呼叫想要使用的 async 函數,
    // - 然後在呼叫宣告的 async 函數.
    useEffect(() => {
        (async () => {
            dispatch(setTitle(settings.title));
            const {pathname, search} = location;
            const urlParams = new URLSearchParams(search);
            if(urlParams.has('token')) {
                const authorization = `Bearer ${urlParams.get('token')}`;
                urlParams.delete('token');
                try {
                    const queryString = urlParams.toString();
                    const url = `${pathname}${queryString.length>0 ? '?' : ''}${queryString}`;
                    history.push(url);
                    await authService.login(authorization);
                } catch(error) {
                    prompt.alert('Invalid URL.', {error})
                    history.push(path);
                }
            } else if(!authenticated && token) {
                try {
                    await authService.login(token);
                } catch(error) {
                    log.debug('Invalid persistent token.');
                }
            }
            if(authenticated) {
                const applets = await authService.fetchApplets();
                dispatch(setApplets(applets));
            }
            setReady(true);
        })();
    }, [authenticated]);

    useEffect(() => {
        const routes = applets.map(applet => {
            const { id, path } = applet
            const url = `/${path || id}`;
            const Component = lazy(()=> import(`./applet/${id}`));
            return (
                <ProtectedRoute key={id} path={url} component={Component} />
            );
        });
        setRoutes(routes);
    }, [applets]);

    const handleResize = event => {
        const {width, height} = event;
        if(width < responsive.width[0] || height < responsive.height[0]) {
            const message = i18n.translate('For the best browsing experience, we suggest you use ${width} x ${height} or higher screen resolution.', {
                width: responsive.width[1],
                height: responsive.height[1]
            });
            prompt.info(message);
        } else {
            // trigger render.
            setReady(true);
        }
    }

    const home = authenticated ? Main : Home;

    return (
        <Box height="100vh" width="100vw" overflow="hidden" backgroundImage={backgroundImage}>
            <LinearProgress value={ready} fullHeight fullWidth>
                <Suspense fallback={<CircularProgress />}>
                    <Switch>
                        <Route exact path="/" component={home} />
                        <Route path="/logon" component={Logon} />
                        { lang.isFalse(settings.registration) ? null : <Route path="/registration" component={Registration} />}
                        <ProtectedRoute path="/main" component={Main} />
                        {routes}
                        <Route component={NotFound} />
                    </Switch>
                </Suspense>
            </LinearProgress>
            <ResizeSensor onResize={handleResize} />
        </Box>
    );
}

const NotFound = () => {
    const theme = useTheme();
    return (
        <Box display="flex" justifyContent="center" alignItems="center" fullHeight fullWidth>
            <Paper backgroundColor={theme.palette.primary.main} color="white" paddingY={3} paddingX={6} backgroundOpacity={0.95} elevation={10}>
                <Typography variant="h2" align="center">Content not found</Typography>
                <Typography variant="h3" align="center">{i18n.translate("or")}</Typography>
                <Typography variant="h2" align="center">You are not authorized</Typography>
                <Space spacing={2} vertical />
                <Box display="flex" justifyContent="space-around" color="white" gap={2}>
                    <Link to="/"><Typography variant="h3">{i18n.translate("Go to Home")}</Typography></Link>
                    <Typography variant="h3" align="center">{i18n.translate("or")}</Typography>
                    <Link to="/logon"><Typography variant="h3">{i18n.translate("Click to Login")}</Typography></Link>
                </Box>
            </Paper>
        </Box>
    );
}

const ProtectedRoute = (props) => {
    const authenticated = useSelector(isAuthenticated);
    return (
        <Route {...props}>
            {authenticated ? props.children : <Redirect to={{pathname: '/logon', state: { from : props.path}}} />}
        </Route>
    );
};

export default AppRouter;
