import React from 'react';
import MuiAccordion from "@material-ui/core/Accordion";
import {makeStyles} from "@material-ui/core";
import palette from "../../core/palette";

const AccordionContext = React.createContext({
    color: palette.Grey
});

AccordionContext.displayName = "AccordionContext";

const useStyles = makeStyles(theme => ({
    root: {
        border: '1px solid',
        borderColor: props => props.borderColor,
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {}
}));

function Accordion(props) {
    const { color = palette.Grey } = props;
    const borderColor = color?.main || color;
    const classes = useStyles({borderColor});
    const theme = { color };
    return (
        <AccordionContext.Provider value={theme}>
            <MuiAccordion {...props} classes={classes} />
        </AccordionContext.Provider>
    )
}

export default Accordion;
export { AccordionContext };