import {makeStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import useRouter from "../../hook/useRouter";
import lang from "../../core/lang";
import imageIO from "../../core/imageIO";
import downloader from "../../core/downloader";
import clsx from "clsx";
import logger from "../../core/logger";
import Box from "../Box";
import {Fade, Grow, Slide, Zoom} from "@material-ui/core";
import i18n from "../../core/i18n";

const LOGGER = logger.getLogger('Image');

const useStyles = makeStyles(theme => ({
    img: {
        width: props => props.imgWidth,
        height: props => props.imgHeight,
        objectFit: props => props.objectFit
    }
}));

/**
 * 使用 named function 取代 anonymous arrow function 讓除錯訊息除了顯示 ForwardRef 外，也能夠顯示 named function 使用的名稱來辨識是由本元件發生的錯誤。
 */
const Image = React.forwardRef(function Image(props, ref) {
    // scaling could be one of the following:
    // - undefined: has not decided how to scale yet.
    // - height: scale the image with fixed height & variable width.
    // - width: scale the image with fixed width & variable height.
    // - original: keep the image at the original size.
    // - none: do not scale.
    const [scaling, setScaling] = useState();
    const [src, setSrc] = useState();
    const [appear, setAppear] = useState(false);

    const {fullWidth, fullHeight, objectFit, scrollable, borderRadius, transition, title} = props;
    const width = fullWidth ? '100%' : props.width;
    const height = fullHeight ? '100%' : props.height;
    const cursor = props.onClick ? 'pointer' : '';
    const router = useRouter();
    const params = {};

    if(objectFit!=='none') {
        params.width = window.innerWidth;
        params.height = window.innerHeight;
    }

    useEffect(()=> {
        (async () => {
            let source;
            if(lang.isString(props.value)) {
                if(imageIO.isImageDataURL(props.value)) {
                    source = props.value;
                } else {
                    const url = router.resolve(props.value);
                    const file = await downloader.fetch(url, params);
                    if(!imageIO.isImage(file)) {
                        LOGGER.debug(`The value is not an image(${file.type}): ${url}`)
                    }
                    source = await imageIO.readAsImageDataURL(file);
                }
            } else if(imageIO.isImage(props.value)) {
                // 限制圖片下載指定的寬度與高度
                source = await imageIO.readAsImageDataURL(props.value, params);
            }
            if(objectFit==='fill' || objectFit==='none' || objectFit==='height' || objectFit==='width') {
                setScaling(objectFit)
            } else if(!(width && height)) {
                setScaling(height ? 'height' : 'width');
            } else {
                setScaling(undefined);
            }
            setSrc(source);
            setAppear(false);
        })();
    }, [props.value]);

    let imgWidth, imgHeight, justifyContent = 'flex-start', alignItems = 'flex-start';
    if(scaling==='height') {
        // scale the image with fixed height & variable width.
        imgWidth = 'auto';
        imgHeight = '100%';
        if(objectFit==='contain') {
            justifyContent = 'center';
        }
    } else if(scaling==='width') {
        // scale the image with fixed width & variable height
        imgWidth = '100%'
        imgHeight = 'auto';
        if(objectFit==='contain') {
            alignItems = 'center';
        }
    } else if(scaling==='original') {
        imgWidth = 'auto';
        imgHeight = 'auto';
        justifyContent = 'center';
        alignItems = 'center';
    } else if(scaling==='fill') {
        imgWidth = '100%';
        imgHeight = '100%';
    } else if(scaling==='none') {
        imgWidth = 'auto';
        imgHeight = 'auto';
    } else {
        imgWidth = 'auto';
        imgHeight = 'auto';
    }

    const classes = useStyles({objectFit, imgWidth, imgHeight});

    const root = useRef(null);
    const img = useRef(null);
    useImperativeHandle(ref, () => root.current);

    const handleLoad = () => resize();

    // determine how to scale.
    const resize = () => {
        lang.defer(() => setAppear(true));
        if(scaling!==undefined) {
            return;
        }

        const { clientWidth, clientHeight } = root.current;
        const { scrollWidth, scrollHeight } = img.current;

        const ratioW = scrollWidth / clientWidth;
        const ratioH = scrollHeight / clientHeight;

        if(objectFit==='auto') {
            // image width overflow or image height overflow
            if(ratioW > 1 || ratioH > 1) {
                if(ratioW > ratioH) {
                    // image too wide for the container
                    // fixed height
                    setScaling('height');
                } else {
                    // image too height for the container
                    // fixed width
                    setScaling('width');
                }
            } else {
                setScaling('original');
            }
        } else if(objectFit==='cover') {
            if(ratioW > ratioH) {
                // image too wide for the container
                // fixed height
                setScaling('height');
            } else {
                // image too height for the container
                // fixed width
                setScaling('width');
            }
        } else if(objectFit==='contain') {
            if(ratioW > ratioH) {
                // image too wide for the container
                // fixed width
                setScaling('width');
            } else {
                // image too height for the container
                // fixed height
                setScaling('height');
            }
        }
    }

    const display = props.value ? 'flex' : 'none';
    const imageElement = <img alt={i18n.translate(title)} title={i18n.translate(title)} ref={img}
                              aria-label={i18n.translate(props["aria-label"] || title)} className={classes.img} src={src} onLoad={handleLoad} />;
    let transitionType, transitionProps = {in: appear, timeout: (appear ? 1000 : 0)};
    if(transition === 'fade') {
        transitionType = Fade;
    } else if(transition === 'grow') {
        transitionType = Grow;
    } else if(transition === 'zoom') {
        transitionType = Zoom;
    } else if(transition === 'slide') {
        transitionType = Slide;
        transitionProps.direction = props.direction || 'down';
    }
    const element = transition ? React.createElement(transitionType,
        transitionProps, imageElement) : imageElement;

    let overflowX, overflowY;

    if(scrollable) {
        if(scaling === 'height') {
            overflowX = 'auto';
            overflowY = 'hidden';
        } else if(scaling === 'width') {
            overflowX = 'hidden';
            overflowY = 'auto';
        } else {
            overflowX = "auto";
            overflowY = "auto";
        }
    } else {
        overflowX = 'hidden';
        overflowY = 'hidden';
    }

    return (
        <Box ref={root} className={clsx('appfuse-image', props.className)}
             display={display} justifyContent={justifyContent} alignItems={alignItems}
             height={height} width={width} cursor={cursor} overflowX={overflowX} overflowY={overflowY}
             borderRadius={borderRadius} title={title}
             // Material UI 的 Tooltip 需要 forward 這 onMouseOver & onMouseLeave
             onMouseOver={props.onMouseOver}
             onMouseLeave={props.onMouseLeave}
             onClick={props.onClick}>
            {element}
        </Box>
    )
});


Image.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(File),
        PropTypes.object
    ]),
    fullWidth: PropTypes.bool,
    fullHeight: PropTypes.bool,
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    objectFit: PropTypes.oneOf(['cover', 'contain', 'fill', 'none', 'auto', 'width', 'height']),
    scrollable: PropTypes.bool,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func,
    borderRadius: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    transition: PropTypes.oneOf(['fade', 'grow', 'zoom', 'slide']),
    direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
    title: PropTypes.string,
    "aria-label": PropTypes.string
}

Image.defaultProps = {
    objectFit: 'auto',
    scrollable: true
}

export default Image;