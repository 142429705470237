import {useContext} from "react";
import PromptContext from "../component/Prompt";

function usePrompt() {
    const context = useContext(PromptContext);

    const success = async (message, {title, icon, onAction, actions, dictionary, vertical = 'top', horizontal = 'center'} = {}) => {
        return await context.success(message, {title, icon, onAction, actions, dictionary, vertical, horizontal});
    };

    const info = async (message, {title, icon, onAction, actions, dictionary, vertical = 'top', horizontal = 'center'} = {}) => {
        return await context.info(message, {title, icon, onAction, actions, dictionary, vertical, horizontal});
    };

    const warn = async (message, {title, icon, error, onAction, actions, dictionary, vertical = 'center', horizontal = 'center'} = {}) => {
        return await context.warn(message, {title, icon, error, onAction, actions, dictionary, vertical, horizontal});
    };

    const alert = async (message, {title, icon = null, error, onAction, actions, dictionary, vertical = 'center', horizontal = 'center'} = {}) => {
        return await context.alert(message, {title, icon, error, onAction, actions, dictionary, vertical, horizontal});
    };

    const confirm = async (message, {title, icon, onAction, actions, dictionary, vertical = 'center', horizontal = 'center'} = {}) => {
        return await context.confirm(message, {title, icon, onAction, actions, dictionary, vertical, horizontal});
    };

    return {success, info, warn, alert, confirm};
}

export default usePrompt;