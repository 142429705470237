import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import * as PropTypes from 'prop-types';
import Accordion from "../../lib/appfuse-react/component/Accordion";
import {Grid} from "@material-ui/core";
import AccordionSummary from "../../lib/appfuse-react/component/Accordion/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "../../lib/appfuse-react/component/Accordion/AccordionDetails";
import i18n from "../../lib/appfuse-react/core/i18n";

const useStyles = makeStyles((theme) => ({
    root: {
    }
}));

function LaunchFolder(props) {
    const classes = useStyles();
    const {title, color, children} = props;

    return (
        <Accordion square color={color}>
            <AccordionSummary color={color}>
                <Typography>{i18n.translate(title)}</Typography>
            </AccordionSummary>
            <AccordionDetails color={color}>
                <Grid container className={classes.root} spacing={2}>
                    {children.map((child, index) => <Grid item xs={6} key={index}>{child}</Grid>)}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}

LaunchFolder.propTypes = {
    title: PropTypes.string.isRequired,
    color: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
}

export default LaunchFolder;