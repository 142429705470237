const config = {
    "app": {
        title: 'Green Conservation Data Office',
        search: false,
        registration: false
    },
    "ajax": {
        // "baseURL": "https://uatapp.leandev.io/gcdmp-server/"
        // "baseURL": "http://localhost:4001/"
        "baseURL": "/gcdmp-server/"
    },
    "i18n": {
        "language": "zh-TW",
        "debug": false
    },
    "currency": {
        "pattern": '$0,0.00'
    },
    "numeral": {
        "pattern": {
            "integer": '0,0',
            "float": '0,0.00'
        }
    },
    "time": {
        "pattern": {
            "datetime": "YYYY/MM/DD HH:mm:ss",
            "date": "YYYY/MM/DD",
            "time": "HH:mm:ss"
        }
    },
    responsive: {
        minWidth: 960,
        width: [1100, 1366],
        height: [700, 768]
    },
    theme: {
        overrides: {
            MuiTableRow: {
                root: {
                    "&$selected": {
                        backgroundColor: 'rgba(249, 169, 0, 0.2)',
                    },
                    "&$hover": {
                        "&:hover": {
                            backgroundColor: 'rgba(249, 169, 0, 0.2)',
                        }
                    }
                }
            },
        },
        brand: {
            logo: '/brand/logo.svg',
            banner: '/brand/banner.svg',
            backgroundImage: '/brand/background.jpg'
        },
        palette: {
            text: {
                primary: '#1a5728',
                secondary: '#546e7a',
                disabled: 'rgba(0, 0, 0, 0.54)'
            },
            primary: {
                main: '#009A44',
                light: '#51cc71',
                dark: '#006a18',
                contrastText: '#ffffff'
            },
            secondary: {
                main: '#F1B434',
                light: '#ffff7c',
                dark: '#c8a804',
                contrastText: '#ffffff'
            },
            success: {
                main: '#00695c',
                light: '#439889',
                dark: '#003d33',
                contrastText: '#ffffff'
            },
            info: {
                main: '#0097a7',
                light: '#56c8d8',
                dark: '#006978',
                contrastText: '#ffffff'
            },
            error: {
                main: '#C23030',
                light: '#fb645a',
                dark: '#8a0009',
                contrastText: '#ffffff'
            },
            warning: {
                main: '#e68523',
                light: '#ffb554',
                dark: '#ae5700',
                contrastText: '#ffffff'
            },
            prompt: {
                main: '#106BA3',
                light: '#5599d5',
                dark: '#004174',
                contrastText: '#ffffff'
            },
        },
        typography: {
            // In Chinese and Japanese the characters are usually larger,
            // so a smaller fontsize may be appropriate.
            htmlFontSize: 16,
            fontSize: 14,
            fontFamily:  '-apple-system, BlinkMacSystemFont, Roboto, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, ' +
                'Helvetica Neue, sans-serif, PingFang TC, Noto Sans TC, Microsoft JhengHei',
            h1: {
                fontWeight: 400,
                fontSize: "3rem",
                lineHeight: 1.167,
                letterSpacing: "0em"
            },
            h2: {
                fontWeight: 400,
                fontSize: '2.5rem',
                lineHeight: 1.201,
                letterSpacing: "0em"
            },
            h3: {
                fontWeight: 400,
                fontSize: "1.75rem",
                lineHeight: 1.235,
                letterSpacing: "0.00735em"
            },
            h4: {
                fontWeight: 400,
                fontSize: "1.5rem",
                lineHeight: 1.334,
                letterSpacing: "0.0075em"
            },
            h5: {
                fontSize: '1.25rem',
                fontWeight: 500,
                lineHeight: 1.6,
                letterSpacing: "0.0075em"
            },
            h6: {
                fontWeight: 500,
                fontSize: "1.1rem",
                lineHeight: 1.75,
                letterSpacing: "0.00938em"
            },
            button: {
                fontSize: '1rem',
                fontFamily:  '-apple-system, BlinkMacSystemFont, Roboto, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, ' +
                    'Helvetica Neue, sans-serif, PingFang TC, Noto Sans TC, Microsoft JhengHei',
            },
            caption: {
                fontFamily:  '-apple-system, BlinkMacSystemFont, Roboto, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, ' +
                    'Helvetica Neue, sans-serif, PingFang TC, Noto Sans TC, Microsoft JhengHei',
            },
            overline: {
                fontFamily:  '-apple-system, BlinkMacSystemFont, Roboto, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, ' +
                    'Helvetica Neue, sans-serif, PingFang TC, Noto Sans TC, Microsoft JhengHei',
            }
        }
    }
}

export default config;