import {createSlice} from "@reduxjs/toolkit";
import {json} from "../../lib/appfuse-react/core/json";

const name = 'certifiedFarmers';

const initialState = {
    pageNumber: 0,
    pageSize: 10,
    criteria: {},
};

const slice = createSlice({
    name,
    initialState,
    reducers: {
        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setCriteria(state, action) {
            state.criteria = json.toJSON(action.payload);
        },
    }
});

export const { setPageNumber, setPageSize, setCriteria } = slice.actions;

export const selectPageNumber = state => state[name].pageNumber;
export const selectPageSize = state => state[name].pageSize;
export const selectCriteria = state => json.fromJSON(state[name].criteria);

const certifiedFarmersReducer = slice.reducer;
export default certifiedFarmersReducer;