import i18n from "./i18n";
import ajax from "./ajax";
import time from "./time";
import currency from "./currency";
import numeral from "./numeral";

class Env {
    props = {};

    async init(options) {
        this.props = {...options};
        ajax.init(this.get('ajax'));
        numeral.init(this.get('numeral'));
        currency.init(this.get('currency'));
        time.init(this.get('time'));
        await i18n.init(this.get('i18n'));
    }

    get(name) {
        return this.props[name];
    }

    set(name, value) {
        this.props[name] = value;
    }

    get responsive() {
        return this.get('responsive') || {};
    }

    get basename() {
        return this.get('basename');
    }

    set basename(basename) {
        this.set('basename', basename);
    }

    get app() {
        return this.get('app') || {};
    }

    get appName() {
        return this.app['name'];
    }

    set appName(appName) {
        this.app['name'] = appName;
    }

    get appVersion() {
        return this.app['version'] || '1.0.0';
    }

    set appVersion(appVersion) {
        this.app['version'] = appVersion;
    }

    get appStage() {
        return this.app['stage'] || 'PROD';
    }

    set appStage(appStage) {
        this.app['stage'] = appStage;
    }
}

const env = new Env();

export default env;