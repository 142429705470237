const codes = [
    {"type":"category", "code":"cropActivity", "text":"作物申請活動"},
    {"type":"cropActivity", "code":"survey_S10", "text":"申請評估"},
    {"type":"cropActivity", "code":"survey_S20", "text":"輔導場勘"},
    {
        "type": "category",
        "code": "Boxed Flower",
        "text": "盒裝花禮",
        "icon": "box",
        "priority": 10,
        "locked": true
    },
    {
        "type": "category",
        "code": "Flower Bouquet",
        "text": "花束",
        "icon": "snowflake",
        "priority": 5,
        "deprecated": true
    },
    {
        "type": "category",
        "code": "Plant",
        "text": "盆花",
        "icon": "mountain",
        "disabled": true
    },
    {
        "type": "keyword",
        "code": "Birthday",
        "text": "生日",
        "locked": true,
        "source": "system"
    },
    {
        "type": "keyword",
        "code": "Valentine",
        "text": "情人節",
        "deprecated": true
    },
    {
        "type": "keyword",
        "code": "Mother's Day",
        "text": "母親節",
        "disabled": true
    },
    {
        "type": "keyword",
        "code": "Father's Day",
        "text": "父親節"
    },
    {
        "type": "keyword",
        "code": "Christmas",
        "text": "聖誕節"
    },
    {
        "type": "keyword",
        "code": "Anniversary",
        "text": "週年紀念日"
    }
];
export default codes;