import registrationInboxes from "./registrationInboxes";
import cropInboxes from "./cropInboxes";
import markInboxes from "./markInboxes";
import disqualificationInboxes from "./disqualificationInboxes";

const inboxService = [
    {
        matcher: 'inboxes/registrations',
        reply: config => {
            return [200, registrationInboxes];
        }
    },
    {
        matcher: /inboxes\/registrations\/(.*)/,
        reply: config => {
            const matches = /inboxes\/registrations\/(.*)/.exec(config.url);
            const uuid = matches[1];
            const registration = registrationInboxes.find(registration => registration.uuid === uuid);
            return [200, registration];
        }
    },
    {
        matcher: 'inboxes/crops',
        reply: config => {
            return [200, cropInboxes];
        }
    },
    {
        matcher: /inboxes\/crops\/(.*)/,
        reply: config => {
            const uuid = /inboxes\/crops\/(.*)/.exec(config.url)[1];
            const cropInbox = cropInboxes.find(cropInbox => cropInbox.uuid === uuid);
            return [200, cropInbox];
        }
    },
    {
        matcher: 'inboxes/marks',
        reply: config => {
            return [200, markInboxes];
        }
    },
    {
        matcher: /inboxes\/marks\/(.*)/,
        reply: config => {
            const uuid = /inboxes\/marks\/(.*)/.exec(config.url)[1];
            const mark = markInboxes.find(mark => mark.uuid === uuid);
            return [200, mark];
        }
    },
    {
        matcher: 'inboxes/disqualifications',
        reply: config => {
            return [200, disqualificationInboxes];
        }
    },
    {
        matcher: /inboxes\/disqualifications\/(.*)/,
        reply: config => {
            const uuid = /inboxes\/disqualifications\/(.*)/.exec(config.url)[1];
            const mark = disqualificationInboxes.find(mark => mark.uuid === uuid);
            return [200, mark];
        }
    }
]

export default inboxService;