import {useDispatch, useSelector} from "../../store";
import React, {useEffect, useState} from "react";
import {selectTitle} from "../../reducer/app/appReducer";
import lang from "../../lib/appfuse-react/core/lang";
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Typography,
    useTheme
} from "@material-ui/core";
import Form from "../../lib/appfuse-react/component/Form";
import Button from "../../lib/appfuse-react/component/Button";
import {ExitToApp, Visibility, VisibilityOff} from "@material-ui/icons";
import Box from "../../lib/appfuse-react/component/Box";
import env from "../../lib/appfuse-react/core/env";
import i18n from "../../lib/appfuse-react/core/i18n";
import IconButton from "@material-ui/core/IconButton";
import downloader from "../../lib/appfuse-react/core/downloader";
import Image from "../../lib/appfuse-react/component/Image";

function LogonForm({value, onChange, onLogin, onForgotPassword, onRegister}) {
    const [showPassword, setShowPassword] = useState(false);
    const [captchaImage, setCaptchaImage] = useState(null);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    useEffect(() => {
        (async () => {
            if(captchaImage == null) {
                const file = await downloader.download('accounts/captcha');
                setCaptchaImage(file);
            }
        })()
    }, [captchaImage])

    const settings = env.get('app');

    return (
        <Box>
            <Box paddingBottom={2}>
                <Typography align="center" variant="h5">{i18n.translate('Sign in')}</Typography>
            </Box>
            <Form onChange={onChange} value={value} onSubmit={onLogin}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField name="username" label="Username" variant="outlined"
                                   fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="password"
                            label="Password"
                            variant="outlined"
                            fullWidth
                            type={showPassword? "text" : "password"}
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPassword}>
                                            { showPassword? <Visibility/> : <VisibilityOff/> }
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Box fullWidth display='flex' alignItems='center' gap={1}>
                            <TextField name='verificationCode' variant='outlined' label='Please enter verification code' fullWidth/>
                            <Image value={captchaImage} height='55px' width='20rem' objectFit='fill' />
                            <Button variant='icon' icon='refresh' color='primary' onClick={() => setCaptchaImage(null)}/>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <FormGroup>
                            <FormControlLabel name="persistentSession" control={<Checkbox /> }
                                              label="Keep me logged in"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <Box onClick={onForgotPassword} marginTop={-1}>
                            <Typography variant="body2" align="right"
                                        gutterBottom={false}
                            >{i18n.translate('Forgot your password?')}</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box display="flex" justifyContent="space-between" paddingTop={1}>
                    <Box visibility={lang.isFalse(settings.registration) ? 'hidden' : 'visible'}>
                        <Button variant={"text"} color={"secondary"}
                                onClick={onRegister} text="Register" />
                    </Box>
                    <Button variant="contained" color="primary" startIcon={<ExitToApp />}
                            onClick={onLogin} text="Login" delay={2000} />
                </Box>
            </Form>
        </Box>
    );
}

export default LogonForm;