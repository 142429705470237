import { createSlice } from '@reduxjs/toolkit';
import random from "../../lib/appfuse-react/core/random";
import lang from "../../lib/appfuse-react/core/lang";

const name = 'app';

const initialState = {
    title: 'Flora Office',
    baseURL: null,
    category: [],
    errors: [],
    messages: [],
    progresses: []
};

const slice = createSlice({
    name,
    initialState,
    reducers: {
        setTitle(state, action) {
            state.title = action.payload;
        },
        setBaseURL(state, action) {
            state.baseURL = action.payload;
        },
        setMessages(state, action) {
            state.messages = action.payload;
        },
        postMessage(state, action) {
            const message = lang.isString(action.payload) ? { text: action.payload } : action.payload;
            message.id || (message.id = random.unique());
            state.messages = [...state.messages, message];
        },
        setErrors(state, action) {
            state.errors = action.payload;
        },
        // postError(state, action) {
        //     const payload = action.payload;
        //     let error;
        //     if(action.payload instanceof Error) {
        //         error = { id: random.unique(), text: payload.message, stack: payload.stack };
        //     } else if(lang.isString(payload)) {
        //         error = { text: payload };
        //     } else {
        //         error = payload;
        //     }
        //     error.id || (error.id = random.unique());
        //     state.errors = [...state.errors, error];
        // },
        setProgresses(state, action) {
            state.progresses = action.payload;
        },
        postProgress(state, action) {
            const progress = lang.isNumber(action.payload) ? { value: action.payload } : action.payload;
            progress.id || (progress.id = random.unique());
            state.progresses = [...state.progresses, progress];
        },
    }
});

export const { setTitle, setBaseURL, postMessage, postProgress } = slice.actions;

export const postError = (payload) => (dispatch, getState) => {
    const state = getState()[name];
    let error;
    if(payload instanceof Error) {
        error = { id: random.unique(), text: payload.message, stack: payload.stack };
    } else if(lang.isString(payload)) {
        error = { text: payload };
    } else {
        error = payload;
    }
    error.id || (error.id = random.unique());
    dispatch(slice.actions.setErrors([...state.errors, error]));
}

export const { reducer } = slice;

export const selectTitle = (state) => state[name].title;
export const selectBaseURL = (state) => state[name].baseURL;
export const selectMessages = (state) => state[name].messages;
export const selectErrors = (state) => state[name].errors;
export const selectProgresses = (state) => state[name].progresses;

const appReducer = reducer;
export default appReducer;
