import {useMediaQuery, useTheme} from "@material-ui/core";
import responsive from "../core/responsive";

function useResponsive() {
    const theme = useTheme();
    const breakpoints = {...theme.breakpoints.values};

    responsive.setBreakpoints(breakpoints);

    const getBreakpoint = () => responsive.getBreakpoint();

    const getResponsiveValue = (value) => responsive.getResponsiveValue(value);

    const smallScreen = useMediaQuery(`(max-width:${breakpoints.sm}px)`);
    const mediumScreen = useMediaQuery(`(min-width:${breakpoints.sm+1}) and (max-width:${breakpoints.md}px)`);
    const largeScreen = useMediaQuery(`(min-width:${breakpoints.md+1}px)`);

    const isSmallScreen = () => smallScreen;
    const isMediumScreen = () => mediumScreen;
    const isLargeScreen = () => largeScreen;


    return {
        getResponsiveValue, getBreakpoint,
        isSmallScreen, isMediumScreen, isLargeScreen
    };
}

export default useResponsive;