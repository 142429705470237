import {Box as MuiBox, CircularProgress as MuiCircularProgress, useTheme} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import lang from "../../core/lang";
import PropTypes from 'prop-types';
import React from 'react';
import clsx from "clsx";
import palette from "../../core/palette";
import responsive from "../../core/responsive";

const useStyles = makeStyles({
    root: {
        gap: props => props.gap,
        cursor: props => props.cursor,
        opacity: props => props.opacity,
        color: props => props.color,
        backgroundColor: props => props.backgroundColor || 'transparent',
        backgroundImage: props => props.backgroundImage && `url(${props.backgroundImage})`,
        backgroundSize: props => props.backgroundSize,
        backgroundRepeat: props => props.backgroundRepeat,
        pointerEvents: props => props.pointerEvents,
        overflowX: props => props.overflowX,
        overflowY: props => props.overflowY,
        position: props => props.position
    },
});

/**
 * 使用 named function 取代 anonymous arrow function 讓除錯訊息除了顯示 ForwardRef 外，也能夠顯示 named function 使用的名稱來辨識是由本元件發生的錯誤。
 */
const Box = React.forwardRef(function Box(props, ref) {
    const {disabled, backgroundImage, backgroundSize, backgroundRepeat, backgroundOpacity, overflowX, overflowY, ready} = props;
    const style = {...props.style};
    const theme = useTheme();
    let backgroundColor = theme.palette.background[props.backgroundColor] || theme.palette[props.backgroundColor]?.main || props.backgroundColor;
    backgroundColor && backgroundOpacity && (backgroundColor = palette.color(backgroundColor, backgroundOpacity));
    const calcSpacing = (spacing) => lang.isNumber(spacing) ? `${theme.spacing(spacing)}px` : spacing;
    const gap = calcSpacing(props.gap);
    const cursor = lang.isNullOrUndefined(props.cursor) ? (props.onClick ? 'pointer' : 'initial') : props.cursor;
    const pointerEvents = disabled ? 'none' : '';
    const opacity = lang.isNullOrUndefined(props.opacity) ? (props.disabled ? 0.7 : 1) : props.opacity;
    const calcColor = (theme, color) => {
        const tokens = color.split('.');
        let themeColor = theme.palette[tokens[0]];
        for(let i = 1; i<tokens.length; i++) {
            if(themeColor[tokens[i]]) {
                themeColor = themeColor[tokens[i]];
            } else {
                break;
            }
        }
        let effectiveColor = color;
        if(themeColor) {
            effectiveColor = lang.isObject(themeColor) ? themeColor.main : themeColor;
        }
        return effectiveColor;
    };
    const color = props.color ? calcColor(theme, props.color) : undefined;

    const options = lang.omit(props,'gap', 'cursor', 'fullWidth', 'fullHeight', 'opacity',
        'backgroundColor', 'backgroundImage', 'backgroundSize', 'backgroundRepeat', 'backgroundOpacity',
        'overflowX', 'overflowY', 'ready', 'position', 'color');

    const calcBorderRadius = (radius) => lang.isNumber(radius) ? `${theme.shape.borderRadius * radius}px` : radius;
    const borderRadius = calcBorderRadius(props.borderRadius)
    borderRadius && (options.borderRadius = borderRadius);
    props.fullWidth && (options.width = '100%');
    props.fullHeight && (options.height = '100%');

    options.top = responsive.getResponsiveValue(props.top);
    options.right = responsive.getResponsiveValue(props.right);
    options.bottom = responsive.getResponsiveValue(props.bottom);
    options.left = responsive.getResponsiveValue(props.left);

    let progress;
    if(lang.isNotNullOrUndefined(ready)) {
        if(lang.isFalse(ready) || (lang.isNumber(ready) && (ready < 100))) {
            const value = lang.isNumber(ready) ? ready : undefined;
            progress = <Progress value={value} />;
        }
    }

    const position = progress ? 'relative' : (props.position || 'unset');

    const classes = useStyles({gap, cursor, pointerEvents, opacity, color, backgroundColor, backgroundImage, backgroundSize, backgroundRepeat, disabled,
        overflowX, overflowY, position});

    return (
        <MuiBox ref={ref} {...options} className={clsx(classes.root, props.className, 'appfuse-box')} style={style}>
            {props.children}
            {progress}
        </MuiBox>
    )
});

const useProgressStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
    }
});

function Progress(props) {
    const { value } = props;
    const classes = useProgressStyles();
    return (
        <div className={clsx('appfuse-box-progress', classes.root)}>
            <MuiCircularProgress value={value} />
        </div>
    )
}

Box.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    // Display
    display: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    displayPrint: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    overflow: PropTypes.string,
    overflowX: PropTypes.string,
    overflowY: PropTypes.string,
    textOverflow: PropTypes.string,
    visibility: PropTypes.string,
    cursor: PropTypes.string,

    // Shadows
    boxShadow: PropTypes.number,

    // Flexbox
    flexDirection: PropTypes.string,
    flexWrap: PropTypes.string,
    justifyContent: PropTypes.string,
    alignItems: PropTypes.string,
    alignContent: PropTypes.string,
    gap: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    order: PropTypes.number,
    flexGrow: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    flexShrink: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    flexBasis: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    alignSelf: PropTypes.string,

    // Spacing
    padding: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    paddingX: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    paddingY: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    paddingTop: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    paddingRight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    paddingBottom: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    paddingLeft: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    margin: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    marginX: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    marginY: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    marginTop: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    marginRight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    marginBottom: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    marginLeft: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),

    // Sizing
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    maxWidth: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    minWidth: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    maxHeight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    minHeight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    boxSizing: PropTypes.string,
    fullWidth: PropTypes.bool,
    fullHeight: PropTypes.bool,

    // Typography
    fontFamily: PropTypes.string,
    fontSize: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    fontStyle: PropTypes.string,
    fontWeight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    letterSpacing: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    lineHeight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    whiteSpace: PropTypes.string,

    // Palette
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    backgroundImage: PropTypes.string,
    backgroundSize: PropTypes.string,
    backgroundRepeat: PropTypes.string,
    backgroundOpacity: PropTypes.number,
    opacity: PropTypes.number,

    // Border
    border: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    borderTop: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    borderLeft: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    borderRight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    borderBottom: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    borderRadius: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    borderColor: PropTypes.string,

    // Position
    position: PropTypes.string,
    zIndex: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    top: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    right: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    bottom: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    left: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),

    // Event
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyPress: PropTypes.func,
    onBlur: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func,

    tabIndex: PropTypes.number,

    ready: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool
    ]),
    "role": PropTypes.string,
    "aria-label": PropTypes.string,
    "aria-hidden": PropTypes.string,
    "aria-labelledby": PropTypes.string,
    "aria-owns": PropTypes.string,
}

Box.defaultProps = {
    backgroundSize: 'cover'
}
export default Box;