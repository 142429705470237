const contentTypes = [
    {
        id: '生日花禮',
        text: '生日花禮',
        category: '主題花禮'
    }, {
        id: '榮升喬遷',
        text: '榮升喬遷',
        category: '主題花禮'
    }, {
        id: '彌月花禮',
        text: '彌月花禮',
        category: '主題花禮'
    }, {
        id: '告別花禮',
        text: '告別花禮',
        category: '主題花禮'
    }, {
        id: '開幕誌慶',
        text: '開幕誌慶',
        category: '主題花禮'
    }, {
        id: '綠化設計',
        text: '綠化設計'
    }, {
        id: '包月週花',
        text: '包月週花'
    }, {
        id: '活動會場',
        text: '活動會場'
    }
];

export default contentTypes;