import React from 'react';
import MuiIcon from '@material-ui/core/Icon';
import SvgIcon from '@material-ui/core/Icon';
import lang from "../../core/lang";
import {useTheme} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import PropTypes from 'prop-types';
import UserEvent from "../../core/UserEvent";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const NAMECOLORS = ['primary', 'secondary', 'action', 'error', 'disabled'];

const NAMESIZE = {
    'large': '2rem',
    'medium': '1.6rem',
    'small': '1.2rem'
};

const useStyles = makeStyles(theme => ({
    root: {},
    clickable: {
        cursor: 'pointer'
    }
}));

const Icon = React.forwardRef(function Icon(props, ref) {
    const {size = 'medium', color = 'inherit', badge, children, value} = props;
    const classes = useStyles();
    const theme = useTheme();

    const count = React.Children.count(children);
    // if(count!==1) {
    //     throw new Error('Icon can have only one child node.');
    // }

    const handleClick = event => {
        props.onClick && props.onClick(new UserEvent({value}, event))
    }

    const child = lang.first(React.Children.toArray(children));

    const elementType = lang.isString(child) ? MuiIcon : SvgIcon;
    const style = {};
    const options = {style};

    style.fill = 'currentColor';
    if(NAMECOLORS.includes(color)) {
        options.color = color;
    } else {
        options.color = 'inherit';
        style.color = color;
    }
    if(lang.has(NAMESIZE, size)) {
        style.fontSize = NAMESIZE[size];
    } else {
        style.fontSize = size;
    }

    options.className = clsx(props.className, props.onClick && classes.clickable);
    options.onClick = handleClick;

    options.ref = ref;
    // Material UI 的 Tooltip 需要 forward 這 onMouseOver & onMouseLeave
    options.onMouseOver = props.onMouseOver;
    options.onMouseLeave = props.onMouseLeave;

    const icon = React.createElement(elementType, options, child);

    const element = lang.isNotNullOrUndefined(badge) ? <Badge badgeContent={badge} color="error">{icon}</Badge> : icon;
    return element;
});

Icon.propTypes = {
    size: PropTypes.oneOfType([
        PropTypes.oneOf(['small', 'large', 'medium']),
        PropTypes.string
    ]),
    color: PropTypes.oneOfType([
        PropTypes.oneOf(['primary', 'secondary', 'action', 'error', 'disabled']),
        PropTypes.string
    ]),
    badge: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func,
    value: PropTypes.any
}

export default Icon;