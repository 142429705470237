import formatter from 'numeral';
import lang from "./lang";

class Numeral {
    pattern = undefined;

    init(options = {}) {
        const {pattern} = options
        this.pattern = pattern;
    }
    format(value, pattern) {
        if(lang.isNullOrUndefined(value)) return '';

        let text;
        if(pattern) {
            if(pattern === 'compact') {
                const formatter = Intl.NumberFormat('en', { notation: 'compact' });
                text = formatter.format(value);
            } else if(lang.has(this.pattern, pattern)) {
                text = formatter(value).format(this.pattern[pattern]);
            } else {
                text = formatter(value).format(pattern);
            }
        } else {
            text = lang.isString(this.pattern) ? formatter(value).format(this.pattern) : value.toString();
        }
        return text;
    }
    parse(text) {
        return formatter(text).value();
    }
}

const numeral = new Numeral();

export default numeral;