import _ from 'underscore';
import Sort from "./Sort";

class Lang {
    isBoolean(...values) {
        return values.every(value=>_.isBoolean(value));
    }
    isFalse(...values) {
        return values.every(value=>value===false);
    }
    isTrue(...values) {
        return values.every(value=>value===true);
    }
    isString(...values) {
        return values.every(value=>_.isString(value));
    }
    isNumber(...values) {
        return values.every(value=>_.isNumber(value));
    }
    isFunction(...values) {
        return values.every(value=>_.isFunction(value));
    }
    isClass(...values) {
        return values.every(value=>_.isFunction(value));
    }
    isDate(...values) {
        return values.every(value=>_.isDate(value));
    }
    isNull(...values) {
        return values.every(value=>value===null);
    }
    isNotNull(...values) {
        return values.every(value=>!this.isNull(value));
    }
    isUndefined(...values) {
        return values.every(value=>value===undefined);
    }
    isNotUndefined(...values) {
        return values.every(value=>!this.isUndefined(value));
    }
    isNullOrUndefined(...values) {
        return values.every(value=>value===null||value===undefined);
    }
    isNotNullOrUndefined(...values) {
        return values.every(value=>!this.isNullOrUndefined(value));
    }
    isEmpty(...values) {
        return values.every(value=> lang.isNullOrUndefined(value)
            || value === ''
            || (lang.isArray(value) && value.length === 0)
            || (lang.isObject(value) && (value.constructor === Object) && Object.keys(value).length === 0)
        );
    }
    isNotEmpty(...values) {
        return values.every(value=>!this.isEmpty(value));
    }
    isEqual(a, b) {
        if(lang.isDate(a, b)) {
            return a.getTime()===b.getTime();
        } else if(lang.isObject(a) && typeof a.equals === 'function') {
            return a.equals(b);
        } else if(lang.isObject(b) && typeof b.equals === 'function') {
            return b.equals(a);
        } else {
            return _.isEqual(a, b);
        }
    }
    isArray(object) {
        return  Array.isArray(object);
    }
    isObject(object) {
        return _.isObject(object);
    }
    isInteger(object) {
        return Number.isInteger(object);
    }
    isFinite(value) {
        return Number.isFinite(value);
    }
    isNaN(object) {
        return _.isNaN(object);
    }
    isPrimitive(value) {
        return this.isNumber(value) || this.isString(value) || this.isDate(value) || this.isBoolean(value);
    }
    isFile(value) {
        return value instanceof File;
    }

    /**
     * test if the string is the JSON string.
     *
     * JSON is a text-based data format following JavaScript object syntax
     * @param str
     * @returns {boolean}
     */
    isJSON(str) {
        if(!_.isString(str)) return false;

        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    each(list, iteratee, context) {
        if(list instanceof Set) {
            const array = Array.from(list);
            array.forEach((value, index, array) => iteratee.call(context, value, index, array));
        } else {
            _.each(list, iteratee, context);
        }
    }

    map(list, iteratee, context) {
        return _.map(list, iteratee, context);
    }

    mapObject(obj, iteratee, context) {
        return _.mapObject(obj, iteratee, context);
    }

    pairs(obj) {
        return _.pairs(obj);
    }

    reduce(list, iteratee, memo, context) {
        return _.reduce(list, iteratee, memo, context);
    }

    every(list, iteratee, context) {
        return _.every(list, iteratee, context);
    }

    some(list, iteratee, context) {
        return _.some(list, iteratee, context);
    }

    find(list, predicate, context) {
        let result = null;
        if(list instanceof Map) {
            const index = this.findIndex(list, predicate, context);
            (index===null) || (result = list.get(index));
        } else {
            result = _.find(list, predicate, context);
        }
        return result;
    }

    findIndex(list, predicate, context) {
        let index = null;
        if(list instanceof Map) {
            for(const entry of list.entries()) {
                const value = entry[1];
                const key = entry[0];
                const test = predicate.call(context, value, key, list);
                if(test) {
                    index = key;
                    break;
                }
            }
        } else {
            index = _.findIndex(list, predicate, context);
        }
        return index;
    }

    filter(list, predicate, context) {
        let results = [];
        if(list instanceof Map) {
            for(const entry of list.entries()) {
                const value = entry[1];
                const key = entry[0];
                const test = predicate.call(context, value, key, list);
                test && results.push(value);
            }
        } else {
            results =  _.filter(list, predicate, context);
        }
        return results;
    }

    sort(list, comparator) {
        let fn;
        if(lang.isString(comparator)) {
            comparator = Sort.asc(comparator)
        }
        if(comparator instanceof Sort) {
            const sort = comparator;
            fn = (a, b) => {
                let comparison = 0;
                for(let i=0; i<sort.orders.length; i++) {
                    const order = sort.orders[i];
                    const direction = (order.direction===Sort.Direction.ASC) ? 1 : -1;
                    const aValue = a[order.property];
                    const bValue = b[order.property];

                    if (lang.isNullOrUndefined(aValue, bValue)) {
                        comparison = 0;
                    } else if (lang.isNullOrUndefined(bValue)) {
                        comparison = 1 * direction;
                    } else if (lang.isNullOrUndefined(aValue)) {
                        comparison = -1 * direction;
                    } else {
                        if(aValue<bValue) {
                            comparison = -1 * direction;
                        } else if(aValue>bValue) {
                            comparison = 1 * direction;
                        } else {
                            comparison = 0;
                        }
                    }

                    if(comparison!==0) {
                        break;
                    }
                }
                return comparison;
            };
        } else {
            fn = comparator;
        }
        return [...list].sort(fn);
    }

    first(array, n) {
        return _.isArray(array) ? _.first(array, n) : null;
    }

    last(array, n) {
        return _.isArray(array) ? _.last(array, n) : null;
    }

    has(object, ...keys) {
        return keys.every(key => _.has(object, key));
    }

    values(object) {
        return _.values(object);
    }

    debounce(fn, wait, immediate) {
        return _.debounce(fn, wait, immediate);
    }
    throttle(fn, wait, options) {
        return _.throttle(fn, wait, options);
    }
    defer(fn, ...args) {
        return _.defer.apply(null, [fn, ...args]);
    }
    delay(fn, time, ...args) {
        return _.delay.apply(null, [fn, time, ...args]);
    }

    until(fn, when, args) {
        if(when()) {
            _.defer(fn, args);
        } else {
            _.delay(()=>this.until(fn, when, args), 500);
        }
    }

    async sleep(milliseconds) {
        return new Promise((resolve) => {
            setTimeout(resolve, milliseconds);
        });
    }

    async repeat(task, interval, ...args) {
        const taskWrapper = async (interval, ...args) => {
            const timeout = _.isFunction(interval) ? interval() : interval;
            const newTimeout = await task(...args);
            setTimeout(taskWrapper, newTimeout || timeout, args);
        };
        this.defer(taskWrapper, interval, ...args);
    }
    omit(object, ...keys) {
        return _.omit(object, keys);
    }

    clone(obj) {
        return JSON.parse(JSON.stringify(obj));
    }
}

const lang = new Lang();

export default lang;