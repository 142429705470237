const habitatImprovement = {
    "sections":[
        {
            "subject":"species",
            "questions":[
                {
                    "description":"目前田區有哪些野生動物",
                    "type":"oeq",
                    "answer": "莫氏樹蛙"
                },
                {
                    "description":"保育對象的特性簡述",
                    "type":"oeq",
                    "helperText":"可至網站或書籍查詢相關資料後填寫，包含食性、棲息環境、出沒時間",
                    "answer": "平常住在樹上，繁殖期時才到水邊活動，常挖一個淺淺的洞藏身在落葉底下，也喜歡躲在水溝旁邊的石縫、鬆鬆的土堆或草根裡鳴叫，有時也會爬到樹上鳴叫。叫聲很響亮，如同火雞叫般的一長串「呱-阿，呱阿阿阿」。雌蛙受雄蛙叫聲吸引主動接近雄蛙形成配對，偶而也會出現一隻雌蛙同時和多隻雄蛙交配情形。"
                },
                {
                    "description":"保育對象在田區現況描述",
                    "type":"oeq",
                    "helperText":"例如：族群數量、平常出沒地點、時間或季節性及其他動物說明",
                    "answer": "繁殖期：隨地區而異，台灣北部及東北部一般在春天及夏天繁殖，中南部則在夏天及秋天產卵，潮溼的山區、例如溪頭，則終年繁殖。"
                },
                {
                    "description":"田間操作模式與動物保育關聯性",
                    "type":"oeq",
                    "helperText":"請說明：作物種類、輪作體系、保育動物與各種作物關係或其他文化性及特殊性",
                    "answer": "一同成長"
                },
                {
                    "description":"棲地改善措施",
                    "type":"oeq",
                    "answer": "幫他們鬆土，讓他們可以躲"
                }
            ]
        },
        {
            "subject":"habitat",
            "questions":[
                {
                    "description":"營造工法",
                    "type":"oeq",
                    "helperText":"請說明所採行的措施，例如：以生態工法營造生態池，營造措施包括砌石、土堤、水深大小、水生植物種類、底質級配等等說明。",
                    "answer": "以生態工法營造生態池，營造措施包括砌石、土堤、水深大小、水生植物種類"
                },
                {
                    "description":"營造位置",
                    "type":"oeq",
                    "answer": "田邊"
                },
                {
                    "description":"營造植物種類",
                    "type":"oeq",
                    "answer": "台灣萍蓬草"
                },
                {
                    "description":"其他說明",
                    "type":"oeq",
                    "answer": "沒有"
                },
                {
                    "description":"野生動物利用所營造棲地現況描述",
                    "type":"oeq",
                    "helperText":"請說明：有那些物種、如何利用、時間或季節性、族群數量等等說明",
                    "answer": "充分利用"
                },
                {
                    "description":"田間操作模式與棲地維護關聯性",
                    "type":"oeq",
                    "helperText":"請說明：作物種類、輪作體系、操作情形等等與棲地維護之關係",
                    "answer": "互利互惠"
                }
            ]
        }
    ]
}

export default habitatImprovement;