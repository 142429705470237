import axios from "axios";
import logger from './logger';
import MockAdapter from "axios-mock-adapter";
import lang from "./lang";
import {json} from "./json";
import time from "./time";
import fileIO from "./fileIO";
import hal from "./hal";
import Sort from "./Sort";
import {ApplicationError, ConstraintError} from "../error";
import cookie from "./cookie";

const HTTP_STATUS_TEXT = new Map();
HTTP_STATUS_TEXT.set(400, 'Bad Ajax');
HTTP_STATUS_TEXT.set(401, 'Unauthorized');
HTTP_STATUS_TEXT.set(403, 'Forbidden');
HTTP_STATUS_TEXT.set(404, 'Not Found');
HTTP_STATUS_TEXT.set(405, 'Method Not Allowed');
HTTP_STATUS_TEXT.set(406, 'Not Acceptable');
HTTP_STATUS_TEXT.set(408, 'Ajax Timeout');
HTTP_STATUS_TEXT.set(409, 'Conflict');
HTTP_STATUS_TEXT.set(500, 'Internal Server Failure');
HTTP_STATUS_TEXT.set(501, 'Not Implemented');
HTTP_STATUS_TEXT.set(503, 'Service Unavailable');
HTTP_STATUS_TEXT.set(504, 'Gateway Timeout');

const Authorization = {
    BASIC: 'Basic',
    BEARER: 'Bearer'
}

const HTTPMethod = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
    PUT: 'PUT',
    PATCH: 'PATCH'
}

const EXPIRED_DAYS = 90;
const PERSISTENT_SESSION_KEY = 'persistentSession';
const AUTHORIZATION_KEY = 'authorization';

const log = logger.getLogger('ajax');

const ISO8601 = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

class Ajax {
    axios = undefined;
    authorization = undefined;
    captcha = undefined;

    constructor(config = {}) {
        this.axios = axios.create(config);
    }

    init(options = {}) {
        options.baseURL && (this.axios.defaults.baseURL = options.baseURL);
    }

    set baseURL(baseURL) {
        this.axios.defaults.baseURL = baseURL
    }

    get interceptors() {
        return this.axios.interceptors;
    }

    resolve(url) {
        if(lang.isNullOrUndefined(url)) {
            throw new Error(`Invalid url: ${url}`);
        }
        let href;
        if(url.startsWith('http') || url.startsWith('/') || !this.axios.defaults.baseURL) {
            href = url;
        } else {
            const separator = this.axios.defaults.baseURL.endsWith('/') ? '' : '/';
            href = this.axios.defaults.baseURL + separator + url;
        }
        const index = href.indexOf('://');
        if(index === -1) {
            href = href.replaceAll('//', '/');
        } else {
            href = href.substring(0, index + 3) + href.substring(index + 3, href.length).replaceAll('//', '/');
        }
        return href;
    }

    async request(url, options) {
        const data = await this.encode(options.data);
        const config = {...lang.omit(options, 'authorization'), data, url};
        config.headers || (config.headers = {});
        if(options.authorization) {
            config.headers['Authorization'] = this.encodeAuthorization(options.authorization);
        } else if(this.authorization) {
            config.headers['Authorization'] = this.authorization;
        }
        if(this.captcha) {
            config.headers['Captcha'] = this.captcha;
        }

        let httpResponse;
        try {
            const response = await this.axios.request(config);
            httpResponse = new HttpResponse(response);
        } catch(err) {
            if(err.response) {
                httpResponse = new HttpResponse(err.response);
            } else {
                log.error(`${err.message}\n${err}`);
                throw err;
            }
        }

        if(!httpResponse.ok) {
            if(httpResponse.isApplicationError()) {
                throw httpResponse.error;
            } else {
                throw new Error(httpResponse.statusText);
            }
        }

        // @todo 暫時使用 authReducer 的設計來解決更換 authorization token 時，同步 cookie 的問題。
        if(httpResponse.authorization) {
            this.authorization = httpResponse.authorization;
            const persistentSession = cookie.getAsBoolean(PERSISTENT_SESSION_KEY, 'true');
            if(persistentSession) {
                cookie.set(AUTHORIZATION_KEY, this.authorization, EXPIRED_DAYS);
            }
        }

        if(httpResponse.captcha) {
            this.captcha = httpResponse.captcha;
        }
        return httpResponse;
    }

    encodeAuthorization(authorization) {
        if(lang.isString(authorization)) return authorization;

        const type = authorization.type || Authorization.BASIC;
        let credentials;

        if(type===Authorization.BASIC) {
            credentials = this.encodeBasicCredentials(authorization.username, authorization.password);
        } else {
            credentials = authorization.token;
        }

        return `${type} ${credentials}`;
    }

    encodeBasicCredentials(username, password) {
        password || (password = '');
        return btoa(`${username}:${password}`);
    }


    async get(url, params = {}, options) {
        const method = HTTPMethod.GET;
        const urlSearchParams = new URLSearchParams();
        for (const [key, value] of Object.entries(params)) {
            if(lang.isEmpty(value)) continue;
            if(value instanceof Sort) {
                for(const order of value.orders) {
                    urlSearchParams.append(key, `${order.property},${order.direction}`);
                }
            } else {
                if(value.toString()!==null) {
                    urlSearchParams.append(key, value?.toString());
                }
            }
        }
        return this.request(url, {...options, method, params: urlSearchParams});
    }

    async post(url, data, options) {
        const method = HTTPMethod.POST;
        return this.request(url, {...options, data, method});
    }

    async delete(url, params, options) {
        const method = HTTPMethod.DELETE;
        return this.request(url, {...options, method, params});
    }

    async put(url, data, options) {
        const method = HTTPMethod.PUT;
        return this.request(url, {...options, data, method});
    }

    async patch(url, data, options) {
        const method = HTTPMethod.PATCH;
        return this.request(url, {...options, data, method});
    }

    create(config) {
        return new Ajax(config);
    }

    /**
     * Transform the value to an encoded JSON value.
     * - Date: encode the date value to a ISO8601 string.
     * - File: encode the file object to a base64 encoded object.
     * @param value
     * @param allowEmptyObject when true, convert the empty object (all the properties are empty) to null
     */
    async encode(value, allowEmptyObject = true) {
        let jsonValue;
        if(lang.isArray(value)) {
            jsonValue = await Promise.all(value.map(async val => await this.encode(val)));
        } else if(lang.isObject(value)) {
            if(this.isRichText(value)) {
                jsonValue = this.encodeTypedObject(value);
            } else if(lang.isDate(value)) {
                jsonValue = time.format(value, ISO8601);
            } else if(lang.isFile(value)) {
                jsonValue = await fileIO.encode(value)
            } else {
                const obj = value.toJSON ? value.toJSON() : value
                jsonValue = await this.mapObject(obj, async val => await this.encode(val, false));
                if(!allowEmptyObject && Object.values(jsonValue).every(value => !lang.isArray(value) && lang.isEmpty(value))) {
                    jsonValue = null;
                }
            }
        } else if(lang.isString(value)) {
            jsonValue = (value==='') ? null : value;
        } else {
            jsonValue = value;
        }

        return jsonValue;
    }

    isRichText(value) {
        return value?.type === 'rtx';
    }

    encodeTypedObject(value) {
        let jsonValue;
        if(fileIO.isFileDescriptor(value)) {
            jsonValue = value;
        } else {
            jsonValue = JSON.stringify(value);
        }
        return jsonValue;
    }

    /**
     * Transform the encoded JSON value to a pojo or primitive value.
     * - Date: decode the ISO8601 string to a date value.
     * - File: decode a base64 encoded object to a file object.
     *
     * @param value
     */
    async decode(value) {
        let jsonValue
        if(lang.isArray(value)) {
            jsonValue = await Promise.all(value.map(async val => await this.decode(val)));
        } else if(lang.isObject(value)) {
            if(fileIO.isFileDescriptor(value)) {
                jsonValue = await fileIO.decode(value)
            } else {
                jsonValue = await this.mapObject(value, async val => await this.decode(val));
            }
        } else if(lang.isString(value) && time.isISODateString(value)) {
            jsonValue = time.parse(value, ISO8601);
        } else {
            jsonValue = value;
        }

        return jsonValue;
    }

    async mapObject(jsonObject, fn) {
        let json = {};
        for(const key in jsonObject) {
            json[key] = await fn(jsonObject[key]);
        }
        return json;
    }
}

class HttpResponse {
    #response = undefined;
    #headers = undefined;
    constructor(response) {
        this.#response = response;
        this.#headers = new Headers(response.headers);
    }
    get data() {
        const data = json.decode(this.#response.data);
        if(lang.isNotNullOrUndefined(this.totalElements)) {
            data.totalElements = this.totalElements;
        }
        return data;
    }
    get text() {
        return this.#response.data;
    }
    //@deprecated
    get resources() {
        let data;
        if(this.contentType?.includes('application/hal+json')) {
            data = hal.convertToResources(this.data);
        } else {
            data = this.data;
        }
        if(lang.isNotNullOrUndefined(this.totalElements)) {
            data.totalElements = this.totalElements;
        }
        return data;
    }
    //@deprecated
    get resource() {
        let data;
        if(this.contentType?.includes('application/hal+json')) {
            data = hal.convertToResource(this.data);
        } else {
            data = this.data;
        }
        return data;
    }
    get headers() {
        return this.#headers;
    }
    get status() {
        if(this.headers.has('Error')||this.headers.has('Exception')) {
            return this.data.status;
        } else {
            return this.#response.status;
        }
    }
    get statusText() {
        return lang.isNotEmpty(this.message) ? this.message : this.#response.statusText;
    }
    get error() {
        const errorType = (this.headers.get('Error') || this.headers.get('Exception')) || this.data.error;
        if(errorType === 'ConstraintError') {
            return ConstraintError.of(this.data);
        } else if(errorType) {
            return ApplicationError.of(this.data);
        } else {
            return this.data;
        }
    }
    isApplicationError() {
        return (this.headers.has('Error') || this.headers.has('Exception')) || lang.isNotNullOrUndefined(this.data.error);
    }
    get message() {
        return this.data?.message;
    }
    get authorization() {
        return this.headers.get('Authorization');
    }
    get captcha() {
        return this.headers.get('Captcha');
    }
    get contentType() {
        return this.headers.get("Content-Type");
    }
    get ok() {
        return this.status>=200 && this.status<=299 && !this.isApplicationError();
    }
    get noContent() {
        return this.status===204;
    }
    get notFound() {
        return this.status===404;
    }
    get notModified() {
        return this.status===304;
    }
    get totalElements() {
        return this.headers.has('totalElements') ? parseInt(this.headers.get("TotalElements")) : null;
    }
}

class Headers {
    data = new Map();

    constructor(headers) {
        if(headers) {
            for(const [key, value] of Object.entries(headers)) {
                this.data.set(key.toLowerCase(), value);
            }
        }
    }

    get(key) {
        return this.data.get(key.toLowerCase());
    }

    has(key) {
        return this.data.has(key.toLowerCase());
    }
}

class Mock extends Ajax {
    constructor(config = {}) {
        super(config);
        this.mock = new MockAdapter(this.axios);
    }

    create(config) {
        return new Mock(config);
    }

    use(mappings = []) {
        mappings.forEach((mapping = {}) => {
            const { matcher, reply} = mapping;
            const method = mapping.method?.toUpperCase();
            switch (method) {
                case 'GET':
                    this.onGet(matcher).reply(reply);
                    break;
                case 'POST':
                    this.onPost(matcher).reply(reply);
                    break;
                case 'PUT':
                    this.onPut(matcher).reply(reply);
                    break;
                case 'PATCH':
                    this.onPatch(matcher).reply(reply);
                    break;
                case 'DELETE':
                    this.onDelete(matcher).reply(reply);
                    break;
                default:
                    this.onAny(matcher).reply(reply);
            }
        }, this)
    }

    onGet(matcher) {
        return this.mock.onGet(matcher);
    }

    onPatch(matcher) {
        return this.mock.onPatch(matcher);
    }

    onPost(matcher) {
        return this.mock.onPost(matcher);
    }

    onDelete(matcher) {
        return this.mock.onDelete(matcher);
    }

    onAny(matcher) {
        return this.mock.onAny(matcher);
    }
}

const ajax = new Ajax();

export { ajax as default, Ajax, Mock, Authorization };