import React, {useState} from "react";
import {withExplorer} from "../../layout/Explorer";
import {useDispatch, useSelector} from "../../store";
import LogonForm from "./LogonForm";
import Box from "../../lib/appfuse-react/component/Box";
import Paper from "../../lib/appfuse-react/component/Paper";
import {HomeImage} from "../Home";
import {isAuthenticated, selectPersistentSession, setPersistentSession} from "../../reducer/iam/authReducer";
import {Redirect, useLocation} from "react-router-dom";
import useRouter from "../../lib/appfuse-react/hook/useRouter";
import usePrompt from "../../lib/appfuse-react/hook/usePrompt";
import authService from "../../service/iam/authService";
import VerificationForm from "./VerificationForm";
import lang from "../../lib/appfuse-react/core/lang";
import ForgotPasswordForm from "./ForgotPasswordForm";

const Mode = {
    LOGON: 'LOGON',
    FORGET_PASSWORD: 'FORGET_PASSWORD',
    VERIFICATION: 'VERIFICATION'
}

function Logon() {
    const dispatch = useDispatch();
    const persistentSession = useSelector(selectPersistentSession) || false;
    const [value, setValue] = useState({persistentSession});
    const authenticated = useSelector(isAuthenticated);
    const [token, setToken] = useState();
    const [mode, setMode] = useState(Mode.LOGON);
    const location = useLocation();
    const router = useRouter();
    const prompt = usePrompt();

    const handleChange = (event) => {
        if(lang.has(event.change, 'persistentSession')) {
            dispatch(setPersistentSession(event.change.persistentSession));
        }
        setValue({...event.value});
    }

    const handleLogin = async () => {
        const {username, password, verificationCode} = value;
        try {
            const data = await authService.login({username, password}, {verificationCode});
            if(data?.token) {
                setToken(data.token);
                setMode(Mode.VERIFICATION);
            }
        } catch (error) {
            prompt.alert(error, {title: 'Failed to login'});
        }
    }

    const handleForgotPassword = () => {
        setMode(Mode.FORGET_PASSWORD);
    };

    const handleApplyOnClickLink = async ({username}) => {
        try {
            await authService.forgotPassword(username);
            prompt.success('An email with a login link has been sent to you.');
        } catch (e) {
            prompt.alert(e, {title: 'Failed to send the login link.'});
        }
    };


    const handleRegister = () => {
        router.register();
    };

    const handleVerify = async ({pin}) => {
        try {
            await authService.verify(token, pin);
        } catch (error) {
            setMode(Mode.LOGON);
            prompt.alert(error, {title: 'Failed to verify'});
        }
    }

    const handleResend = async () => {
        try {
            await handleLogin();
            prompt.info("An email with the verification code has been resent to you.")
        } catch (e) {
            prompt.alert(e, {title: 'Failed to send the verification code.'});
        }
    }

    const handleCancel = () => {
        setMode(Mode.LOGON);
    }

    if(authenticated) {
        const from = location?.state?.from || '/';
        return <Redirect to={from} />;
    }

    let form;
    if(mode === Mode.VERIFICATION) {
        form = <VerificationForm onVerify={handleVerify} onResend={handleResend} onCancel={handleCancel} />;
    } else if(mode === Mode.FORGET_PASSWORD) {
        form = <ForgotPasswordForm onCancel={handleCancel} onApply={handleApplyOnClickLink} />;
    } else {
        form = (
            <Paper position="absolute" left={{sm: '7%', md: "auto"}} right={{sm: 'auto', md: "7%"}} top="20%" width="36%" minWidth="20rem" maxWidth="30rem"
                   backgroundOpacity={0.9} elevation={1} padding={3}>
                <LogonForm value={value} onChange={handleChange} onLogin={handleLogin} onForgotPassword={handleForgotPassword} onRegister={handleRegister} />
            </Paper>
        );
    }

    return (
        <Paper className="app-logon"  position="relative" fullWidth fullHeight>
            <Box position="absolute" top={0} left={0} fullWidth fullHeight backgroundColor="black" backgroundOpacity={0.5} >
                <HomeImage />
            </Box>
            {form}
        </Paper>
    );
}


export default withExplorer(Logon);