import {createSlice} from "@reduxjs/toolkit";
import {json} from "../../lib/appfuse-react/core/json";
import Sort from "../../lib/appfuse-react/core/Sort";

const name = 'cropApplicationDraft';

const initialState = {
    selection: [],
    pageNumber: 0,
    pageSize: 10,
    criteria: {},
    sort: null,
    drafts: {},
};

const slice = createSlice({
    name,
    initialState,
    reducers: {
        setSelection(state, action) {
            state.selection = json.toJSON(action.payload);
        },
        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setCriteria(state, action) {
            state.criteria = json.toJSON(action.payload);
        },
        setSort(state, action) {
            state.sort = json.toJSON(action.payload);
        },
        setDrafts(state, action) {
            state.drafts = json.toJSON(action.payload);
        }
    }
});

export const { setSelection, setPageNumber, setPageSize, setCriteria, setSort, setDrafts } = slice.actions;

export const selectSelection = state => json.fromJSON(state[name].selection);
export const selectPageNumber = state => state[name].pageNumber;
export const selectPageSize = state => state[name].pageSize;
export const selectCriteria = state => json.fromJSON(state[name].criteria);
export const selectSort = state => json.fromJSON(state[name].sort, Sort);
export const selectDrafts = state => json.fromJSON(state[name].drafts);
export const selectDraft = (key) => (state) => selectDrafts(state)[key];

export const setDraft = (key, draft) => (dispatch, getState) => {
    const drafts = selectDrafts(getState());
    if(drafts[key]) drafts[key] = {...drafts[key], ...draft};
    else drafts[key] = draft;
    dispatch(setDrafts(drafts));
};

export const removeDraft = key => (dispatch, getState) => {
    const drafts = selectDrafts(getState());
    delete drafts[key];
    dispatch(setDrafts(drafts));
};

const approveProcessingApplicationDraftReducer = slice.reducer;
export default approveProcessingApplicationDraftReducer;