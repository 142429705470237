import appReducers from '../reducer/app';
import salesReducers from "../reducer/sales";
import iamReducers from "../reducer/iam";
import contentReducers from "../reducer/content";
import inboxReducers from "../reducer/inbox";
import samplingCropReducers from "../reducer/samplingCrop";
import cropApplicationReducers from "../reducer/cropApplication";
import farmerReducers from "../reducer/farmer";
import accountReducers from "../reducer/account";
import regionReducers from "../reducer/region";
import utilReducers from "../reducer/util";
import letterTemplateReducers from "../reducer/letterTemplate";
import cropCategoryReducers from "../reducer/cropCategory";
import markApplicationReducers from "../reducer/markApplication";
import disqualificationApplicationReducers from "../reducer/disqualificationApplication";
import reportReducers from "../reducer/report";
import approveProcessingReducers from "../reducer/approveProcessingInbox";
import approveProcessingApplicationReducers from "../reducer/approveProcessingApplication";
import systemCodeReducers from "../reducer/systemCode";
import approveSamplingReducers from "../reducer/samplingApprove";
import smsReducers from "../reducer/sms";

const rootReducer = {
    ...appReducers,
    ...iamReducers,
    ...salesReducers,
    ...contentReducers,
    ...inboxReducers,
    ...samplingCropReducers,
    ...cropApplicationReducers,
    ...farmerReducers,
    ...accountReducers,
    ...regionReducers,
    ...utilReducers,
    ...letterTemplateReducers,
    ...cropCategoryReducers,
    ...markApplicationReducers,
    ...disqualificationApplicationReducers,
    ...reportReducers,
    ...approveProcessingReducers,
    ...approveProcessingApplicationReducers,
    ...systemCodeReducers,
    ...approveSamplingReducers,
    ...smsReducers
};

export default rootReducer;
