const markInboxes = [
    {
        "uuid":"1",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGA00150-C-0-00-001",
        "type":"initial",
        "activity":"S10",
        "farmerNo":"CGA00150",
        "farmerName":"王森林",
        "markApplication": {
            "id": "1",
            "farmer": "王森林",
            "applyDate": "2019-05-06T08:20:10.000Z",
            "contactPerson": "王森林",
            "contactPersonPhone": "0911234098",
            "receiveType": "byYourself",
            "useDateStart": "2019-05-06T08:20:10.000Z",
            "useDateEnd": "2022-05-06T08:20:10.000Z",
            "applicationItem": ["玉米", "玉米筍"],
            "style": "sticker",
            "bigMarkQuantity": "1000",
            "smallMarkQuantity": "100",
            "applicationItemDetail": [
                {
                    "crop": "玉米",
                    "content": [
                        {
                            "spec": "玉米規格1",
                            "packagingLabel": [
                                {
                                    "type": "",
                                    "size": 0,
                                    "bytes": "",
                                    "name": "玉米內包裝1.pdf",
                                    "lastModified": 0
                                },
                                {
                                    "type": "",
                                    "size": 0,
                                    "bytes": "",
                                    "name": "玉米外包裝1.pdf",
                                    "lastModified": 0
                                }
                            ]
                        },
                        {
                            "spec": "玉米規格2",
                            "packagingLabel": [
                                {
                                    "type": "",
                                    "size": 0,
                                    "bytes": "",
                                    "name": "玉米內包裝2.pdf",
                                    "lastModified": 0
                                }
                            ]
                        }
                    ]
                },
                {
                    "crop": "玉米筍",
                    "content": [
                        {
                            "spec": "玉米筍規格",
                            "packagingLabel": [
                                {
                                    "type": "",
                                    "size": 0,
                                    "bytes": "",
                                    "name": "玉米筍包裝.pdf",
                                    "lastModified": 0
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "workflowHistory": [
            {
                "activity": "S10",
                "createdOn": "2021/9/10",
                "assigneeName": "王小華",
                "examination": "approve",
            },
            {
                "activity": "S20",
                "createdOn": "2021/9/10",
                "assigneeName": "張小花",
                "examination": "approve",
            }
        ]
    },
    {
        "uuid":"2",
        "createdOn":"2021-03-12T08:20:10.000Z",
        "appNo":"CGB00090-C-0-00-001",
        "type":"initial",
        "activity":"S20",
        "farmerNo":"CGB00090",
        "farmerName":"吳小花",
        "markApplication": {
            "farmer": "吳小花",
            "applyDate": "2021-03-05T08:20:10.000Z",
            "contactPerson": "吳小花",
            "contactPersonPhone": "0919234098",
            "receiveType": "byPost",
            "mailingAddress": {
                "city":"63",
                "district":"中正區",
                "street":"忠孝東路",
                "zipCode":"100"
            },
            "inventory": 450,
            "applicationItem": ["蘿蔔", "地瓜"],
            "style": "overprint",
            "applicationItemDetail": [
                {
                    "crop": "蘿蔔",
                    "content": [
                        {
                            "spec": "蘿蔔規格"
                        }
                    ]
                },
                {
                    "crop": "地瓜",
                    "content": [
                        {
                            "spec": "地瓜規格1",
                            "packagingLabel": [
                                {
                                    "type": "",
                                    "size": 0,
                                    "bytes": "",
                                    "name": "地瓜包裝1.png",
                                    "lastModified": 0
                                }
                            ]
                        },
                        {
                            "spec": "地瓜規格2",
                            "packagingLabel": [
                                {
                                    "type": "",
                                    "size": 0,
                                    "bytes": "",
                                    "name": "地瓜包裝2.png",
                                    "lastModified": 0
                                }
                            ]
                        },
                        {
                            "spec": "地瓜規格3",
                            "packagingLabel": [
                                {
                                    "type": "",
                                    "size": 0,
                                    "bytes": "",
                                    "name": "地瓜包裝3.png",
                                    "lastModified": 0
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "workflowHistory": [
            {
                "activity": "S10",
                "createdOn": "2021/9/10",
                "assigneeName": "王小華",
                "examination": "reject",
            },
            {
                "activity": "S20",
                "createdOn": "2021/9/10",
                "assigneeName": "張小花",
                "examination": "approve",
            }
        ],

        "review": "approve",
        "decision": "approve",
        "overPrintQuantityApproved": "200",
    },
    {
        "uuid": "4",
        "createdOn": "2021-03-12T08:20:10.000Z",
        "appNo": "CGA00154-C-0-00-001",
        "type": "initial",
        "activity": "S40",
        "farmerNo": "CGA00153",
        "farmerName": "李小異",
        "markApplication": {
            "farmer": "李小異",
            "applyDate": "2020-09-22T08:20:10.000Z",
            "contactPerson": "李小異",
            "contactPersonPhone": "0919234098",
            "receiveType": "byPost",
            "mailingAddress": {
                "city":"63",
                "district":"中正區",
                "street":"忠孝東路",
                "zipCode":"100"
            },
            "inventory": 300,
            "useDateStart": "2020-09-22T08:20:10.000Z",
            "useDateEnd": "2021-09-22T08:20:10.000Z",
            "applicationItem": ["蘿蔔", "地瓜"],
            "style": "sticker",
            "bigMarkQuantity": "1000",
            "smallMarkQuantity": "500",
            "applicationItemDetail": [
                {
                    "crop": "蘿蔔",
                    "content": [
                        {
                            "spec": "蘿蔔規格"
                        }
                    ]
                },
                {
                    "crop": "地瓜",
                    "content": [
                        {
                            "spec": "地瓜規格",
                            "packagingLabel": [
                                {
                                    "type": "",
                                    "size": 0,
                                    "bytes": "",
                                    "name": "地瓜包裝.png",
                                    "lastModified": 0
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "workflowHistory": [
            {
                "activity": "S10",
                "createdOn": "2021/9/10",
                "assigneeName": "王小華",
                "examination": "approve",
            },
            {
                "activity": "S20",
                "createdOn": "2021/9/10",
                "assigneeName": "張小花",
                "examination": "approve",
            }
        ],

        "style": "sticker",
        "bigMarkQuantityApproved": 1000,
        "smallMarkQuantityApproved": 300
    },
    {
        "uuid": "5",
        "createdOn": "2021-03-12T08:20:10.000Z",
        "appNo": "CGA00156-C-0-00-001",
        "type": "initial",
        "activity": "S40",
        "farmerNo": "CGA00153",
        "farmerName": "李大異",
        "markApplication": {
            "farmer": "李大異",
            "applyDate": "2020-09-22T08:20:10.000Z",
            "contactPerson": "李大異",
            "contactPersonPhone": "0919234098",
            "receiveType": "byPost",
            "mailingAddress": {
                "city":"63",
                "district":"中正區",
                "street":"忠孝東路",
                "zipCode":"100"
            },
            "inventory": 300,
            "useDateStart": "2020-09-22T08:20:10.000Z",
            "useDateEnd": "2021-09-22T08:20:10.000Z",
            "applicationItem": ["蘿蔔", "地瓜"],
            "style": "sticker",
            "smallMarkQuantity": "500",
            "applicationItemDetail": [
                {
                    "crop": "蘿蔔",
                    "content": [
                        {
                            "spec": "蘿蔔規格"
                        }
                    ]
                },
                {
                    "crop": "地瓜",
                    "content": [
                        {
                            "spec": "地瓜規格",
                            "packagingLabel": [
                                {
                                    "type": "",
                                    "size": 0,
                                    "bytes": "",
                                    "name": "地瓜包裝.png",
                                    "lastModified": 0
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "workflowHistory": [
            {
                "activity": "S10",
                "createdOn": "2021/9/10",
                "assigneeName": "王小華",
                "examination": "approve",
            },
            {
                "activity": "S20",
                "createdOn": "2021/9/10",
                "assigneeName": "張小花",
                "examination": "approve",
            }
        ],

        "style": "sticker",
        "smallMarkQuantityApproved": 300
    }
]

export default markInboxes;