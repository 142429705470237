import lang from "../../core/lang";
import Box from "../Box";
import {CircularProgress as MuiCircularProgress} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from "clsx";
import i18n from "../../core/i18n";

function CircularProgress(props) {
    const { value, children, className,
        fullHeight, fullWidth, position, top, right, bottom, left,
        color, backgroundColor, backgroundOpacity, backgroundImage, backgroundSize, backgroundRepeat } = props;
    const options = lang.omit('children', 'className', 'value');

    let ready;
    if(lang.isNumber(value)) {
        ready = (value===100);
        options.value = value;
    } else {
        ready = lang.isNotNullOrUndefined(value) && !lang.isFalse(value);
    }

    const progress = (
        <Box className={clsx('appfuse-circular-progress', className)} display="flex" alignItems="center" justifyContent="center"
             fullHeight={fullHeight} fullWidth={fullWidth} position={position} top={top} right={right} bottom={bottom} left={left}
             color={color} backgroundImage={backgroundImage} backgroundSize={backgroundSize} backgroundRepeat={backgroundRepeat}
             backgroundColor={backgroundColor} backgroundOpacity={backgroundOpacity}>
            <MuiCircularProgress {...options} aria-label={i18n.translate('Loading')} />
        </Box>
    );

    return ready ? children : progress;
}

CircularProgress.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,

    // Sizing
    fullWidth: PropTypes.bool,
    fullHeight: PropTypes.bool,

    // Palette
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    backgroundImage: PropTypes.string,
    backgroundSize: PropTypes.string,
    backgroundRepeat: PropTypes.string,
    backgroundOpacity: PropTypes.number,
    opacity: PropTypes.number,

    // Position
    position: PropTypes.string,
    zIndex: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    top: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    right: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    bottom: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    left: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
}

CircularProgress.defaultProps = {
}

export default CircularProgress;
