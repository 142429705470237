import codes from "./codes";


const codeService = [
    {
        matcher: 'codes',
        reply: config => {
            return [200, codes];
        }
    },
    {
        matcher: /codes\/findByType\/(.*)/,
        reply: config => {
            const type = /codes\/findByType\/(.*)/.exec(config.url)[1];
            const codes = codes.filter(data => data.type === type);
            return [200, codes];
        }
    }
]

export default codeService;