import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import {makeStyles} from "@material-ui/core";
import {useContext} from "react";
import {AccordionContext} from "./index";
import palette from "../../core/palette";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        backgroundColor: props => props.backgroundColor
    },
}));

function AccordionDetails(props) {
    const theme = useContext(AccordionContext);
    const {color = theme.color} = props;
    const backgroundColor = color?.light || palette.color(color, 0.1);
    const classes = useStyles({backgroundColor});
    return (
        <MuiAccordionDetails {...props} classes={classes} />
    )
}
export default AccordionDetails;