import {ApplicationError} from "./index";
import lang from "../core/lang";
import i18n from "../core/i18n";

class ConstraintError extends ApplicationError {
    #violations = [];

    constructor(message, {params, status, cause, violations} = {}) {
        super(message, {params, status, cause});
        this.#violations = violations.map(violation => Violation.of(violation));
    }

    get violations() {
        return this.#violations;
    }

    static of(value) {
        let error;
        if(lang.isNullOrUndefined(value)) {
            error = value;
        } else if(lang.isObject(value)) {
            const { message } = value;
            error = new ConstraintError(message, value);
        } else {
            throw new Error(`Type is not supported. Supported types are JSON object, but receive: ${value}`);
        }
        return error;
    }

    toString(dictionary = {}) {
        const violationMessage = this.violations.reduce((accumulate, violation) => {
            const props = violation.props.map(prop => {
                const key = dictionary[prop] || prop;
                return i18n.translate(key)
            });
            let message = this.interpolate(i18n.translate(violation.message), violation.params);
            if(props.length > 0) {
                message = `${props.join()}: ${message}`;
            }
            return `${accumulate}\n${message}`;
        }, '');

        return `${this.interpolate(i18n.translate(this.message), this.params)}${violationMessage}`;
    }
}

class Violation {
    #props;
    #message;
    #params;

    constructor(props, message, params) {
        this.#props = props;
        this.#message = message;
        this.#params = params;
    }

    get props() {
        return this.#props;
    }

    get message() {
        return this.#message;
    }

    get params() {
        return this.#params;
    }

    static of(value) {
        let violation;
        if(lang.isNullOrUndefined(value)) {
            violation = value;
        } else if(lang.isObject(value)) {
            const { message, params, props } = value;
            violation = new Violation(props, message, params);
        } else {
            throw new Error(`Type is not supported. Supported types are JSON object, but receive: ${value}`);
        }
        return violation;
    }
}

export default ConstraintError;