import lang from "./lang";

const BREAKPOINTS = [{
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
}]

class Responsive {
    static instance = new Responsive();
    #breakpoints = BREAKPOINTS
    #keys = Object.keys(BREAKPOINTS).reverse()

    constructor() {
        if(!Responsive.instance) {
            Responsive.instance = this;
        }
        return Responsive.instance;
    }

    getBreakpoints() {
        return this.#breakpoints;
    }

    setBreakpoints(breakpoints) {
        this.#breakpoints = breakpoints;
        this.#keys = Object.keys(breakpoints).sort((a, b) => breakpoints[b] - breakpoints[a]);
    }

    getBreakpoint() {
        return this.#keys.find(key => window.innerWidth >= this.#breakpoints[key]) || this.#breakpoints[this.#keys.length-1];
    }

    getResponsiveValue(value) {
        if(!lang.isObject(value)) return value;

        const index = this.#keys.indexOf(this.getBreakpoint());
        let key;
        for(let i=index; i<this.#keys.length; i++) {
            key = this.#keys[i];
            if(value[key]) {
                break;
            }
        }
        return key ? value[key] : undefined;
    }
}

const responsive = new Responsive();

export default responsive;