import React, {useEffect, useRef, useState} from "react";
import UserEvent from "../../core/UserEvent";
import MuiButton from "@material-ui/core/Button";
import i18n from "../../core/i18n";
import IconButton from "@material-ui/core/IconButton";
import {Box, CircularProgress, makeStyles, Typography, useTheme} from "@material-ui/core";
import PropTypes from "prop-types";
import lang from "../../core/lang";
import clsx from "clsx";
import Icon from "../Icon";
import palette from "../../core/palette";
import usePrompt from "../../hook/usePrompt";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative'
    },
    button: {
        color: props => props.color,
        backgroundColor: props => props.backgroundColor,
        '&:hover': {
            backgroundColor: props => props.backgroundColorOnHover,
            color: props => props.colorOnHover
        },
        height: props => props.height
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -8,
        marginLeft: -8,
    }
}));

/**
 * 使用 named function 取代 anonymous arrow function 讓除錯訊息除了顯示 ForwardRef 外，也能夠顯示 named function 使用的名稱來辨識是由本元件發生的錯誤。
 */
const Button = React.forwardRef(function Button(props, ref) {
    const prompt = usePrompt();
    const theme = useTheme();
    const { icon, text, title, value, edge, variant, size, height, fullWidth, noWrap, badge, delay, async } = props;
    const [hover, setHover] = useState(false);
    const [busy, setBusy] = useState(false);
    const mounted = useRef(false);
    const [color, backgroundColor, colorOnHover, backgroundColorOnHover] = ((color, backgroundColor, variant) => {
        let effectiveColor = (color in theme.palette) ? theme.palette[color].main : color;
        let effectiveBackgroundColor = (backgroundColor in theme.palette) ? theme.palette[backgroundColor].main : backgroundColor;
        let fillBackgroundColor, fillColor, fillBackgroundColorOnHover, fillColorOnHover;

        if(effectiveColor!=='inherit' && !palette.color(effectiveColor)) {
            // default color
            effectiveColor = '#546e7a';
        }

        if(effectiveColor!=='inherit' && variant!=='contained' && !(variant==='icon' && !(hover && text))) {
            if(palette.invert(effectiveColor, true)==='#000000') {
                effectiveColor = palette.darker(effectiveColor);
            }
        }
        if(variant==='contained') {
            fillColor = palette.invert(effectiveColor, true);
            fillBackgroundColor = effectiveBackgroundColor || effectiveColor;
            fillBackgroundColorOnHover = palette.darker(fillBackgroundColor);
            fillColorOnHover = palette.invert(fillBackgroundColorOnHover, true);
        } else if(variant==='outlined') {
            fillColor = effectiveColor;
            fillBackgroundColor = effectiveBackgroundColor || theme.palette.background.default;
            fillBackgroundColorOnHover = palette.darker(fillBackgroundColor);
            fillColorOnHover = palette.invert(fillBackgroundColorOnHover, true);
        } else {
            fillColor = effectiveColor;
            fillBackgroundColor = effectiveBackgroundColor || 'transparent';
            if(fillBackgroundColor === 'transparent') {
                fillBackgroundColorOnHover = palette.color(fillColor, 0.1);
            } else {
                fillBackgroundColorOnHover = palette.color(fillBackgroundColor, 0.8);
            }
        }
        return [fillColor, fillBackgroundColor, fillColorOnHover, fillBackgroundColorOnHover];
    })(props.color, props.backgroundColor, variant)

    const classes = useStyles({color, backgroundColor, colorOnHover, backgroundColorOnHover});

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, [])


    const handleClick = async event => {
        if(props.onClick) {
            event.stopPropagation();
        } else {
            return;
        }

        const {target, currentTarget} = event;
        const clickEvent = new UserEvent({ value, text, target, currentTarget }, event.nativeEvent)
        if(props.warning) {
            const actions = (props.warnPreference === 'no') ?  ['yes', 'no'] :  ['no', 'yes'];
            await prompt.warn(props.warning, {onAction: handleAction(clickEvent), actions: actions});
        } else if(props.confirmation) {
            const actions = (props.confirmPreference === 'no') ?  ['yes', 'no'] :  ['no', 'yes'];
            await prompt.confirm(props.confirmation, {onAction: handleAction(clickEvent), actions: actions});
        } else {
            await handleExecute(clickEvent);
        }
    };

    const handleAction = (event) => async ({action}) => {
        event.action = action;
        if(action === 'yes') {
            await handleExecute(event);
        }
    }

    const handleExecute = async event => {
        if(props.onClick) {
            if(delay || async) {
                setBusy(true);
                lang.delay(triggerClick, delay, {event}, props.onClick);
            } else {
                await triggerClick({event}, props.onClick);
            }
        }
    }

    const triggerClick = async ({event}, fn) => {
        try {
            await fn(event);
        } catch(error) {
            mounted.current && setBusy(false);
            await prompt.alert(error);
            return;
        }
        mounted.current && setBusy(false);
    }

    const handleMouseOver = event => {
        event.stopPropagation();
        setHover(true);
        // Material UI 的 Tooltip 需要 forward 這 onMouseOver & onMouseLeave
        props.onMouseOver && props.onMouseOver(event);
    }

    const handleMouseEnter = event => {
        event.stopPropagation();
        setHover(true);
        // Material UI 的 Tooltip 需要 forward 這 onMouseOver & onMouseLeave
        props.onMouseEnter && props.onMouseEnter(event);
    }

    const handleMouseOut = event => {
        event.stopPropagation();
        setHover(false);
    }

    const disabled = busy ? true : props.disabled;

    const className = clsx(classes.button, props.className);
    let element;
    // if(variant!=='icon' || (hover && lang.isNotNullOrUndefined(text))) {
    let arialLabel = i18n.translate(props["aria-label"] || title || (lang.isString(text) ? text : ''));

    if(variant!=='icon') {
        const startIcon = props.startIcon || (icon && (variant!=='icon') ? <Icon size={size}>{icon}</Icon> : null);
        const endIcon = props.endIcon && <Icon size={size}>{props.endIcon}</Icon>;
        let textNode;
        if(text) {
            textNode = lang.isString(text) ? <Typography noWrap={noWrap}>{i18n.translate(text)}</Typography> : text;
        } else {
            textNode = props.children;
        }
        element = (
            <MuiButton className={className} size={size} disabled={disabled} title={i18n.translate(title)} aria-label={arialLabel}
                       variant={variant==='icon' ? 'text' : variant} fullWidth={fullWidth}
                       startIcon={startIcon} endIcon={endIcon}
                       onClick={handleClick} >
                {textNode}
            </MuiButton>
        );
    } else {
        element = (
            <IconButton title={i18n.translate(title || text)} aria-label={arialLabel} className={className} disabled={disabled} edge={edge} size={size==='large' ? 'medium' : size}
                        onClick={handleClick} >
                <Icon size={size} badge={badge}>{icon}</Icon>
            </IconButton>
        );
    }
    return (
        <Box ref={ref} className={clsx("appfuse-button", classes.root)} display="inline"
             onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
             onMouseEnter={handleMouseEnter}
             // Material UI 的 Tooltip 需要 forward 這 onMouseOver & onMouseLeave
             onMouseLeave={props.onMouseLeave}>
            {element}
            {busy ? <CircularProgress color="secondary" className={classes.progress} variant="indeterminate" size={16} /> : null}
        </Box>
    );
});

Button.propTypes = {
    className: PropTypes.string,
    value: PropTypes.any,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    "aria-label": PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    color: PropTypes.oneOfType([
        PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
        PropTypes.string,
        PropTypes.object
    ]),
    backgroundColor: PropTypes.oneOfType([
        PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
        PropTypes.string,
        PropTypes.object
    ]),
    variant: PropTypes.oneOf(['contained', 'text', 'outlined', 'icon']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    edge: PropTypes.oneOf(['start', 'end', false]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    badge: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    delay: PropTypes.number,
    async: PropTypes.bool,
    confirmation: PropTypes.string,
    warning: PropTypes.string,
    confirmPreference: PropTypes.oneOf(['yes', 'no']),
    warnPreference: PropTypes.oneOf(['yes', 'no']),
    noWrap: PropTypes.bool
}

Button.defaultProps = {
    color: 'default',
    variant: 'text',
    delay: 0,
    async: true,
    confirmPreference: 'no',
    warnPreference: 'no',
    noWrap: false
}


export default Button;