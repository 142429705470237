import lang from "../../lib/appfuse-react/core/lang";
import cropApplication from "./cropApplication";

const cropApplicationDraftService = [{
    matcher: 'cropApplicationDraft',
    reply: config => {
        if(config.method==='post') {
            return [200, cropApplication[0]];
        } else {
            const {predicate} = config.params;
            let data;
            const selector = predicate?.node()?.selector?.();
            const children = predicate?.node()?.children?.();
            if(lang.isNotEmpty(selector)) {
                const value = predicate.node().expectation();
                if(lang.isEmpty(value)) {
                    return [200, cropApplication];
                }
                if(selector==='farmerName') {
                    const regexp = new RegExp(value.replaceAll('*', '.*'));
                    data = cropApplication.filter(crop => regexp.test(crop[selector]));
                } else {
                    data = cropApplication.filter(crop => crop[selector]===value);
                }
            } else if(lang.isNotEmpty(children)) {
                let tmp = cropApplication;
                for(let child of children) {
                    const value = child.expectation();
                    if(lang.isEmpty(value)) {
                        continue;
                    }
                    const selector = child.selector();
                    if(selector === 'farmerName') {
                        const regexp = new RegExp(value.replaceAll('*', '.*'));
                        tmp = tmp.filter(crop => regexp.test(crop[selector]));
                    } else {
                        tmp = tmp.filter(crop => crop[selector]===value);
                    }
                }
                data = tmp;
            } else {
                data = cropApplication;
            }
            return [200, data];
        }
    }
}, {
    matcher: /cropApplicationDraft\/(.*)/,
    reply: config => {
        const id = /cropApplicationDraft\/(.*)/.exec(config.url)[1];
        const data = cropApplication.find(crop => crop.id===id);
        return [200, data];
    }
}];

export default cropApplicationDraftService;