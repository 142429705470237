import {Paper as MuiPaper, useTheme} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import React from 'react';
import clsx from "clsx";
import palette from "../../core/palette";
import lang from "../../core/lang";
import useResponsive from "../../hook/useResponsive";

const useStyles = makeStyles(theme => ({
    root: {
        display: props => props.display,
        displayPrint: props => props.displayPrint,
        overflow: props => props.overflow,
        textOverflow: props => props.textOverflow,
        visibility: props => props.visibility,

        flexDirection: props => props.flexDirection,
        flexWrap: props => props.flexWrap,
        justifyContent: props => props.justifyContent,
        alignItems: props => props.alignItems,
        alignContent: props => props.alignContent,
        gap: props => props.gap,
        order: props => props.order,
        flexGrow: props => props.flexGrow,
        flexShrink: props => props.flexShrink,
        alignSelf: props => props.alignSelf,

        padding: props => props.padding,
        paddingTop: props => props.paddingTop,
        paddingRight: props => props.paddingRight,
        paddingBottom: props => props.paddingBottom,
        paddingLeft: props => props.paddingLeft,
        margin: props => props.margin,
        marginTop: props => props.marginTop,
        marginRight: props => props.marginRight,
        marginBottom: props => props.marginBottom,
        marginLeft: props => props.marginLeft,

        width: props => props.width,
        height: props => props.height,
        minWidth: props => props.minWidth,
        minHeight: props => props.minHeight,
        maxWidth: props => props.maxWidth,
        maxHeight: props => props.maxHeight,
        boxSizing: props => props.boxSizing,

        fontFamily: props => props.fontFamily,
        fontSize: props => props.fontSize,
        fontStyle: props => props.fontStyle,
        fontWeight: props => props.fontWeight,
        letterSpacing: props => props.letterSpacing,
        lineHeight: props => props.lineHeight,
        whiteSpace: props => props.whiteSpace,

        position: props => props.position,
        top: props => props.top,
        right: props => props.right,
        bottom: props => props.bottom,
        left: props => props.left,
        zIndex: props => props.zIndex,
        cursor: props => props.cursor,

        color: props => props.color,
        backgroundColor: props => props.backgroundColor || 'transparent',
        backgroundImage: props => props.backgroundImage && `url(${props.backgroundImage})`,
        backgroundSize: props => props.backgroundSize,
        backgroundRepeat: props => props.backgroundRepeat,
        opacity: props => props.opacity,

        borderRadius: props => props.borderRadius,

        pointerEvents: props => props.pointerEvents,

        "&:hover": {
            boxShadow: props => props.boxShadowOnHover,
        }
    }
}));

/**
 * 使用 named function 取代 anonymous arrow function 讓除錯訊息除了顯示 ForwardRef 外，也能夠顯示 named function 使用的名稱來辨識是由本元件發生的錯誤。
 */
const Paper = React.forwardRef(function Paper(props, ref) {
    const {component, elevation, square, variant, disabled, children,
        display, displayPrint, overflow, textOverflow, visibility,
        flexDirection, flexWrap, justifyContent, alignItems, alignContent, order, flexGrow, flexShrink, alignSelf,
        color, backgroundImage, backgroundSize, backgroundRepeat, backgroundOpacity,
        fullWidth, fullHeight, maxWidth, maxHeight, minWidth, minHeight, boxSizing,
        fontFamily, fontSize, fontStyle, fontWeight, letterSpacing, lineHeight, whiteSpace,
        position, zIndex, onClick, onKeyDown, onKeyPress, onBlur, onMouseOver, onMouseLeave, tabIndex} = props;
    const style = {...props.style};
    const theme = useTheme();
    const responsive = useResponsive();

    let backgroundColor = theme.palette.background[props.backgroundColor] || theme.palette[props.backgroundColor]?.main || props.backgroundColor;
    backgroundColor && backgroundOpacity && (backgroundColor = palette.color(backgroundColor, backgroundOpacity))
    const boxShadowOnHover = onClick ? theme.shadows[5] : undefined;

    const calcSpacing = (spacing) => lang.isNumber(spacing) ? `${theme.spacing(spacing)}px` : spacing;
    const gap = calcSpacing(props.gap);
    const padding = calcSpacing(props.padding);
    const paddingTop = calcSpacing(props.paddingTop || props.paddingY);
    const paddingRight = calcSpacing(props.paddingRight || props.paddingX);
    const paddingBottom = calcSpacing(props.paddingBottom || props.paddingY);
    const paddingLeft = calcSpacing(props.paddingLeft || props.paddingX);
    const margin = calcSpacing(props.margin);
    const marginTop = calcSpacing(props.marginTop || props.marginY);
    const marginRight = calcSpacing(props.marginRight || props.marginX);
    const marginBottom = calcSpacing(props.marginBottom || props.marginY);
    const marginLeft = calcSpacing(props.marginLeft || props.marginX);

    const calcBorderRadius = (radius) => lang.isNumber(radius) ? `${theme.shape.borderRadius * radius}px` : radius;
    const borderRadius = calcBorderRadius(props.borderRadius)

    const height = fullHeight ? '100%' : props.height;
    const width = fullWidth ? '100%' : props.width;
    const cursor = lang.isNullOrUndefined(props.cursor) ? (onClick ? 'pointer' : 'initial') : props.cursor;
    const pointerEvents = disabled ? 'none' : '';
    const opacity = lang.isNullOrUndefined(props.opacity) ? (props.disabled ? 0.7 : 1) : props.opacity;

    const top = responsive.getResponsiveValue(props.top);
    const right = responsive.getResponsiveValue(props.right);
    const bottom = responsive.getResponsiveValue(props.bottom);
    const left = responsive.getResponsiveValue(props.left);

    const classes = useStyles({display, displayPrint, overflow, textOverflow, visibility,
        flexDirection, flexWrap, justifyContent, alignItems, alignContent, gap, order, flexGrow, flexShrink, alignSelf,
        padding, paddingTop, paddingRight, paddingBottom, paddingLeft,
        margin, marginTop, marginRight, marginBottom, marginLeft,
        height, width, maxWidth, maxHeight, minWidth, minHeight, boxSizing,
        fontFamily, fontSize, fontStyle, fontWeight, letterSpacing, lineHeight, whiteSpace,
        position, top, right, bottom, left, zIndex,
        color, backgroundColor, backgroundImage, backgroundSize, backgroundRepeat, opacity,
        borderRadius,
        cursor, boxShadowOnHover, pointerEvents});

    return (
        <MuiPaper ref={ref} component={component} elevation={elevation} square={square} variant={variant} children={children}
                  onClick={onClick} onKeyDown={onKeyDown} onKeyPress={onKeyPress} onBlur={onBlur}
                  // Material UI 的 Tooltip 需要 forward 這 onMouseOver & onMouseLeave
                  onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}
                  tabIndex={tabIndex}
                  className={clsx(classes.root, props.className, 'appfuse-paper')} style={style}
                  aria-label={props["aria-label"]} role={props["role"]} aria-hidden={props["aria-hidden"]} aria-labelledby={props["aria-labelledby"]} aria-owns={props["aria-owns"]} />
    )
});

Paper.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    component: PropTypes.elementType,
    elevation: PropTypes.number,
    square: PropTypes.bool,
    variant: PropTypes.oneOf(['elevation', 'outlined']),

    // Display
    display: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    displayPrint: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    overflow: PropTypes.string,
    textOverflow: PropTypes.string,
    visibility: PropTypes.string,
    cursor: PropTypes.string,

    // Flexbox
    flexDirection: PropTypes.string,
    flexWrap: PropTypes.string,
    justifyContent: PropTypes.string,
    alignItems: PropTypes.string,
    alignContent: PropTypes.string,
    gap: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    order: PropTypes.number,
    flexGrow: PropTypes.number,
    flexShrink: PropTypes.number,
    alignSelf: PropTypes.string,

    // Palette
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    backgroundImage: PropTypes.string,
    backgroundSize: PropTypes.string,
    backgroundRepeat: PropTypes.string,
    backgroundOpacity: PropTypes.number,
    opacity: PropTypes.number,

    // Border
    borderRadius: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),

    // Spacing
    padding: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    paddingX: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    paddingY: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    paddingTop: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    paddingRight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    paddingBottom: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    paddingLeft: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    margin: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    marginX: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    marginY: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    marginTop: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    marginRight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    marginBottom: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    marginLeft: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),

    // Sizing
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    maxWidth: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    minWidth: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    maxHeight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    minHeight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    boxSizing: PropTypes.string,
    fullWidth: PropTypes.bool,
    fullHeight: PropTypes.bool,

    // Typography
    fontFamily: PropTypes.string,
    fontSize: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    fontStyle: PropTypes.string,
    fontWeight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    letterSpacing: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    lineHeight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    whiteSpace: PropTypes.string,

    // Position
    position: PropTypes.string,
    zIndex: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    top: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    right: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    bottom: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),
    left: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
    ]),

    // Event
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyPress: PropTypes.func,
    onBlur: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func,

    tabIndex: PropTypes.number,

    "role": PropTypes.string,
    "aria-label": PropTypes.string,
    "aria-hidden": PropTypes.string,
    "aria-labelledby": PropTypes.string,
    "aria-owns": PropTypes.string,
}

Paper.defaultProps = {
    component: 'div',
    elevation: 1,
    square: false,
    variant: 'elevation',
    backgroundColor: 'paper',
    backgroundSize: 'cover'
}

export default Paper;