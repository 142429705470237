import { mock } from "../../conf/context";

const registrationService = {
    requestToken: async (email) => {
        const response = await mock.get('registration/requestToken', {email});
        return response.ok;
    },
    verifyEmail: async (email, token) => {
        const response = await mock.get('registration/verifyEmail', {email, token});
        return response.ok;
    }
}

export default registrationService;