const farmer = [
    {
        "id": "1",
        "farmerNo": "CGA00150",
        "farmerName": "金鍂鑫",
        "gender": "male",
        "mobilePhone": "0912123321",
        "crop": true,
        "selfProcessing": true,
        "greenConservationLiaison": "王小保",
        "cropApplication": {
            "id":"1",
            "applicant":"金鍂鑫",
            "idNo": "A123456789",
            "farmName": "興興",
            "applicantType": "individual",
            "gender": "male",
            "birthday": "1979/03/23",
            "phone": "03-2912-1292",
            "contactPerson": "王大濃",
            "farmType": ["production"],
            "applyApprovedProcessing": true,
            "contactPersonPhone": "0912123321",
            "conservationTarget": "species2",
            "species": "青蛙",
            "category":"crop",
            "organicQualification": {
                "crop": {
                    "certificationBody": "001",

                },
                "processing": {
                    "certificationBody": "others",
                    "certificationBodyDesc": "Ａ單位",
                }
            },
            "type":"initial",
            "createdOn":"2021-05-12T08:20:10.000Z",
            "totalImplementationArea":1.1678,
            "nonGreenArea": 0.345,
            "fieldLands":[
                {
                    "id":"1",
                    "fieldNo":"F01",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"249",
                    "actualCultivatedArea":0.2968
                },
                {
                    "id":"2",
                    "fieldNo":"F02",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"246",
                    "actualCultivatedArea":0.0305
                },
                {
                    "id":"3",
                    "fieldNo":"F03",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"218",
                    "actualCultivatedArea":0
                },
                {
                    "id":"4",
                    "fieldNo":"F04",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"277",
                    "actualCultivatedArea":0.0719
                },
                {
                    "id":"5",
                    "fieldNo":"F05",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"278",
                    "actualCultivatedArea":0.1417
                },
                {
                    "id":"6",
                    "fieldNo":"F06",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"346",
                    "actualCultivatedArea":0.3405
                },
                {
                    "id":"7",
                    "fieldNo":"F07",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"329",
                    "actualCultivatedArea":0.2864
                }
            ],
            "fieldCrops":[
                {
                    "fieldNos":["F01","F02"],
                    "crops":"包心白菜、結球萵苣",
                    "variety":"高雄149",
                    "estimatedProductionPeriods":["1月","11月","12月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                },
                {
                    "fieldNos":["F03","F04","F05"],
                    "crops":"蘿蔔、胡蘿蔔",
                    "variety":"白玉蘿蔔",
                    "estimatedProductionPeriods":["1月","2月","10月","11月","12月"],
                    "plantingMethod":"seedling",
                    "seedSources":["selfSeeding","outsourcing"]
                },
                {
                    "fieldNos":["F03"],
                    "crops":"地瓜",
                    "variety":"台農 157",
                    "estimatedProductionPeriods":["6月","7月","8月","9月","10月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                }
            ],
            "confirmStatement1": true,
            "confirmStatement2": true,
            "confirmStatement3": true,
            "confirmStatement4": true,
            "confirmStatement5": true,
            "confirmStatement6": true
        },
        "certificates": {
            "content": [
                {
                    "category": "crop",
                    "no": "19-CN-0409-01",
                    "startDate": "20190409",
                    "endDate": "20220409",
                    "status": "approved",
                },
                {
                    "category": "selfProcessing",
                    "no": "20-PN-0513-01",
                    "startDate": "20200513",
                    "endDate": "20230513",
                    "status": "suspend",
                }
            ],
            "progress": [
                {
                    "category": "crop",
                    "current": "initial",
                    "next": "regular1"
                },
                {
                    "category": "selfProcessing",
                    "current": "regular2",
                    "next": "reEvaluation"
                }
            ]
        }
    },
    {
        "id": "2",
        "farmerNo": "CGA00151",
        "farmerName": "木林森",
        "gender": "male",
        "mobilePhone": "0959184321",
        "crop": true,
        "greenConservationLiaison": "王小保",
        "cropApplication": {
            "id":"2",
            "applicant":"木林森",
            "idNo": "A123456789",
            "farmName": "槮槮",
            "applicantType": "individual",
            "gender": "male",
            "birthday": "1979/03/23",
            "phone": "03-2912-1292",
            "contactPerson": "王大濃",
            "farmType": ["production"],
            "applyApprovedProcessing": true,
            "contactPersonPhone": "0912123321",
            "conservationTarget": "habitat1",
            "category":"crop",
            "type":"initial",
            "createdOn":"2021-03-12T08:20:10.000Z",
            "totalImplementationArea":1.1678,
            "nonGreenArea": 0.345,
            "fieldLands":[
                {
                    "id":"1",
                    "fieldNo":"F01",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"249",
                    "actualCultivatedArea":0.2968
                },
                {
                    "id":"2",
                    "fieldNo":"F02",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"246",
                    "actualCultivatedArea":0.0305
                },
                {
                    "id":"3",
                    "fieldNo":"F03",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"218",
                    "actualCultivatedArea":0
                },
                {
                    "id":"4",
                    "fieldNo":"F04",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"277",
                    "actualCultivatedArea":0.0719
                },
                {
                    "id":"5",
                    "fieldNo":"F05",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"278",
                    "actualCultivatedArea":0.1417
                },
                {
                    "id":"6",
                    "fieldNo":"F06",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"346",
                    "actualCultivatedArea":0.3405
                },
                {
                    "id":"7",
                    "fieldNo":"F07",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"329",
                    "actualCultivatedArea":0.2864
                }
            ],
            "fieldCrops":[
                {
                    "fieldNos":["F01","F02"],
                    "crops":"包心白菜、結球萵苣",
                    "variety":"高雄149",
                    "estimatedProductionPeriods":["1月","11月","12月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                },
                {
                    "fieldNos":["F03","F04","F05"],
                    "crops":"蘿蔔、胡蘿蔔",
                    "variety":"白玉蘿蔔",
                    "estimatedProductionPeriods":["1月","2月","10月","11月","12月"],
                    "plantingMethod":"seedling",
                    "seedSources":["selfSeeding","outsourcing"]
                },
                {
                    "fieldNos":["F03"],
                    "crops":"地瓜",
                    "variety":"台農 157",
                    "estimatedProductionPeriods":["6月","7月","8月","9月","10月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                }
            ],
            "confirmStatement1": true,
            "confirmStatement2": true,
            "confirmStatement3": true,
            "confirmStatement4": true,
            "confirmStatement5": true,
            "confirmStatement6": true
        },
        "certificates": {
            "content": [
                {
                    "category": "crop",
                    "no": "19-CN-0409-01",
                    "startDate": "20190409",
                    "endDate": "20220409",
                    "status": "applying",
                }
            ],
            "progress": [
                {
                    "category": "crop",
                    "current": "initial",
                    "next": "regular1"
                }
            ]
        }
    },
    {
        "id": "3",
        "farmerNo": "CGB00100",
        "farmerName": "水沝淼",
        "gender": "female",
        "mobilePhone": "0948212336",
        "selfProcessing": true,
        "greenConservationLiaison": "王小保",
        "cropApplication": {
            "id":"3",
            "applicant":"水沝淼",
            "idNo": "A123456789",
            "farmName": "曉曉",
            "applicantType": "individual",
            "gender": "male",
            "birthday": "1979/03/23",
            "phone": "03-2912-1292",
            "contactPerson": "王大濃",
            "farmType": ["production"],
            "applyApprovedProcessing": true,
            "contactPersonPhone": "0912123321",
            "conservationTarget": "species2",
            "species": "青蛙",
            "category":"crop",
            "type":"initial",
            "createdOn":"2021-05-12T08:20:10.000Z",
            "totalImplementationArea":1.1678,
            "nonGreenArea": 0.345,
            "fieldLands":[
                {
                    "id":"1",
                    "fieldNo":"F01",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"249",
                    "actualCultivatedArea":0.2968
                },
                {
                    "id":"2",
                    "fieldNo":"F02",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"246",
                    "actualCultivatedArea":0.0305
                },
                {
                    "id":"3",
                    "fieldNo":"F03",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"218",
                    "actualCultivatedArea":0
                },
                {
                    "id":"4",
                    "fieldNo":"F04",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"277",
                    "actualCultivatedArea":0.0719
                },
                {
                    "id":"5",
                    "fieldNo":"F05",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"278",
                    "actualCultivatedArea":0.1417
                },
                {
                    "id":"6",
                    "fieldNo":"F06",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"346",
                    "actualCultivatedArea":0.3405
                },
                {
                    "id":"7",
                    "fieldNo":"F07",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"329",
                    "actualCultivatedArea":0.2864
                }
            ],
            "fieldCrops":[
                {
                    "fieldNos":["F01","F02"],
                    "crops":"包心白菜、結球萵苣",
                    "variety":"高雄149",
                    "estimatedProductionPeriods":["1月","11月","12月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                },
                {
                    "fieldNos":["F03","F04","F05"],
                    "crops":"蘿蔔、胡蘿蔔",
                    "variety":"白玉蘿蔔",
                    "estimatedProductionPeriods":["1月","2月","10月","11月","12月"],
                    "plantingMethod":"seedling",
                    "seedSources":["selfSeeding","outsourcing"]
                },
                {
                    "fieldNos":["F03"],
                    "crops":"地瓜",
                    "variety":"台農 157",
                    "estimatedProductionPeriods":["6月","7月","8月","9月","10月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                }
            ],
            "confirmStatement1": true,
            "confirmStatement2": true,
            "confirmStatement3": true,
            "confirmStatement4": true,
            "confirmStatement5": true,
            "confirmStatement6": true
        },
        "certificates": {
            "content": [
                {
                    "category": "selfProcessing",
                    "no": "20-PN-0513-01",
                    "startDate": "20200513",
                    "endDate": "20230513",
                    "status": "suspend",
                }
            ],
            "progress": [
                {
                    "category": "selfProcessing",
                    "current": "regular2",
                    "next": "reEvaluation"
                }
            ]
        }
    },
    {
        "id": "4",
        "farmerNo": "CGB00101",
        "farmerName": "火炎焱",
        "gender": "female",
        "mobilePhone": "0940712727",
        "selfProcessing": true,
        "approvedProcessing": true,
        "greenConservationLiaison": "王小保",
        "cropApplication": {
            "id":"4",
            "applicant":"火炎焱",
            "idNo": "A123456789",
            "farmName": "研研",
            "applicantType": "individual",
            "gender": "male",
            "birthday": "1979/03/23",
            "phone": "03-2912-1292",
            "contactPerson": "王大濃",
            "farmType": ["production"],
            "applyApprovedProcessing": true,
            "contactPersonPhone": "0912123321",
            "conservationTarget": "species2",
            "species": "青蛙",
            "category":"crop",
            "type":"initial",
            "createdOn":"2021-05-12T08:20:10.000Z",
            "totalImplementationArea":1.1678,
            "nonGreenArea": 0.345,
            "fieldLands":[
                {
                    "id":"1",
                    "fieldNo":"F01",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"249",
                    "actualCultivatedArea":0.2968
                },
                {
                    "id":"2",
                    "fieldNo":"F02",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"246",
                    "actualCultivatedArea":0.0305
                },
                {
                    "id":"3",
                    "fieldNo":"F03",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"218",
                    "actualCultivatedArea":0
                },
                {
                    "id":"4",
                    "fieldNo":"F04",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"277",
                    "actualCultivatedArea":0.0719
                },
                {
                    "id":"5",
                    "fieldNo":"F05",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"278",
                    "actualCultivatedArea":0.1417
                },
                {
                    "id":"6",
                    "fieldNo":"F06",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"346",
                    "actualCultivatedArea":0.3405
                },
                {
                    "id":"7",
                    "fieldNo":"F07",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"329",
                    "actualCultivatedArea":0.2864
                }
            ],
            "fieldCrops":[
                {
                    "fieldNos":["F01","F02"],
                    "crops":"包心白菜、結球萵苣",
                    "variety":"高雄149",
                    "estimatedProductionPeriods":["1月","11月","12月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                },
                {
                    "fieldNos":["F03","F04","F05"],
                    "crops":"蘿蔔、胡蘿蔔",
                    "variety":"白玉蘿蔔",
                    "estimatedProductionPeriods":["1月","2月","10月","11月","12月"],
                    "plantingMethod":"seedling",
                    "seedSources":["selfSeeding","outsourcing"]
                },
                {
                    "fieldNos":["F03"],
                    "crops":"地瓜",
                    "variety":"台農 157",
                    "estimatedProductionPeriods":["6月","7月","8月","9月","10月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                }
            ],
            "confirmStatement1": true,
            "confirmStatement2": true,
            "confirmStatement3": true,
            "confirmStatement4": true,
            "confirmStatement5": true,
            "confirmStatement6": true
        },
        "certificates": {
            "content": [
                {
                    "category": "selfProcessing",
                    "no": "20-PN-0513-01",
                    "startDate": "20200513",
                    "endDate": "20230513",
                    "status": "suspend",
                },
                {
                    "category": "approvedProcessing",
                    "no": "19-CN-0409-01",
                    "startDate": "20190409",
                    "endDate": "20220409",
                    "status": "approved",
                }
            ],
            "progress": [
                {
                    "category": "selfProcessing",
                    "current": "regular2",
                    "next": "reEvaluation"
                },
                {
                    "category": "approvedProcessing",
                    "current": "initial",
                    "next": "regular1"
                }
            ]
        }
    },
    {
        "id": "5",
        "farmerNo": "CGB00102",
        "farmerName": "土圭垚",
        "gender": "male",
        "mobilePhone": "0949512727",
        "crop": true,
        "approvedProcessing": true,
        "greenConservationLiaison": "王小保",
        "cropApplication": {
            "id":"5",
            "applicant":"土圭垚",
            "idNo": "A123456789",
            "farmName": "槻硅",
            "applicantType": "individual",
            "gender": "male",
            "birthday": "1979/03/23",
            "phone": "03-2912-1292",
            "contactPerson": "王大濃",
            "farmType": ["production"],
            "applyApprovedProcessing": true,
            "contactPersonPhone": "0912123321",
            "conservationTarget": "species2",
            "species": "青蛙",
            "category":"crop",
            "type":"initial",
            "createdOn":"2021-05-12T08:20:10.000Z",
            "totalImplementationArea":1.1678,
            "nonGreenArea": 0.345,
            "fieldLands":[
                {
                    "id":"1",
                    "fieldNo":"F01",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"249",
                    "actualCultivatedArea":0.2968
                },
                {
                    "id":"2",
                    "fieldNo":"F02",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"246",
                    "actualCultivatedArea":0.0305
                },
                {
                    "id":"3",
                    "fieldNo":"F03",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"218",
                    "actualCultivatedArea":0
                },
                {
                    "id":"4",
                    "fieldNo":"F04",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"277",
                    "actualCultivatedArea":0.0719
                },
                {
                    "id":"5",
                    "fieldNo":"F05",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"278",
                    "actualCultivatedArea":0.1417
                },
                {
                    "id":"6",
                    "fieldNo":"F06",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"346",
                    "actualCultivatedArea":0.3405
                },
                {
                    "id":"7",
                    "fieldNo":"F07",
                    "city":"64",
                    "district":"美濃區",
                    "landLot":"廣林段",
                    "landSerialNo":"329",
                    "actualCultivatedArea":0.2864
                }
            ],
            "fieldCrops":[
                {
                    "fieldNos":["F01","F02"],
                    "crops":"包心白菜、結球萵苣",
                    "variety":"高雄149",
                    "estimatedProductionPeriods":["1月","11月","12月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                },
                {
                    "fieldNos":["F03","F04","F05"],
                    "crops":"蘿蔔、胡蘿蔔",
                    "variety":"白玉蘿蔔",
                    "estimatedProductionPeriods":["1月","2月","10月","11月","12月"],
                    "plantingMethod":"seedling",
                    "seedSources":["selfSeeding","outsourcing"]
                },
                {
                    "fieldNos":["F03"],
                    "crops":"地瓜",
                    "variety":"台農 157",
                    "estimatedProductionPeriods":["6月","7月","8月","9月","10月"],
                    "plantingMethod":"seed",
                    "seedSources":["selfSeeding"]
                }
            ],
            "confirmStatement1": true,
            "confirmStatement2": true,
            "confirmStatement3": true,
            "confirmStatement4": true,
            "confirmStatement5": true,
            "confirmStatement6": true
        },
        "certificates": {
            "content": [
                {
                    "category": "crop",
                    "no": "19-CN-0409-01",
                    "startDate": "20190409",
                    "endDate": "20220409",
                    "status": "approved",
                },
                {
                    "category": "selfProcessing",
                    "no": "20-PN-0513-01",
                    "startDate": "20200513",
                    "endDate": "20230513",
                    "status": "suspend",
                }
            ],
            "progress": [
                {
                    "category": "crop",
                    "current": "initial",
                    "next": "regular1"
                },
                {
                    "category": "selfProcessing",
                    "current": "regular2",
                    "next": "reEvaluation"
                }
            ]
        }
    }
];

export default farmer;