class Hal {
    convertToResources(data) {
        // might not have _embedded.
        const embedded = data?.['_embedded'] || {}
        const content = Object.values(embedded)[0] || [];
        let resources = content.map(item => this.convertToResource(item));
        const links = data['_links'] || {};
        for(const [key, value] of Object.entries(links)) {
            resources[key] = value?.href;
        }
        const page = data['page'] || {};
        for(const [key, value] of Object.entries(page)) {
            resources[key] = value;
        }
        return resources;
    }

    convertToResource(data) {
        const resource = {...data};
        delete resource['_links'];
        const links = data['_links'] || {};
        for(const [key, value] of Object.entries(links)) {
            resource[key] = value?.href;
        }
        return resource;
    }
}

const hal = new Hal();

export default hal;