import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import {makeStyles} from "@material-ui/core";
import {useContext} from "react";
import {AccordionContext} from "./index";
import colorUtils from "../../core/palette";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: props => props.backgroundColor,
        color: props => props.textColor,
        borderBottom: '1px solid',
        borderBottomColor: props => props.backgroundColor,
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
}));

function AccordionSummary(props) {
    const theme = useContext(AccordionContext);
    const {color = theme.color} = props;
    const backgroundColor = color?.main || color;
    const textColor = color?.contrastText || colorUtils.invert(backgroundColor, true);
    const classes = useStyles({backgroundColor, textColor});
    return (
        <MuiAccordionSummary {...props} classes={classes} />
    )
}

export default AccordionSummary;