import usePrompt from "../../lib/appfuse-react/hook/usePrompt";
import {useState} from "react";
import {Dialog, DialogContent, Divider, Grid, Typography} from "@material-ui/core";
import Box from "../../lib/appfuse-react/component/Box";
import i18n from "../../lib/appfuse-react/core/i18n";
import Form from "../../lib/appfuse-react/component/Form";
import TextField from "../../lib/appfuse-react/component/TextField";
import Button from "../../lib/appfuse-react/component/Button";
import lang from "../../lib/appfuse-react/core/lang";
import UserEvent from "../../lib/appfuse-react/core/UserEvent";

function VerificationForm({onVerify, onResend, onCancel}) {
    const prompt = usePrompt();
    const [value, setValue] = useState({});

    const handleChange = (event) => {
        const {value} = event;
        setValue(value);
    };

    const handleVerify = () => {
        const {pin} = value;
        if(lang.isEmpty(pin)) {
            prompt.confirm('Please enter the verification code.');
            return;
        }
        onVerify(new UserEvent({pin}));
    };

    const handleResend = () => {
        onResend();
    }

    const handleCancel = () => {
        onCancel();
    };

    return (
        <Dialog open={true} maxWidth='xs' fullWidth>
            <Box paddingTop={2} paddingBottom={1}>
                <Typography align='center' variant='h6'>{i18n.translate('Verification Code')}</Typography>
            </Box>
            <Divider variant='middle'/>
            <DialogContent>
                <Box fullHeight fullWidth overflow='auto' paddingY={2} paddingX={2}>
                    <Box marginBottom={1}>
                        <Typography style={{whiteSpace: 'pre-wrap'}} variant='body2' color='textSecondary'>
                            {i18n.translate("An email with a verification code has been sent to you.")}
                        </Typography>
                        <Typography style={{whiteSpace: 'pre-wrap'}} variant='body2' color='textSecondary'>
                            {i18n.translate("Please enter the verification code to finish signing in.")}
                        </Typography>
                    </Box>
                    <Form value={value} onChange={handleChange} onSubmit={handleVerify}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {/*驗證碼*/}
                                <TextField name='pin' label='Verification Code' variant='outlined' fullWidth required />
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button text="Resend verification code." color="primary" size="small" onClick={handleResend} />
                                    <Button color="primary" icon="send" variant="icon"  size="small" onClick={handleResend} />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Button icon='done' text='OK' variant='contained' color='primary' fullWidth onClick={handleVerify}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Button icon='close' text='Cancel' variant='contained' color='primary' fullWidth onClick={handleCancel}/>
                            </Grid>
                        </Grid>
                    </Form>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default VerificationForm;