import i18n from "../core/i18n";
import string from "../core/string";
import lang from "../core/lang";

class ApplicationError extends Error {
    #message
    #format
    #status
    #params
    #cause

    constructor(message, {format, params, status, cause} = {}) {
        super(message);
        this.#message = message;
        this.#format = format;
        this.#params = params;
        this.#status = status;
        this.#cause = cause;
    }
    get status() {
        return this.#status;
    }
    get message() {
        return this.#message;
    }
    get format() {
        return this.#format;
    }
    get params() {
        return this.#params;
    }
    get cause() {
        return this.#cause;
    }

    toString() {
        const text = i18n.translate(lang.isNotEmpty(this.format) ? this.format : this.message);
        return this.interpolate(text, this.params);
    }

    interpolate(template, params) {
        let text = i18n.translate(template);
        return string.format(text, params);
    }

    static of(value) {
        let error;
        if(lang.isNullOrUndefined(value)) {
            error = value;
        } else if(lang.isObject(value)) {
            const { message } = value;
            error = new ApplicationError(message, value);
        } else {
            throw new Error(`Type is not supported. Supported types are JSON object, but receive: ${value}`);
        }
        return error;
    }
}

export default ApplicationError;