import {createSlice} from "@reduxjs/toolkit";
import {json} from "../../lib/appfuse-react/core/json";

const name = 'util';

const initialState = {
    county: [],
    region: [],
};

const slice = createSlice({
    name,
    initialState,
    reducers: {
        setCounty(state, action) {
            state.county = json.toJSON(action.payload);
        },
        setRegion(state, action) {
            state.region = json.toJSON(action.payload);
        }
    }
});

export const { setCounty, setRegion } = slice.actions;

export const selectCounty = state => json.fromJSON(state[name].county);
export const selectRegion = state => json.fromJSON(state[name].region);

const utilReducer = slice.reducer;
export default utilReducer;