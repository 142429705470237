import time from './time';
import fileIO from './fileIO';
import lang from "./lang";
import ajax from "./ajax";
import logger from "./logger";

const CONTENTDISPOSITIONPATTERN = /attachment; filename\*=UTF-8''(.+)$/;

const log = logger.getLogger('downloader');

class Downloader {
    data = new Map();

    constructor() {
        if(!Downloader.instance) {
            Downloader.instance = this;
        }
        return Downloader.instance;
    }

    async download(source, params, filename) {
        let file;

        if(fileIO.isFileLike(source)) {
            if(source instanceof File) {
                file = source;
            } else {
                if(source.bytes) {
                    file = await fileIO.decode(source);
                } else {
                    file = await this.fetch(source.href);
                }
            }
        } else if(source instanceof Response) {
            file = await this.readAsFile(source, filename);
        } else {
            file = await this.fetch(source, params);
        }

        return file;
    }

    async save(source, params, filename) {
        const file = await this.download(source, params, filename);

        await fileIO.save(file, filename);
    }

    getCacheItem(href) {
        return this.data.get(href);
    }

    async load(descriptor) {
        if(descriptor instanceof File || descriptor.bytes) {
            return descriptor;
        } else {
            if(lang.isNullOrUndefined(descriptor.href)) {
                throw Error(`Can not load the FileDescriptor without href.`);
            }
            return await this.fetch(descriptor.href);
        }
    }

    async fetch(href, params) {
        const cacheItem = this.getCacheItem(href);

        // if cached file has no tag & params and last fetched time < 30 minutes, then return the cached file.
        // if(cacheItem && lang.isNullOrUndefined(cacheItem.etag, params) &&
        //     time.between(cacheItem.lastFetchedDate, time.now()).toMinutes()<30) {
        //     return cacheItem.file;
        // }

        const options = {
            headers: {}
        };

        if(cacheItem?.etag) {
            options.headers['If-None-Match'] = `${cacheItem.etag}`;
        }

        // const response = await ajax.get(href, {}, options);
        const response = await this.get(href, params, options);
        if(!response.ok) {
            if(response.status===304) {
                log.debug(`Cache hit: ${href}`);
                return cacheItem.file;
            } else if(response.status===404) {
                this.cache(href, null);
                return null;
            } else if(response.status===403){
                throw new Error(`${response.statusText || 'You are not authorized.'}`);
            } else {
                throw new Error(`Unrecognized response(${response.status}): ${response.statusText}`);
            }
        }

        const etag = response.headers.has('ETag') ? response.headers.get('ETag') : null;
        const file = await this.readAsFile(response);
        this.cache(href, file, etag);

        return file;
    }

    async fetchDescriptor(href) {
        const cacheItem = this.getCacheItem(href);

        // if cached file has no tag and last fetched time < 30 minutes, then return the cached file.
        // if(cacheItem && lang.isNullOrUndefined(cacheItem.etag) &&
        //     time.between(cacheItem.lastFetchedDate, time.now()).toMinutes()<30) {
        //     return cacheItem.file;
        // }


        // const response = await ajax.head(href, {}, options);
        const response = await this.head(href);
        const descriptor = this.readAsDescriptor(response);

        return descriptor;
    }

    async head(url, options = {}) {
        options.method = 'HEAD';
        options.headers || (options.headers = {});
        if(ajax.authorization) {
            options.headers['Authorization'] = ajax.authorization;
        }
        const href = ajax.resolve(url);
        return await fetch(href, options);
    }

    async get(url, params = {}, options = {}) {
        options.method = 'GET';
        options.headers || (options.headers = {});
        if(ajax.authorization) {
            options.headers['Authorization'] = ajax.authorization;
        }
        if(ajax.captcha) {
            options.headers['Captcha'] = ajax.captcha;
        }

        const searchParams = new URLSearchParams();
        for (const [key, value] of Object.entries(params)) {
            if (lang.isDate(value)) {
                searchParams.append(key, time.toISOString(value));
            } else if(value?.toString() != null) {
                searchParams.append(key, value);
            }
        }

        const href = ajax.resolve(url) + '?' + searchParams.toString();

        const response = await fetch(href, options);

        if(response.headers.has('Captcha')) {
            ajax.captcha = response.headers.get('Captcha');
        }
        if(response.headers.has('Authorization')) {
            ajax.authorization = response.headers.get('Authorization');
        }

        return response
    }


    async readAsFile(response) {
        const headers = response.headers;

        const type = headers.get('Content-Type');
        const size = parseInt(headers.get('Content-Length'));
        const lastModified= headers.get('Last-Modified');
        const lastModifiedDate = Date.parse(headers.get('Last-Modified'));
        const name = this.readFilename(response) || '';
        const blob = await response.blob();

        return new File([blob], name, { name, type, size, lastModified, lastModifiedDate });
    }

    async readAsDescriptor(response) {
        const headers = response.headers;

        const type = headers.get('Content-Type');
        const size = parseInt(headers.get('Content-Length'));
        const lastModified= headers.get('Last-Modified');
        const lastModifiedDate = Date.parse(headers.get('Last-Modified'));
        const url = response.url;

        const contentDisposition = headers.get('Content-Disposition');
        let name = null;
        if(contentDisposition) {
            const matches = CONTENTDISPOSITIONPATTERN.exec(contentDisposition);
            if(matches) {
                const encodedFilename = matches[1];
                name = decodeURIComponent(encodedFilename);
            }
        } else {
            const mediaType = response.headers.get('Content-Type');
            const extension = fileIO.getExtensionByMediaType(mediaType);
            if(url.endsWith(extension)) {
                const matches =/.*\/(.*)$/.exec(url);
                if(matches) {
                    const encodedFilename = matches[1];
                    name = decodeURIComponent(encodedFilename);
                }
            }
        }

        return { name, type, size, lastModified, lastModifiedDate, url };
    }

    readFilename(response) {
        const headers = response.headers;
        const contentDisposition = headers.get('Content-Disposition');
        let filename = null;
        if(contentDisposition) {
            const matches = CONTENTDISPOSITIONPATTERN.exec(contentDisposition);
            if(matches) {
                const encodedFilename = matches[1];
                filename = decodeURIComponent(encodedFilename);
            }
        } else {
            const mediaType = response.headers.get('Content-Type');
            const extension = fileIO.getExtensionByMediaType(mediaType);
            const url = response.url;
            if(url.endsWith(extension)) {
                const matches =/.*\/(.*)$/.exec(url);
                if(matches) {
                    const encodedFilename = matches[1];
                    filename = decodeURIComponent(encodedFilename);
                }
            }
        }
        return filename;
    }


    cache(href, file, etag) {
        this.data.set(href, {
            file,
            etag,
            lastFetchedDate: time.now()
        })
    }
}

const downloader = new Downloader();

export default downloader;