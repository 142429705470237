import React from "react";
import * as PropTypes from "prop-types";
import i18n from "../../lib/appfuse-react/core/i18n";
import {Paper} from "@material-ui/core";
import UserEvent from "../../lib/appfuse-react/core/UserEvent";
import LaunchFolder from "./LaunchFolder";
import {makeStyles} from "@material-ui/core/styles";
import LaunchItem from "./LaunchItem";
import palette from "../../lib/appfuse-react/core/palette";

const useStyles = makeStyles(theme => ({
    root: {
        width: '20rem'
    }
}));

function Launchpad(props) {
    const classes = useStyles();
    const {data} = props;

    const map = new Map();
    data.forEach((datum, index) => {
        const {category = 'others'} = datum;
        if(!map.has(category)) {
            map.set(category, []);
        }
        map.get(category).push(datum);
    });

    const folders = [];
    const colors = palette.Scheme.Categorical;
    let index = 0;
    map.forEach((value, key) => {
        const color = colors[index++];
        const title = i18n.translate(key);
        const items = value.map((item, index) => <LaunchItem {...item} color={color} onClick={props.onClick} key={index} />);
        const folder = <LaunchFolder title={title} color={color} children={items} key={key} />
        folders.push(folder);
    });

    return (
        <Paper className={classes.root}>
            {folders}
        </Paper>
    );
}

Launchpad.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    onClick: PropTypes.func
};

export default Launchpad;