const cropSampling = [
    {
        "id": "1",
        "farmer": "CGA00150 金鍂鑫",
        "farmerNo": "CGA00150",
        "farmerName": "金鍂鑫",
        "reason": "隨機",
        "appNo": "CGA00150",
        "createdOn": "2021-09-01T08:20:10.000Z"
    },
    {
        "id": "2",
        "farmer": "CGA00151 木林森",
        "farmerNo": "CGA00151",
        "farmerName": "木林森",
        "reason": "隨機",
        "createdOn": "2021-09-10T08:20:10.000Z"
    },
    {
        "id": "3",
        "farmer": "CGB00100 水沝淼",
        "farmerNo": "CGB00100",
        "farmerName": "水沝淼",
        "reason": "隨機",
        "appNo": "CGA00152",
        "createdOn": "2021-09-20T08:20:10.000Z"
    },
    {
        "id": "4",
        "farmer": "CGB00101 火炎焱",
        "farmerNo": "CGB00101",
        "farmerName": "火炎焱",
        "reason": "產品不良",
        "createdOn": "2021-09-30T08:20:10.000Z"
    },
    {
        "id": "5",
        "farmer": "CGB00102 土圭垚",
        "farmerNo": "CGB00102",
        "farmerName": "土圭垚",
        "reason": "隨機",
        "appNo": "CGA00154",
        "createdOn": "2021-09-15T08:20:10.000Z"
    }
];

export default cropSampling;