import moment from 'moment';
import lang from "./lang";

// +0800; +08:00; +08;
const ISO8601_REGEXP = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})(Z|(\+|-)\d{2}(:?\d{2})?)/;

const ISO8601_PATTERN = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

class Time {
    pattern = undefined;

    init(options = {}) {
        const {pattern} = options
        this.pattern = pattern;
    }

    now() {
        return new Date();
    }

    between(startTime, endTime) {
        return Duration.between(startTime, endTime);
    }

    duration(amount, unit) {
        return Duration.of(amount, unit);
    }

    add(time, duration) {
        return new Date(time.getTime() + duration.toMillis());
    }

    addDays(time, days) {
        let duration = Duration.of(days, TimeUnit.DAY);
        return this.add(time, duration);
    }

    addHours(time, hours) {
        let duration = Duration.of(hours, TimeUnit.HOUR);
        return this.add(time, duration);
    }

    addMinutes(time, minutes) {
        let duration = Duration.of(minutes, TimeUnit.MINUTE);
        return this.add(time, duration);
    }

    addSeconds(time, seconds) {
        let duration = Duration.of(seconds, TimeUnit.SECOND);
        return this.add(time, duration);
    }

    of(year, month, dayOfMonth, hour, minute, second, milliOfSecond) {
        if(year && year instanceof Date) return new Date(year.getTime());

        month || (month = 0);
        dayOfMonth || (dayOfMonth = 1);
        hour || (hour = 0);
        minute || (minute = 0);
        second || (second = 0);
        milliOfSecond || (milliOfSecond = 0);
        return new Date(year, month, dayOfMonth, hour, minute, second, milliOfSecond);
    }

    daysInMonth(year, month) {
        let endOfMonth = new Date(year, month, 0);
        return endOfMonth.getDate();
    }

    format(value, pattern) {
        if(lang.isNullOrUndefined(value)) return '';

        let text;

        if(pattern) {
            if(pattern==='date') {
                text = lang.has(this.pattern, 'date') ? moment(value).format(this.pattern.date) : this.toLocaleDateString(value);
            } else if(pattern==='time') {
                text = lang.has(this.pattern, 'time') ? moment(value).format(this.pattern.time) : this.toLocaleTimeString(value);
            } else if(pattern==='datetime')  {
                text = lang.has(this.pattern, 'datetime') ? moment(value).format(this.pattern.datetime) : this.toLocaleString(value);
            } else if(lang.has(this.pattern, pattern)) {
                text = moment(value).format(this.pattern[pattern]);
            } else {
                text = moment(value).format(pattern);
            }
        } else {
            text = lang.isString(this.pattern) ?  moment(value).format(pattern) : this.toLocaleString(value);
        }
        return text;
    }

    parse(text, pattern) {
        return pattern ? moment(text, pattern).toDate() : Date.parse(text);
    }

    compare(a, b) {
        const aTime = a?.getTime() || 0;
        const bTime = b?.getTime() || 0;

        return aTime-bTime;
    }

    round(time) {
        const date = time;
        let timeStamp = date.getTime();
        timeStamp -= timeStamp % (24 * 60 * 60 * 1000);
        timeStamp += date.getTimezoneOffset() * 60 * 1000;
        return new Date(timeStamp);
    }

    toISOString(value) {
        return this.format(value, ISO8601_PATTERN);
    }

    isISODateString(value) {
        return ISO8601_REGEXP.test(value);
    }

    toLocaleString(value) {
        return value.toLocaleString();
    }

    toLocaleDateString(value) {
        return value.toLocaleDateString();
    }

    toLocaleTimeString(value) {
        return value.toLocaleTimeString();
    }r
}

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;


class Duration {
    static between(startTime, endTime) {
        let diff = endTime.getTime() - startTime.getTime();
        return new Duration(diff);
    }

    static of(amount, unit) {
        return new Duration(amount, unit);
    }

    amount = 0;

    constructor(amount, unit) {
        unit || (unit = TimeUnit.MILLISECOND);

        let millis;
        if(unit===TimeUnit.DAY) {
            millis = amount * DAY;
        } else if(unit===TimeUnit.HOUR) {
            millis = amount * HOUR;
        } else if(unit===TimeUnit.MINUTE) {
            millis = amount * MINUTE;
        } else if(unit===TimeUnit.SECOND) {
            millis = amount * SECOND;
        } else {
            millis = amount;
        }

        this.amount = millis;
    }

    toDays() {
        return this.toMillis() / DAY;
    }

    toHours() {
        return this.toMillis() / HOUR;
    }

    toMinutes() {
        return this.toMillis() / MINUTE;
    }

    toSeconds() {
        return this.toMillis() / SECOND;
    }

    toMillis() {
        return this.amount;
    }

    subtractDays(days) {
        let millis = this.toMillis() - days * DAY;
        return new Duration(millis);
    }

    days() {
        return Math.floor(this.toDays());
    }

    hours() {
        let days = Math.floor(this.toDays());
        let duration = new Duration(this.toMillis() - days * DAY);
        return Math.floor(duration.toHours());
    }

    minutes() {
        let hours = Math.floor(this.toHours());
        let duration = new Duration(this.toMillis() - hours * HOUR);
        return Math.floor(duration.toMinutes());
    }

    seconds() {
        const minutes = Math.floor(this.toMinutes());
        let duration = new Duration(this.toMillis() - minutes * MINUTE);
        return Math.floor(duration.toSeconds());
    }

    add(duration) {
        return new Duration(this.amount + duration.amount);
    }

    addDays(days) {
        let duration = Duration.of(days, TimeUnit.DAY);
        return this.add(duration);
    }

    addHours(hours) {
        let duration = Duration.of(hours, TimeUnit.HOUR);
        return this.add(duration);
    }

    addMinutes(minutes) {
        let duration = Duration.of(minutes, TimeUnit.MINUTE);
        return this.add(duration);
    }

    addSeconds(seconds) {
        let duration = Duration.of(seconds, TimeUnit.SECOND);
        return this.add(duration);
    }

    equals(another) {
        return (another instanceof Duration) ? (this.toMillis() === another?.toMillis()) : false;
    }
}

const TimeUnit = {
    DAY: 'day',
    HOUR: 'hour',
    MINUTE: 'minute',
    SECOND: 'second',
    MILLISECOND: 'millisecond'
};

let time  = new Time();

export {Duration, TimeUnit};

export default time;