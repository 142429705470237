import UserEvent from "../../lib/appfuse-react/core/UserEvent";
import {Paper, Typography} from "@material-ui/core";
import React from "react";
import * as PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import palette from "../../lib/appfuse-react/core/palette";
import i18n from "../../lib/appfuse-react/core/i18n";
import Image from "../../lib/appfuse-react/component/Image";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1.5),
        gap: theme.spacing(1),
        height: '100%',
        cursor: 'pointer',
        backgroundColor: props => props.backgroundColor,
        '&:hover': {
            backgroundColor: props => props.hoverColor,
        },
    },
    icon: {
        width: '3rem',
        height: '3rem'
    },
    text: {
        fontSize: '0.95rem',
        lineHeight: 1.2,
        color: props => props.textColor
    }
}));

function LaunchItem(props) {
    const {text, icon, color} = props;
    const backgroundColor = color?.light || palette.color(color, 0.1);
    const hoverColor = color?.light || palette.color(color, 0.15);
    const textColor = color?.contrastText || palette.darker(color, 2);
    const classes = useStyles({backgroundColor, hoverColor, textColor});

    const onClick = (event => {
        props.onClick && props.onClick(new UserEvent({...props}, event));
    });
    return (
        <Paper className={classes.root} onClick={onClick}>
            <Image className={classes.icon} value={icon}/>
            <Typography className={classes.text} align="center" >{i18n.translate(text)}</Typography>
        </Paper>
    );
}

LaunchItem.propTypes = {
    text: PropTypes.string.isRequired,
    color: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    icon: PropTypes.string,
    onClick: PropTypes.func
}

LaunchItem.defaultProps = {
    icon: '/image/application/webpage.svg'
}

export default LaunchItem;