import ajax from "./ajax";
import time from "./time";
import lang from "./lang";

const COLLECTION = 'codes';

class Code {
    #dictionaries = new Map()

    translate(type, value) {
        if(!this.validate(type)) {
            this.fetch(type).catch();
        }
        const dictionary = this.getDictionary(type);
        let text;
        if(dictionary.has(value)) {
            const item = dictionary.get(value);
            text = lang.isObject(item) ? item.text : item;
        } else {
            text = value;
        }
        return text;
    }

    async assert(...types) {
        for(const type of types) {
            await code.fetch(type);
        }
    }

    async fetch(type) {
        if(this.validate(type)) {
            return this.get(type);
        }
        const response = await ajax.get(`${COLLECTION}/${type}`);
        const dictionary = this.getDictionary(type);
        const data = response.data;
        for(const item of data) {
            dictionary.set(item.value, item);
        }
        dictionary.fetchTime = time.now();
        return data;
    }

    getDictionary(type) {
        if(!this.has(type)) {
            this.#dictionaries.set(type, new Dictionary(type));
        }
        return this.#dictionaries.get(type);
    }

    has(type) {
        return this.#dictionaries.has(type);
    }

    get(type) {
        const dictionary = this.getDictionary(type);
        return dictionary.toArray();
    }

    validate(type) {
        let test = false;
        if(this.has(type)) {
            const dictionary = this.getDictionary(type);
            if(dictionary.fetchTime && (time.between(this.getDictionary(type).fetchTime, time.now()).toMinutes()<30)) {
                test = true;
            }
        }
        return test;
    }
}


class Dictionary {
    #type
    #fetchTime
    #translation = new Map()

    constructor(type) {
        this.#type = type;
    }

    get type() {
        return this.#type;
    }

    get fetchTime() {
        return this.#fetchTime;
    }

    set fetchTime(fetchTime) {
        this.#fetchTime = fetchTime;
    }

    set(value, item) {
        this.#translation.set(value, item);
    }

    get(value) {
        return this.#translation.get(value);
    }

    has(value) {
        return this.#translation.has(value);
    }

    toArray() {
        const data = [];
        for(const [, item] of this.#translation.entries()) {
            data.push(item);
        }
        return data;
    }
}

const code = new Code();

export default code;