import contentTypes from "./contentTypes";

const contentTypeService = [{
    matcher: 'contentTypes',
    reply: config => {
        return [200, contentTypes];
    }
}, {
    matcher: /contentTypes\/(.*)/,
    reply: config => {
        const id = /contentTypes\/(.*)/.exec(config.url)[1];
        const contentType = contentTypes.find(contentType => contentType.id === id);
        return [200, contentType];
    }
}]

export default contentTypeService;