import registrationInboxReducer from "./registrationInboxReducer";
import cropInboxReducer from "./cropInboxReducer";
import markInboxReducer from "./markInboxReducer";
import disqualificationInboxReducer from "./disqualificationInboxReducer";

const inboxReducers = {
    registrationInbox: registrationInboxReducer,
    cropInbox: cropInboxReducer,
    markInbox: markInboxReducer,
    disqualificationInbox: disqualificationInboxReducer
}

export default inboxReducers;