import * as d3 from 'd3';

class Palette {
    constructor() {
        if(!Palette.instance) {
            Palette.instance = this;
        }
        return Palette.instance;
    }

    color(specifier, opacity) {
        const color = d3.color(specifier);
        color && (color.opacity = opacity);
        return color;
    }

    rgb(specifier) {
        return d3.color(specifier)?.rgb();
    }

    hex(specifier) {
        return d3.color(specifier)?.hex()
    }

    brighter(specifier, k) {
        const color = d3.color(specifier)?.brighter(k);
        return color;
    }

    darker(specifier, k) {
        const color = d3.color(specifier)?.darker(k);
        return color;
    }

    invert(specifier, bw) {
        let hex = this.hex(specifier).slice(1);
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        let r = parseInt(hex.slice(0, 2), 16), g = parseInt(hex.slice(2, 4), 16),
            b = parseInt(hex.slice(4, 6), 16);
        if (bw) {
            // http://stackoverflow.com/a/3943023/112731
            return (r * 0.299 + g * 0.587 + b * 0.114) > 186
                ? '#000000'
                : '#FFFFFF';
        }
        // invert color components
        r = (255 - r).toString(16);
        g = (255 - g).toString(16);
        b = (255 - b).toString(16);
        // pad each with zeros and return
        return this.of("#" + this.padZero(r) + this.padZero(g) + this.padZero(b));
    }

    padZero(str, len) {
        len = len || 2;
        const zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }

    Scheme = {
        Categorical: d3.schemeCategory10
    }

    Grey = {
        light: 'rgb(230, 233, 236)',
        main: 'rgb(115, 118, 121)',
        dark: 'rgb(67, 70, 73)'
    }
}

export const blue = {
    light: d3.rgb(207, 237, 251),
    main: d3.rgb(0, 140, 201),
    dark: d3.rgb(0, 94, 147)
}

const orange = {
    light: d3.rgb(255, 231, 187),
    main: d3.rgb(236, 100, 12),
    dark: d3.rgb(175, 65, 4)
}

const green = {
    light: d3.rgb(224, 244, 190),
    main: d3.rgb(96, 170, 20),
    dark: d3.rgb(59, 117, 71)
}

const red = {
    light: d3.rgb(255, 224, 218),
    main: d3.rgb(221, 46, 31),
    dark: d3.rgb(164, 15, 28)
}

const purple = {
    light: d3.rgb(235, 228, 255),
    main: d3.rgb(124, 91, 187),
    dark: d3.rgb(87, 59, 147)
}

const yellow = {
    light: d3.rgb(255, 242, 182),
    main: d3.rgb(230, 167, 0),
    dark: d3.rgb(170, 125, 0)
}

const pink = {
    light: d3.rgb(255, 223, 242),
    main: d3.rgb(126, 36, 127),
    dark: d3.rgb(177, 12, 92)
}

const cyan = {
    light: d3.rgb(210, 236, 235),
    main: d3.rgb(0, 158, 165),
    dark: d3.rgb(0, 114, 125)
}

const grey = {
    light: d3.rgb(230, 233, 236),
    main: d3.rgb(115, 118, 121),
    dark: d3.rgb(67, 70, 73)
}

const white = {
    light: d3.rgb(210, 236, 235),
}

const metal = {
    light: d3.color('whitesmoke')
}

const palette = new Palette();


Object.freeze(palette);

export default palette;