import React, {useRef, useState} from "react";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {AppBar} from "@material-ui/core";
import {useSelector} from "../../store";
import Button from "../../lib/appfuse-react/component/Button";
import Space from "../../lib/appfuse-react/component/Space";
import Typography from "@material-ui/core/Typography";
import SearchField from "../../component/SearchField";
import Expander from "../../lib/appfuse-react/component/Expander";
import Toolbar from "../../component/Toolbar";
import {selectTitle} from "../../reducer/app/appReducer";
import i18n from "../../lib/appfuse-react/core/i18n";
import {selectContentTypes} from "../../reducer/content/contentTypeReducer";
import env from "../../lib/appfuse-react/core/env";
import lang from "../../lib/appfuse-react/core/lang";
import Box from "../../lib/appfuse-react/component/Box";
import useRouter from "../../lib/appfuse-react/hook/useRouter";
import {useHistory, useRouteMatch} from "react-router-dom";
import Image from "../../lib/appfuse-react/component/Image";

const initialContext = {
    workspace: undefined
}

const ExplorerContext = React.createContext(initialContext);

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 100,
        backgroundColor: theme.brand.appbar?.backgroundColor,
        color: theme.brand.appbar?.color,
    },
}));

function Explorer(props) {
    const theme = useTheme();
    const router = useRouter();
    const history = useHistory();
    const backgroundImage = router.resolve(theme.brand.backgroundImage);
    const classes = useStyles({backgroundImage});
    const [keyword, setKeyword] = useState(null);
    const title = useSelector(selectTitle);
    const { path } = useRouteMatch();
    const contentTypes = useSelector(selectContentTypes);
    const responsive = env.responsive;
    const workspaceRef = useRef();
    const workspace = workspaceRef?.current;

    const handleSearch = event => {
        const keyword = event.value;
        setKeyword(keyword);
    }

    window.document.title = i18n.translate(title);

    const settings = env.get('app');
    const stageInfo = env.appStage.toUpperCase() === 'PROD' ? null : env.appStage;
    const versionInfo = env.appVersion + `${stageInfo ? '@' + stageInfo: ''}`;
    return (
        <Box className="app-explorer" fullHeight fullWidth overflow="hidden" display="flex" flexDirection="column">
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Image value={theme.brand.logo} onClick={()=> history.push('/')} height={46} title={versionInfo}/>
                    <Space spacing={1} />
                    <Typography variant="h5" noWrap>
                        {i18n.translate(title)}
                        <Space spacing={1} />
                        {stageInfo ? <Typography variant="body2" display="inline">{versionInfo}</Typography> : null}
                    </Typography>
                    <Box display={lang.isFalse(settings.search) ? 'none' : 'initial'}>
                        <SearchField label="Search" value={keyword} onChange={handleSearch} />
                    </Box>
                    <Expander />
                    <Button text="Login" variant="text" color="inherit" onClick={() => history.push('/logon')} />
                    <Box display={lang.isFalse(settings.registration) ? 'none' : 'initial'}>
                        <Button text="Register" variant="text" color="inherit" onClick={() => history.push('/registration')}/>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box fullHeight fullWidth  overflow="auto" backgroundImage={backgroundImage}>
                <Box ref={workspaceRef} fullHeight fullWidth overflow="hidden"  minWidth={`${responsive.minWidth}px`}>
                    <ExplorerContext.Provider value={{workspace}}>
                        {props.children}
                    </ExplorerContext.Provider>
                </Box>
            </Box>
        </Box>
    )
}


const withExplorer = WrappedComponent => (props) => {
    return (
        <Explorer>
            <WrappedComponent {...props} />
        </Explorer>
    );
};

export default Explorer;

export { withExplorer, ExplorerContext };