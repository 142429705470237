import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "../../store";
import React, {useState} from "react";
import {withExplorer} from "../../layout/Explorer";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import Form from "../../lib/appfuse-react/component/Form";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField, useTheme
} from "@material-ui/core";
import Button from "../../lib/appfuse-react/component/Button";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Box from "../../lib/appfuse-react/component/Box";
import i18n from "../../lib/appfuse-react/core/i18n";
import usePrompt from "../../lib/appfuse-react/hook/usePrompt";
import UserEvent from "../../lib/appfuse-react/core/UserEvent";
import registrationService from "../../service/iam/registrationService";
import {selectRegistrationForm, setRegistrationForm} from "../../reducer/iam/registrationReducer";
import useRouter from "../../lib/appfuse-react/hook/useRouter";
import Paper from "../../lib/appfuse-react/component/Paper";
import Space from "../../lib/appfuse-react/component/Space";
import {HomeImage} from "../Home";

const useStyles = makeStyles((theme) => ({
    transparent: {
        backgroundColor: 'transparent'
    }
}));

const STEPS = ['Create your account', 'Tell us more about yourself', 'Verify your email address'];
const FORMS = [RegistrationForm_1, RegistrationForm_2, RegistrationForm_3];

function getStepContent(step) {
    switch (step) {
        case 0:
            return `Please enter your email and password to start to create your account.`;
        case 1:
            return 'Please provide us with your contact information and content preferences so we can set you up in our system.';
        case 2:
            return `Click the send button to get the verification code, then enter the verification code sent to your
                    email address and click the verify button.`;
        default:
            return 'Unknown step';
    }
}

function Registration(props) {
    const router = useRouter();
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const value = useSelector(selectRegistrationForm);
    const dispatch = useDispatch();
    const prompt = usePrompt();
    const backgroundImage = router.resolve(theme.brand.banner);

    const handleChange = (event) => {
        dispatch(setRegistrationForm(event.value));
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleComplete = () => {
        prompt.confirm('You Have Successfully Registered.', {
            title: 'Welcome to Flora Web',
            onAction: () => router.login()
        });
    }

    const title = STEPS[activeStep];
    const description = getStepContent(activeStep);
    const Form = FORMS[activeStep];

    return (
        <Paper className="app-registration" position="relative" backgroundImage={backgroundImage} backgroundOpacity={0.85} fullWidth fullHeight >
            <Box position="relative" fullHeight fullWidth backgroundColor="black" backgroundOpacity={0.4}
                 display="flex" justifyContent="center" alignItems="center" >
                <Box display="flex" maxWidth="60%" minWidth="45rem" gap={4}>
                    <Form value={value} onChange={handleChange} title={title} description={description}
                          onNext={handleNext} onBack={handleBack}
                          onComplete={handleComplete} />
                    <Paper minWidth="20rem" backgroundColor="paper" backgroundOpacity={0.95}>
                        <RegistrationStepper activeStep={activeStep} />
                    </Paper>
                </Box>
            </Box>
        </Paper>
    );
}

function RegistrationForm_1(props) {
    const {title, description, value} = props;
    const router = useRouter();
    const theme = useTheme();
    const handleSignInInstead = () => router.login();
    return (
        <Paper padding={3} display="flex" flexDirection="column" gap={1} backgroundOpacity={0.95} borderRadius={1}>
            <Typography variant="h6">{i18n.translate(title)}</Typography>
            <Typography>{i18n.translate(description)}</Typography>
            <Space vertical />
            <Form value={value} onChange={props.onChange} maxHeight={`calc(40vh - ${theme.mixins.toolbar.minHeight}px)`} overflow="auto">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField name="forename" label="First Name" variant="outlined"
                                   fullWidth  />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name="surname" label="Last Name" variant="outlined"
                                   fullWidth  />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name="username" label="Username" variant="outlined"
                                   fullWidth  helperText="you can use letters, numbers & periods" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name="password" label="Password" variant="outlined"
                                   fullWidth type="password"  />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name="passwordConfirmation" label="Confirm Password" variant="outlined"
                                   fullWidth type="password"   />
                    </Grid>
                    <Grid item xs={12}>
                        <Box paddingX={1.5} marginTop={-1.5}>
                            <Typography variant="caption" color="textSecondary">use 8 or more characters with a mix of letters, numbers & symbols</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name="email" label="Recovery email address (optional)" variant="outlined"
                                   fullWidth
                                   helperText="We’ll use it to keep your account secure."/>
                    </Grid>
                </Grid>
            </Form>
            <Box display="flex" justifyContent="space-between" paddingTop={1}>
                <Button text="Sign in instead" color="secondary" onClick={handleSignInInstead} />
                <Button text="Next" variant="contained" color="primary" onClick={props.onNext} />
            </Box>
        </Paper>
    )
}

function RegistrationForm_2(props) {
    const {title, description, value} = props;
    return (
        <Paper padding={3} display="flex" flexDirection="column" gap={1} borderRadius={1} backgroundOpacity={0.95}>
            <Typography variant="h6">{i18n.translate(title)}</Typography>
            <Typography>{i18n.translate(description)}</Typography>
            <Space vertical />
            <Form value={value} onChange={props.onChange} maxHeight="40vh" overflow="auto">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField name="mobile" label="Phone number (optional)" variant="outlined"
                                   fullWidth
                                   helperText="We’ll use your number for account security. It won’t be visible to others."/>
                    </Grid>
                    <Grid item xs={12}>
                        <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Your Birthday"
                            format="YYYY/MM/DD"
                            InputAdornmentProps={{ position: "start" }}
                            name="birthday"
                            nullable="true"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth  >
                            <InputLabel>Gender</InputLabel>
                            <Select name="gender" label="Gender">
                                <MenuItem value={"female"}>Female</MenuItem>
                                <MenuItem value={"mail"}>Male</MenuItem>
                                <MenuItem value={""}>Rather not say</MenuItem>
                                <MenuItem value={"custom"}>Custom</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Form>
            <Box display="flex" justifyContent="space-between" paddingTop={2}>
                <Button text="Back" color="secondary" onClick={props.onBack} />
                <Button text="Next" variant="contained" color="primary" onClick={props.onNext}/>
            </Box>
        </Paper>
    )
}


function RegistrationForm_3(props) {
    const {title, description, value} = props;
    const prompt = usePrompt();

    const handleRequestToken = async () => {
        try {
            await registrationService.requestToken(value.email);
            prompt.info('Open the email and find the verification code.');
        } catch(e) {
            prompt.alert('Failed to send the verification code.\nPlease try again later.');
        }
    }

    const handleVerifyEmail = async () => {
        try {
            const valid = await registrationService.verifyEmail(value.email, value.token);
            if(valid) {
                props.onChange(new UserEvent({
                    value : {...value, valid: true}
                }));
            }
        } catch(e) {
            prompt.alert('Failed to verify the email.\nPlease try again later.');
        }
    }

    return (
        <Paper padding={3} display="flex" flexDirection="column" gap={1} borderRadius={1} backgroundOpacity={0.95}>
            <Typography variant="h6">{i18n.translate(title)}</Typography>
            <Typography>{i18n.translate(description)}</Typography>
            <Space vertical />
            <Form value={props.value} onChange={props.onChange} maxHeight="40vh" overflow="auto">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Button text="Click to get the verification code." color="primary" onClick={handleRequestToken} />
                        <Button color="primary" icon="send" variant="icon" onClick={handleRequestToken} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" gap={1}>
                            <TextField name="token" label="Verification Code" variant="outlined"
                                       fullWidth size="small" />
                            <Checkbox disabled name="valid" />
                            <Button color={"secondary"} text="Verify" onClick={handleVerifyEmail} />
                        </Box>
                    </Grid>
                </Grid>
                <Box marginTop={1} padding={1}>
                    <Typography variant="h6">{i18n.translate('Terms of Service')}</Typography>
                    <Typography>{i18n.translate('Terms of Service ......')}</Typography>
                    <FormGroup row>
                        <FormControlLabel name="agree" control={<Checkbox /> }
                                          label={i18n.translate("I Agree")} />
                    </FormGroup>
                </Box>
                <Box display="flex" justifyContent="space-between" paddingTop={1}>
                    <Button text="Back" color="secondary" onClick={props.onBack} />
                    <Button text="Complete" variant="contained" color="primary" onClick={props.onComplete}/>
                </Box>
            </Form>
        </Paper>
    )
}

function RegistrationStepper(props) {
    const {activeStep} = props;
    const steps = STEPS;
    const classes = useStyles();

    return (
        <Stepper className={classes.transparent} activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
                <Step key={label}>
                    <StepLabel><Typography>{label}</Typography></StepLabel>
                    <StepContent>
                        <Typography>{getStepContent(index)}</Typography>
                    </StepContent>
                </Step>
            ))}
        </Stepper>
    );
}

export default withExplorer(Registration);