import React ,{lazy} from 'react'

class Reactjs {
    /**
     * test if the component is react class component.
     *
     * @param component
     * @returns {boolean}
     */
    isClassComponent(component) {
        const type = typeof(component);
        let test;
        if(type==='function') {
            test = !!component.prototype.isReactComponent;
        } else if(typeof component === 'object') {
            const naked = component['Naked'];
            test = (
                typeof naked === 'function' &&
                !!naked.prototype.isReactComponent
            );
        } else {
            test = false;
        }
        return test;
    }

    /**
     * test if the component is react function component.
     *
     * @param component
     * @returns {boolean}
     */
    isFunctionComponent(component) {
        return (
            typeof component === 'function' &&
            String(component).includes('return React.createElement')
        )
    }

    /**
     * test if the component is react component.
     *
     * @param component
     * @returns {boolean}
     */
    isComponent(component) {
        return (
            this.isClassComponent(component) ||
            this.isFunctionComponent(component)
        )
    }

    /**
     * test if the component is react element.
     *
     * @param element
     * @returns {boolean}
     */
    isElement(element) {
        return React.isValidElement(element);
    }

    /**
     * test if the component is react dom element.
     *
     * @param element
     * @returns {boolean}
     */
    isDOMTypeElement(element) {
        return this.isElement(element) && typeof element.type === 'string';
    }

    isCompositeTypeElement(element) {
        return this.isElement(element) && typeof element.type === 'function';
    }

    /**
     * test if the event is a SyntheticEvent.
     *
     * use duck type checking.
     *
     * @param event
     */
    isSyntheticEvent(event) {
      return event.nativeEvent && (event.nativeEvent instanceof Event);
    }

    lazy(resolver, name = 'default') {
        return lazy(async () => {
            const resolved = await resolver()
            return {default: resolved[name]}
        })
    }
}

const reactjs = new Reactjs();

export default reactjs;