const users = [{
    username: 'admin',
    password: 'admin',
    displayName: '系統管理者'
}, {
    username: 'customer',
    password: 'customer',
    displayName: '客戶'
}, {
    username: 'operator',
    password: 'operator',
    displayName: '作業人員'
}, {
    username: 'manager',
    password: 'manager',
    displayName: '主管'
}];

export default users;