import certifiedFarmersReducer from "./certifiedFarmersReducer";
import farmerChannelQuestionnaireReducer from "./farmerChannelQuestionnaireReducer";
import organicAgricultureSystemReducer from "./organicAgricultureSystemReducer";
import farmerStatisitcsReducer from "./farmerStatisitcsReducer";

const reportReducers = {
    certifiedFarmers: certifiedFarmersReducer,
    farmerChannelQuestionnaire: farmerChannelQuestionnaireReducer,
    organicAgricultureSystem: organicAgricultureSystemReducer,
    farmerStatistics: farmerStatisitcsReducer
};

export default reportReducers;