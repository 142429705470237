import lang from "./lang";
import time from "./time";
import logger from "./logger";
import JSZip from "jszip";
import {random} from "underscore";

const log = logger.getLogger('appfuse.react.fileIO');

const MEDIATYPEBYEXTENSION = {
    '.txt': 'text/plain',
    '.csv': 'text/csv',
    '.pdf': 'application/pdf',
    '.xls': 'application/vnd.ms-excel',
    '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.doc': 'application/msword',
    '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.ppt': 'application/vnd.ms-powerpoint',
    '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    '.zip': 'application/zip',
    '.json': 'application/json',
    '.avi': 'video/x-msvideo',
    '.mp3': 'audio/mpeg',
    '.png': 'image/png',
    '.jpg': 'image/jpeg',
    '.jpeg': 'image/jpeg',
    '.html': 'text/html',
    '.htm': 'text/html',
    '.css': 'text/css'
};

const DEFAULTMEDIATYPE = 'application/octet-stream';

const IMAGEMEDIATYPEPATTERN = new RegExp('image/*');

class FileIO {
    constructor() {
        if(!FileIO.instance) {
            FileIO.instance = this;
        }
        return FileIO.instance;
    }

    getFileType(file) {
        const extension = this.getFileExtension(file);
        const type = lang.isNullOrUndefined(file.type) ? this.getMediaTypeByExtension(extension) : file.type;
        return type || DEFAULTMEDIATYPE;
    }

    getMediaTypeByExtension(extension) {
        return  lang.has(MEDIATYPEBYEXTENSION, extension) ? MEDIATYPEBYEXTENSION[extension] : DEFAULTMEDIATYPE;
    }

    getExtensionByMediaType(mediaType) {
        const entry = Object.entries(MEDIATYPEBYEXTENSION).find(entry => entry[1]===mediaType);
        return entry ? entry[0] : null;
    }

    getFileExtension(file) {
        const name = file.name;
        const matches = /\..*$/.exec(name);
        const extension = matches ? matches[0] : null;

        return extension;
    }

    isImage(file) {
        const type = fileIO.getFileType(file);
        return IMAGEMEDIATYPEPATTERN.test(type);
    }

    async save(file, filename) {
        let source;
        if(file instanceof Blob) {
            source = file;
        } else if(file instanceof FileDescriptor) {
            source = await this.decode(file);
        } else {
            throw new Error('fileIO failed to save the file. The file should be instance of File or FileDescriptor.')
        }

        const url = URL.createObjectURL(source);

        const link = document.createElement('a');
        link.href = url;
        link.download = source.name;
        link.download = filename || source.name;
        if(link.download==='') {
            const extension = fileIO.getExtensionByMediaType(source.type) || '';
            link.download = time.format(time.now(), 'YYYYMMDDHHmmss') + extension;
        }

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    async zip(files) {
        let zip = new JSZip();
        for(let file of files) {
            let filename = file.name || random.uuid();
            zip.file(filename, file)
        }

        const data = await zip.generateAsync({type:'blob'});
        return new Blob([data])
    }

    open(file, target) {
        // 將 blob 放到 URL 上
        const url = window.URL.createObjectURL(file);
        window.open(url, target);
    }

    /**
     * Base64 encode the file object (File) to a file descriptor (FileDescriptor).
     * @param file
     * @returns {Promise<FileDescriptor>}
     */
    async encode(file) {
        const promise = new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                const bytes = reader.result;
                const name = file.name;
                const size = file.size;
                const lastModified = file.lastModified;
                const type = this.getFileType(file);
                const descriptor = new FileDescriptor(type, size, bytes, name, lastModified);
                resolve(descriptor);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });

        return promise;
    }

    /**
     * Base64 decode the file descriptor (FileDescriptor) to a file object (File).
     * @param fileDescriptor
     * @returns {Promise<File>}
     */
    async decode(fileDescriptor) {
        const {type, size, name, lastModified, lastModifiedDate} = fileDescriptor;
        const response = await fetch(fileDescriptor.bytes);
        const blob = await response.blob();

        return new File([blob], name, { name, type, size, lastModified, lastModifiedDate });
    }

    base64ToFile(base64String, {contentType = '', name = '', lastModified = new Date(), lastModifiedDate = new Date() } ) {
        const blob = this.dataURLtoBlob(base64String, contentType);
        const size = blob.size;
        const type = contentType;
        return new File([blob], name, { name, type, size, lastModified, lastModifiedDate });
    }

    dataURLtoBlob(dataURL) {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    isFileDescriptor(jsonObject) {
        return jsonObject instanceof FileDescriptor;
    }

    isFileLike(jsonObject) {
        let test;
        if (jsonObject instanceof File || jsonObject instanceof FileDescriptor) {
            test = true;
        } else {
            // do not add 'lastModified' to keep compatible with appstack
            test = lang.has(jsonObject, 'name', 'type', 'size')
                && (lang.has(jsonObject, 'href') || lang.has(jsonObject, 'bytes'));
        }

        return test;
    }
}

class FileDescriptor {
    type = undefined;
    size = undefined;
    bytes = undefined;
    name = undefined;
    lastModified = undefined;
    href = undefined;
    uuid = undefined;
    createdDate = undefined;

    constructor(type, size, bytes, name, lastModified, href, uuid, createdDate) {
        this.type = type;
        this.size = size;
        this.bytes = bytes;
        this.name = name;
        this.lastModified = lastModified;
        this.href = href;
        this.uuid = uuid;
        this.createdDate = createdDate;
    }
    static fromJSON({type, size, bytes, name, lastModified, href, uuid, createdDate}) {
        return new FileDescriptor(type, size, bytes, name, lastModified, href, uuid, createdDate);
    }
}

const fileIO = new FileIO();

export default fileIO;

export { FileDescriptor };